import React from 'react';
import PropTypes from 'prop-types';
import './headerCell.less'
import SortIcon from './sortIcon';
import withManageableHeader from './withManageableHeader';
import FilterBox from 'controls/react/rsuite/filterBox';
import { TableContext } from './constants';
import { ACTIONS, VARS } from "controls/react/rsuite/reducer";
import { Checkbox } from 'antd';
import FilterInput from './filterInput'

const b = require('b_').with('rtable-headercell');

function RHeaderCell(props) {
	const {column, direction, sortingIndex, filter, filterOptions, onToggleSort, onFilter, dataKey, singleMetricSelection} = props;
	const {state, dispatch} = React.useContext(TableContext);
	const indexKey = state[VARS.INDEX_KEY];
	const handleChange = React.useCallback((e) => {
		dispatch({ type: ACTIONS.TOGGLE_ALL_CHECKED, value: e.target.checked })
	}, [indexKey]);

	const handleClick = React.useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();
	}, [])

	const handleToggleSort = React.useCallback((e) => {
		if (column.field !== indexKey && !column.index) {
			onToggleSort(e);
		}
	}, [indexKey, column.field]);

	return <div className={b()} onClick={handleToggleSort}>
		<div className={b('titleRow')}>
			<div className={b('title')}>
				{column.index && <Checkbox onChange={handleChange} disabled={singleMetricSelection} />}
				{!column.index && column.title}
			</div>
			<div className={b('sortIcon')}>
				<SortIcon direction={direction} />
				{sortingIndex !== undefined && sortingIndex > -1 && <div className={b('sortingIndex')}>
					{sortingIndex + 1}
				</div>}
			</div>
			{(column.filterable && ['box','select','multiSelect'].includes(column.filterable.config?.type)) && <div className={b('filter-box')} onClick={handleClick}>
				<FilterBox value={filter?.value} condition={filter?.condition} filterOptions={filterOptions} onChange={onFilter}
				columnConfig={column.filterable.config} />
			</div>}

		</div>
		{(column.filterable && !['box','select','multiSelect'].includes(column.filterable.config?.type)) && <div className={b('filter-input')} onClickCapture={handleClick}>
				<FilterInput value={filter} filterOptions={filterOptions} onChange={onFilter} />
			</div>}
	</div>
}

RHeaderCell.propTypes = {
	column: PropTypes.shape({
		title: PropTypes.string,
		filterable: PropTypes.object
	}).isRequired,
	dataKey: PropTypes.string.isRequired,

	direction: PropTypes.string,
	onToggleSort: PropTypes.func.isRequired,

	filterOptions: PropTypes.array,
	filter: PropTypes.string,
	onFilter: PropTypes.func
};


export default withManageableHeader(RHeaderCell);

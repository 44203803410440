import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import {ACTIONS} from "areas/services/widgets/filterPanel/reducer";
import Lang from "core/localization/lang";
import NumericTextBox from "controls/react/form/numericTextBox";
import {b} from './filteringPanelShared';
import classnames from 'classnames';

const OPERATOR_DATA = [{
	text: Lang.grid.filter.EQ,
	value: '=='
}, {
	text: Lang.grid.filter.NEQ,
	value: '!='
}, {
	text: Lang.grid.filter.GTE,
	value: '>='
}, {
	text: Lang.grid.filter.GT,
	value: '>'
}, {
	text: Lang.grid.filter.LTE,
	value: '<='
}, {
	text: Lang.grid.filter.LT,
	value: '<'
}];

function IncidentNumber(props) {
	const onOperatorChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_OPERATOR, index: props.index, value })
	}, [props.dispatch]);

	const onIncidentChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_INCIDENT_NUMBER, index: props.index, value })
	}, [props.dispatch]);

	return <>
		<div className={b('input')}>
			<DropDownList dataSource={OPERATOR_DATA} value={props.operator} onChange={onOperatorChange} />
		</div>
		<div className={classnames(b('input'), b('reset-field'))}>
			<NumericTextBox value={props.incidents} onChange={onIncidentChange} />
		</div>
	</>
}

IncidentNumber.propTypes = {
	operator: PropTypes.string.isRequired,
	incidents: PropTypes.number.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default IncidentNumber;

import Cookies from 'core/cookies';
import Settings from 'settings';
import {useEffect, useState} from "react";
import {ErrorHandler} from "core/errorHandler";

let Api = {
	get server() {
		return Settings.serverPath;
	},

	async fetch(url, options, cache, key) {
		if(typeof url != 'string'){ //assuming that this is UrlBuilder
			url = url.build();
		}
		if( key == null && cache != null ){
			key = url;
		}
		if (cache != null && cache[key] != null)
			return cache[key];

		options = {
			credentials: 'include',
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				"Auth-Token": Cookies.sessionId
			},
			throwOnError: false,
			...options
		};

		let response = await fetch(url, options);
		let result = null;

		if (!response.ok) {
			let text = await response.text();
			try {
				//Response can be JSON even if http code is not 200 OK
				result = JSON.parse(text);
			} catch {
				result = {
					success: false,
					message: text
				}
			}
		}else{
			if (options.plainText) {
				result = await response.text();
			} else {
				try {
					result = await response.json();
				} catch {
					result = {
						success: false,
						message: 'Cannot deserialize json'
					}
				}
			}
		}

		if (options.throwOnError) {
			if (result.success === false && !result.data) {
				console.log('Backend error: ' + result.message);
				throw Error(result.message);
			}
		}

		if (cache != null) {
			cache[key] = result;
		}

		return result;
	},

	fetchPost(url, data, options){
		return this.fetch(
			url,
			Object.assign({}, options, {
				method: 'POST',
				body: JSON.stringify(data)
			}));
	},

	fetchDelete(url, options = {}){
		return this.fetch(
			url,
			{
				...options,
				method: 'DELETE'
			}
		);
	},

	ajax(url, method, data, success, error, timeout, customTimeout) {
		return jQuery.ajax({
			cache: false,
			timeout: timeout,
			url: url,
			type: method,
			async: true,
			data: data,
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			crossDomain: true,
			statusCode: {
				500: function (result, status, obj) {
					if (error && !this.calledErrorCallback) {
						error.call(this, result, status, obj);
					}
				}
			},
			beforeSend: function (x) {
				if (x && x.overrideMimeType) {
					x.overrideMimeType("application/json;charset=UTF-8");
					if (Cookies.sessionId) {
						x.setRequestHeader('Auth-Token', Cookies.sessionId);
					}
				}
			},
			error: function (result, status, obj) {
				if (status === 'timeout' && customTimeout) {
					customTimeout.call(this);
				} else {
					if (error) {
						this.calledErrorCallback = true;
						error.call(this, result, status, obj);
					} else if (status !== 'abort') {
						Settings.tr = new Date().getTime();
						ErrorHandler.serverError.call(this, result, status, obj);
					}
				}
			},
			success: function (result, status, obj) {
				Settings.tr = new Date().getTime();
				if (success) {
					success.call(this, result, status, obj);
				}
			}
		});
	},

	async loadContent(url, container){
		let result = await fetch(url);
		let text = await result.text();
		container.innerHTML = text;
	},

	compileUrlWithParams: function (url, params) {
		return url + '?' + Object.keys(params).map((key) => {
			const value = params[key];

			if (value === undefined) {
				return;
			}

			return `${key}=${value}`;
		}).filter((i) => i).join('&');
	},

	serverRoot: function () {
		return this.server;
	},

	userRoot: function (customUserId) {
		const userId = customUserId || Cookies.CeesoftUserId;
		return `${this.accountRoot()}users/${userId}/`
	},

	teamRoot: function (teamId) {
		return `${this.accountRoot()}teams/${teamId}/`
	},

	accountRoot: function (accountId, sessionId) {
		accountId = (accountId || Cookies.CeesoftCurrentAccountId)
		return Api.sessionRoot(sessionId) + 'accounts/' + accountId + "/";
	},

	sessionAndAccountRoot: function (accountId, sessionId, forceSessionId = false) {
		let url = Api.sessionRoot(sessionId, forceSessionId);

		url += 'accounts/' + (accountId || Cookies.CeesoftCurrentAccountId) + '/';

		return url;
	},

	sessionRoot: function (sessionId, forceSessionId = false) {
		let url = Api.server;

		if( sessionId != null || forceSessionId ){
			url += 'sessions/' + (sessionId || Cookies.sessionId) + '/';
		}

		return url;
	},

	getWithCache: function (ajaxArgs, cache, key) {
		if (cache != null && cache[key] != null)
			return cache[key];

		var promise = Api.ajax(ajaxArgs.url, "GET", ajaxArgs.data, ajaxArgs.success, ajaxArgs.error || function () {
		});
		if (cache != null) {
			cache[key] = promise;
		}

		return promise;
	}
}

Api.team = {
	urls: {
		list: function (accountId) {
			return Api.accountRoot(accountId) + 'teams/responsibleTeams/lite';
		}
	}
}

Api.accounts =
	{
		urls: {
			list: function (accountId, sessionId) {
				return Api.sessionAndAccountRoot(accountId, sessionId) + 'subaccounts/allLevels/lite'
			}
		},

		list: function (accountId, sessionId, cache) {
			return Api.fetch(
				this.urls.list(accountId, sessionId),
				null,
				cache, 'accounts');
		},
	}

Api.templates =
	{
		preview: function (templateId, accountId, sessionId) {
			return Api.sessionAndAccountRoot(accountId, sessionId) + 'templates/images/' + templateId;
		}
	}

Api.images = {

	urls: {
		image: function (imageId, accountId, sessionId) {
			return Api.sessionAndAccountRoot(accountId, sessionId) + 'images/' + imageId;
		},

		thumbnail: function (imageId, accountId) {
			return Api.accountRoot(accountId) + 'images/' + imageId + "/thumbnail";
		},

		list: function (accountId) {
			return Api.accountRoot(accountId) + 'images';
		},

		save: function (accountId) {
			return this.list(accountId);
		},

		upload: function (accountId) {
			return this.list(accountId) + '/upload';
		},

		delete: function (imageId, accountId) {
			return this.image(imageId, accountId);
		}
	},

	list: function (accountId) {
		return Api.ajax(this.urls.list(accountId), "GET");
	},

	delete: function (imageId, accountId) {
		return Api.ajax(this.urls.delete(imageId, accountId), 'DELETE');
	}
}

Api.users = {
	urls: {
		avatar: function (userId, imageId, accountId, sessionId) {
			return Api.sessionAndAccountRoot(accountId, sessionId) + 'users/' + userId + '/picture/' + imageId;
		},
	}
}

export {Api, Api as default}

const defaultOptions = {
	directList: false,
	makeRequest: true
}

export function useRemoteList(url, options) {
	options = Object.assign({}, defaultOptions, options);
	const [list, setList] = useState([]);

	useEffect(() => {
		if (url == null || !options.makeRequest)
			return;

		Api.fetch(url).then(r => {
			//=== is crucial here because in case of directList 'success' property wont be present until there is error
			if(r.success === false)
				return;

			if (options.directList) {
				setList(r);
			} else {
				if (!r.success)
					return;

				setList(r.data);
			}
		});

	}, [url, options.makeRequest]);

	return list;
}

export function useRemoteListPost(url, data, options ) {
	options = Object.assign({}, defaultOptions, options);
	const [list, setList] = useState([]);

	useEffect(() => {
		if (url == null || !options.makeRequest)
			return;

		Api.fetchPost(url, data).then(r => {
			if (options.directList) {
				setList(r);
			} else {
				if (!r.success)
					return;

				setList(r.data);
			}
		});

	}, [url, JSON.stringify(data)]);

	return list;
}

import React, {useContext, useCallback, useRef, useEffect, useState} from "react";
import {useStoreCreator} from "./useStoreCreator";
import {fromJS} from "immutable";

export function usePlainStoreCreator(context, storeRef) {
	const useStore = useStoreCreator(context, storeRef);

	return function usePlainStore(path, defaultValue) {
		const [value, setValue] = useStore(path, fromJS(defaultValue));
		const [plainValue, setPlainValue] = useState();

		useEffect(() => {
			if (value && value.toJS) {
				setPlainValue(value.toJS());
			}
			else {
				setPlainValue(value);
			}
		}, [value]);

		const onChange = useCallback((plainValue) => {
			setValue(fromJS(plainValue))
		});

		return [plainValue, onChange];
	}
}

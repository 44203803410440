import {Api} from "tools/api";

export class AssetGroupAPI {
	static getDynamicSearchUrl(accountId) {
		return AssetGroupAPI.getListUrl(accountId) + 'search/lite?includeSubaccounts=true';
	}

	static getListUrl(accountId) {
		return `${Api.accountRoot(accountId)}assetGroups/`;
	}
	static getCategoryListUrl(accountId) {
		return `${Api.accountRoot(accountId)}metrics/categories/`;
	}
	static getMembersUrl(assetGroupId) {
		return `${Api.accountRoot()}assetGroups/${assetGroupId}/members`;
	}

	static getAgentMembersUrl(assetGroupId) {
		return `${Api.accountRoot()}assetGroups/${assetGroupId}/members/types/AGENT`;
	}

	static getAssetGroups(accountId) {
		return Api.fetch(this.getListUrl(accountId), { credentials: undefined });
	}

	static getCategories(accountId) {
		return Api.fetch(this.getCategoryListUrl(accountId), { credentials: undefined });
	}
}

export default AssetGroupAPI;

import {Cookies} from 'core';
import cloneDeep from 'lodash/cloneDeep';
import Settings from 'settings';
import {Api} from "tools/api";
import './ruleset.less';
import {updateIndexes} from "../notifications/notificationPolicyLoader";

export function getItemId(itemId) {
	return (!itemId || itemId === 'current') ? Cookies.CeesoftUserId: itemId;
}

export class DataReader {
	_data = null;

	constructor(accountId, type, itemId) {
		this.accountId = accountId;
		this.type = type;
		this.itemId = itemId;
	}

	getPolicyUrl(update) {
		return `${Settings.serverPath}accounts/${this.accountId}/${this.type}/${this.itemId}/notificationPolicy?update=${update}`;
	}

	async read() {
		const {data} = await Api.fetch(this.getPolicyUrl(true));

		this._data = data;

		return data;
	}

	deleteTrigger(items) {
		this._data = cloneDeep(this._data);
		this._data.triggers = this._data.triggers.filter((trigger) => {
			return !items.includes(trigger.id);
		})
	}

	setTrigger(items, value) {
		this._data = cloneDeep(this._data);
		this._data.triggers = this._data.triggers.map((trigger) => {
			if (items.includes(trigger.id)) {
				trigger.active = value;
			}

			return trigger;
		})
	}

	setPeriods(periods) {
		this._data = cloneDeep(this._data);
		this._data.periods = periods;
	}

	setExcludeFilters(filters, notificationTypes, severityThreshold) {
		this._data = cloneDeep(this._data);
		this._data.excludeFilters = filters;
		this._data.notificationTypes = notificationTypes;
		this._data.severityThreshold = severityThreshold;
	}

	setEnabled(value) {
		this._data = cloneDeep(this._data);
		this._data.isEnabled = value;
	}

	get data() {
		return this._data;
	}

	save() {
		const policy = updateIndexes(this.data);

		Api.fetchPost(this.getPolicyUrl(true), policy);
	}
}

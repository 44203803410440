import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from 'antd';
import {TableContext} from './constants';
import {ACTIONS, VARS} from './reducer';
import "./indexCell.less";
import withComponentLoaded from "./withComponentLoaded";
import State from 'tools/state';
import classnames from 'classnames';

const b = require('b_').with('rtable-commoncell');

function CommonCell(props) {
	const {Table, Cell, Column, HeaderCell, rowData, dataKey, column, ...restProps} = props;

	const value = React.useMemo(() => {
		if (column.render) {
			return column.render(rowData[dataKey], rowData);
		}
		else {
			return rowData[dataKey];
		}
	}, [rowData, dataKey, column]);

	return <Cell {...restProps}>
		<div className={classnames(b(), column.attributes?.class)}>
			{value}
		</div>
	</Cell>
};

CommonCell.propTypes = {
	dataKey: PropTypes.string.isRequired,
	rowData: PropTypes.object.isRequired,
	draggable: PropTypes.bool,
	column: PropTypes.object.isRequired
};

export default withComponentLoaded(CommonCell);

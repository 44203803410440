import Cookies from 'core/cookies';


export class CeeViewDataSource extends kendo.data.DataSource {
	constructor(options) {
		if (options.transport && options.transport.read) {
			options.transport.read.beforeSend = (xhr) => {
				xhr.withCredentials = true;

				if (Cookies.sessionId) {
					xhr.setRequestHeader('Auth-Token', Cookies.sessionId);
				}
			};
		}

		super(options);
	}
};

export default CeeViewDataSource;

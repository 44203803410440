import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import Lang from 'core/localization/lang'

import {MultiToggle} from "./form";

export const timePeriodItems = {
	'LASTHOUR': Lang.periodMultiToggle.HOUR,
	'LASTDAY': Lang.periodMultiToggle.DAY,
	'LAST7DAYS': Lang.periodMultiToggle.WEEK,
	'LAST30DAYS': Lang.periodMultiToggle.MONTH
};

export const timePeriodItemsWithCustom = {
	...timePeriodItems,
	'CUSTOM': Lang.CUSTOM
};

export let timePeriodMultiToggle = props => {
	const [value, setValue] = useState(props.value || 'LASTHOUR');

	const resetCallback = useCallback(() => {
		setValue('CUSTOM')
	}, [setValue]);

	if (props.setResetCallback) {
		props.setResetCallback(resetCallback);
	}

	return (
		<MultiToggle items={props.includeCustom ? timePeriodItemsWithCustom : timePeriodItems}
		             onChange={period => {setValue(period), props.onChange(period)}}
		             value={value}/>
	);
}

export default timePeriodMultiToggle;

timePeriodMultiToggle.propTypes = {
	includeCustom: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOf(['LASTHOUR', 'LASTDAY', 'LAST7DAYS', 'LAST30DAYS', 'CUSTOM']),
	setResetCallback: PropTypes.func
}

timePeriodMultiToggle.defaultProps = {
	includeCustom: true
}



import Utils from 'tools/utils'
import State from 'tools/state'

/**
 * @class GridSearch This is the class that handles the grids search field in
 * application
 * @namespace ceeview.controls
 * @constructor
 */
export default function GridSearch(config) {
	Utils.apply(this, config);
	this.initComponent();
};

jQuery.extend(GridSearch.prototype, {
	/**
	 * @config {String} id The id of the input field
	 */
	/**
	 * @config {kendoDataSource} dataSource The grid's datasource
	 */
	/**
	 * @config {Array} fields Array with the filterable fields
	 */
	/**
	 * @config {String} value The initial value
	 */
	/**
	 * Main init class
	 */
	initComponent: function () {
		this.removeListeners();
		this.attachListeners();
		this.textbox().val(this.value);
	},

	textbox() {
		if (this.input) {
			return this.$input ??= $(this.input);
		}
		return $(`#${this.id}`);
	},
	/**
	 * Attaches listeners
	 */
	removeListeners: function () {
		this.textbox().off();
	},
	/**
	 * Removes listeners
	 */
	attachListeners: function () {
		this.textbox().on('keyup', $.proxy(this.filter, this));
	},
	getValue() {
		return this.textbox().val();
	},
	/**
	 * Handler function for the click event on the action button
	 * @param e
	 */
	filter: function (e) {
		var filters = [], currentFilters;
		var text = $.trim($(e.currentTarget).val());
		if (this.dataSource) {
			var df = this.dataSource.filter();
			if (df && df.filters.length) {
				if (df.filters[0].filters) {
					currentFilters = df.filters[0];
				} else {
					currentFilters = df;
				}
			} else {
				currentFilters = {};
			}
			if (text) {
				for (var i = 0, length = this.fields.length; i < length; i++) {
					filters.push({
						field: this.fields[i],
						operator: 'contains',
						value: text
					});
				}
				var searchFilter = {
					logic: 'or',
					filters: filters
				};
				if (currentFilters.filters) {
					this.dataSource.filter({
						logic: 'and',
						filters: [currentFilters, searchFilter]
					});
				} else {
					this.dataSource.filter(searchFilter);
				}
			} else {
				if (State.currentApp.gridFilterPanel) {
					State.currentApp.gridFilterPanel.filterList.trigger('select');
				} else {
					this.dataSource.filter([]);
				}
			}
		}
	}
});

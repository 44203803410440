import { useEffect, useState } from "react";
import AssetGroupAPI from "tools/apis/assetGroupAPI";

export function useMetricCategories(accountId) {
	const [metricCategories, setMetricCategories] = useState();

	useEffect(() => {
		const fetchMetricCategories = async () => {
			const {data} = await AssetGroupAPI.getCategories(accountId);
			setMetricCategories(data);
		};

		if (accountId) {
			fetchMetricCategories();
		}
	}, [accountId]);

	return [metricCategories];
}
import React from 'react';
import PropTypes from 'prop-types';

export default function Label(props) {
    const {tooltip, caption} = props;

    if (tooltip) {
        return <div className={"qualifier-wizard__icon-label"}>
            {caption}
            <div className={"label-icon"} title={tooltip}>
                <i className="glyphicons pointer question-sign"/>
            </div>
        </div>;
    } else {
        return <span>{caption}</span>
    }
}

Label.propTypes = {
    tooltip: PropTypes.string,
    caption: PropTypes.string.isRequired
};
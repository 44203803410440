import "./checkbox.less"

import React from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";
import {IconButton} from "controls/react/form/iconButton";

let b = require('b_').with('c-checkbox');

export class Checkbox extends React.PureComponent {
	constructor(props) {
		super(props);
		if (this.props.realId) {
			this.id = this.props.id;

		} else {
			this.id = `checkbox_${Math.random().toString().replace(/0\./, '')}`;
		}
	}

	render() {
		let value = this.props.value || false;

		const {disabled, classes: {root}} = this.props;

		return (
			<div className={classnames(b(), root)}>
				<input type="checkbox" className="k-checkbox" id={this.id}
				       checked={value}
					   disabled={disabled}
				       onChange={(item, evt) => this.props.onChange(!this.props.value, item)}/>
				<label htmlFor={this.id} className="k-checkbox-label">{this.props.label}</label>
				{this.props.tooltip &&
					<IconButton iconName={"question-sign"}
					            embedded={true}
					            containerClass={b('tooltip')}
					            title={this.props.tooltip}/>
					}
			</div>
		)
	}
}

Checkbox.propTypes = {
	label: PropTypes.string,
	value: PropTypes.bool,
	onChange: PropTypes.func,
	classes: PropTypes.shape({
		root: PropTypes.string
	}),
	disabled: PropTypes.bool,
	tooltip: PropTypes.string
};

Checkbox.defaultProps = {
	disabled: false,
	classes: {

	}
};

export {Checkbox as default};

import React, {useCallback, useRef} from 'react';
import {useContextField} from 'core/react/reducerHelpers';
import TypeFactory from 'areas/service-boards/widgets/customMetrics/types/typeFactory';

export function withContextData(Component) {
	function WithContextData(props) {
		const {value: accountId} = useContextField(props.context, 'configuration.accountId');
		const {
			value: selectedMetrics,
			onChange: setSelectedMetrics
		} = useContextField(props.context, 'configuration.metricsItems');
		const {value: config, onChange: setConfig} = useContextField(props.context, 'configuration.highchartConfig');
		const {value: labelTemplate} = useContextField(props.context, 'configuration.labelTemplate');
		const {value: chartType} = useContextField(props.context, 'configuration.chartType');

		const handleDeleteRef = useRef(null);
		handleDeleteRef.current = useCallback((checkedRef) => {
			const highChartConfig = JSON.parse(config);

			const newSelectedMetrics = selectedMetrics.filter((metric) => {
				return !checkedRef.current.includes(metric.metricId)
			})

			TypeFactory.get(chartType).setMetrics(highChartConfig, newSelectedMetrics, labelTemplate);

			setSelectedMetrics(JSON.parse(JSON.stringify(newSelectedMetrics)));
			setConfig(JSON.stringify(highChartConfig));
		}, [config, selectedMetrics, labelTemplate]);

		React.useEffect(() => {
			if (!config) {
				return;
			}

			const highChartConfig = JSON.parse(config);

			TypeFactory.get(chartType).setMetrics(highChartConfig, selectedMetrics || [], labelTemplate);

			setConfig(JSON.stringify(highChartConfig));
		}, [config, labelTemplate, selectedMetrics]);

		const handleDelete = React.useCallback((checkedRowsRef) => {
			handleDeleteRef.current(checkedRowsRef);
		}, [])

		return <Component
			accountId={accountId}
			selectedMetrics={selectedMetrics}
			onSelectedMetrics={setSelectedMetrics}
			onDelete={handleDelete}
			singleMetricSelection={props.singleMetricSelection??false}
		/>
	}

	return WithContextData;
}

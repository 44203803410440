import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import Renderer from 'tools/renderer';
import GridMenu from 'controls/gridMenu';
import State from 'tools/state';
import Application from 'core/application';
import PDFObject from 'pdfobject';
import ExpandableTextarea from 'controls/expandableTextarea';
import {IncidentsRouter} from "areas/incidents/bundleDescription";


export default function LogDetailsView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'ServiceLogDetails',
		initialConfig: config
	};
	this.initComponent();
};

jQuery.extend(LogDetailsView.prototype, Application.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
		this.errorMessage = new ExpandableTextarea({
			label: lang.ERROR_MESSAGE,
			renderTo: $('.cw_error_message'),
			readonly: true
		});
		this.notificationMessage = new ExpandableTextarea({
			label: lang.NOTIFICATION_MESSAGE,
			renderTo: $('.cw_notification_message'),
			readonly: true
		});
		//render notification status indicator
		$('#cw_service_log_title').prepend(Renderer.serviceLogStatus(this.status));
		//set notification message
		this.notificationMessage.value(this.message);
		this.load();
		this.adjustSectionHeight();
		this.removeMask();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_atnav_prev').on('click', $.proxy(this.onPrevAttachButton, this));
		$('#cw_atnav_next').on('click', $.proxy(this.onNextAttachButton, this));
		$('#cw_service_create_incident').on('click', $.proxy(this.onIncidentCreate, this));
		
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_atnav_prev').off();
		$('#cw_atnav_next').off();
		$('.cw_list_item').off();
		$('#cw_service_create_incident').off();
	},
	/**
	 * gets the log details from the server
	 */
	load: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/' + this.serviceId + '/notifications/' + this.notificationId + '/details/' + this.type + '';
		//var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/' + this.serviceId + '/notifications/' + this.notificationId + '/details';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var data = result.data;
				this.data = result.data;
				$('#cw_error_code').val(data.code);
				this.errorMessage.value(data.message);
				$('#cw_error_time').val(Renderer.browserDateRenderer(data.time, Constants.DATETIME));
				data.attachments = data.attachments || [];
				this.attachmentsList.setDataSource(new kendo.ceeview.DataSource({
					data: data.attachments
				}));
				if (data.stackTrace) {
					$('#cw_at_download').addClass('hide');
					this.attachmentsList.setDataSource(new kendo.ceeview.DataSource({
						data: [{
							name: 'Stacktrace',
							id: 'Stacktrace',
							mimeType: 'stacktrace',
							content: data.stackTrace
						}]
					}));
					$('.cw_log_attachments').addClass('hide');
					this.attachmentsList.enable(false);
				} else {
					this.attachmentsList.setDataSource(new kendo.ceeview.DataSource({
						data: data.attachments
					}));

					var length = data.attachments.length;
					if (length) {
						if (length > 1) {
							$('#cw_atnav_next').removeAttr('disabled');
						}
						$('#cw_at_download').removeClass('hide');
						this.attachmentsList.enable(true);
						//render the attachments list into the right panel
						for (var i = 0; i < length; i++) {
							var linkHref = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/' + this.serviceId + '/notifications/' + this.notificationId + '/details/' + this.type + '/attachments/' + data.attachments[i].id + '/download';
							var html = '<li class="cw_list_row pointer" data-id="' + data.attachments[i].id + '"><div class="cw_list_item w60">' + Renderer.fileIcon(data.attachments[i].mimeType) + data.attachments[i].name;
							html += '</div><div class="cw_list_item w20">' + Renderer.fileType(data.attachments[i].mimeType) + '</div><div class="cw_list_item w20"><a href="' + linkHref + '"><span class="glyphicons download-alt"></span></a></div></li>';
							$('.cw_log_attachments').append(html);
						}

						$('.cw_list_item').off();
						$('.cw_list_item').on('click', $.proxy(this.onAttachmentClick, this));
						$('.cw_list_item a').on('click', $.proxy(this.onDownloadAttachment, this));
					}
				}
				if (this.attachmentsList.dataSource.total() > 0) {
					this.attachmentsList.select(0);
					this.attachmentsList.trigger('change', {sender: this.attachmentsList});
				}
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Initialize Kendo components
	 */
	initKendoComponents: function () {
		this.attachmentsList = $('#cw_log_attachments').kendoDropDownList({
			dataSource: [],
			dataTextField: 'name',
			dataValueField: 'id',
			change: $.proxy(this.onAttachmentSelect, this)
		}).data('kendoDropDownList');
	},
	/**
	 * Handler function for the click event on the prev attachment button
	 */
	onPrevAttachButton: function () {
		var currentIndex = this.attachmentsList.select();
		this.attachmentsList.select(currentIndex - 1);
		this.attachmentsList.trigger('change', {sender: this.attachmentsList});
		if (currentIndex === 0) {
			$('#cw_atnav_prev').attr('disabled', 'disabled');
		}
		$('#cw_atnav_next').removeAttr('disabled');
	},
	/**
	 * Handler function for the click event on the next attachment button
	 */
	onNextAttachButton: function () {
		var currentIndex = this.attachmentsList.select();
		this.attachmentsList.select(currentIndex + 1);
		this.attachmentsList.trigger('change', {sender: this.attachmentsList});
		if (currentIndex + 1 === this.attachmentsList.dataSource.total() - 1) {
			$('#cw_atnav_next').attr('disabled', 'disabled');
		}
		$('#cw_atnav_prev').removeAttr('disabled');
	},
	onDownloadAttachment: function (e) {
		e.stopPropagation();
	},
	onAttachmentSelect: function (e) {
		var selectedIndex = e.sender.selectedIndex, record = e.sender.dataSource.at(selectedIndex);
		this.getPreviewContent(record);
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/' + this.serviceId + '/notifications/' + this.notificationId + '/details/' + this.type + '/attachments/' + record.id + '/download';
		$('#cw_at_download').attr('href', url);
		$('#cw_atnav_prev').removeAttr('disabled');
		$('#cw_atnav_next').removeAttr('disabled');
		if (selectedIndex === 0) {
			$('#cw_atnav_prev').attr('disabled', 'disabled');
		}
		if (selectedIndex === e.sender.dataSource.total() - 1) {
			$('#cw_atnav_next').attr('disabled', 'disabled');
		}

		var currentClickedEl = $('.cw_list_row').eq(selectedIndex + 1);
		if (this.clickedAttachment)
			this.clickedAttachment.removeClass('highlight');
		this.clickedAttachment = currentClickedEl;
		currentClickedEl.addClass('highlight');
	},
	onAttachmentClick: function (e) {
		var id = $(e.currentTarget).closest('li').attr('data-id');
		this.attachmentsList.value(id);
		this.attachmentsList.trigger('change', {sender: this.attachmentsList});
	},
	getPreviewContent: function (data) {
		var contentType = '';
		if (data.mimeType === 'stacktrace') {
			$('#text_preview').removeClass('hide').empty().append('<pre>' + data.content + '</pre>');
		} else {
			//content type detection
			if (data.mimeType.indexOf('image') > -1) {
				contentType = 'image';
			}
			if (data.mimeType === 'application/pdf') {
				contentType = 'pdf';
			}
			if (data.mimeType === 'text/plain') {
				contentType = 'text';
			}
			if (data.mimeType === 'text/html') {
				contentType = 'html';
			}
			$('#cw_service_log_preview').children().each(function (index) {
				$(this).addClass('hide');
			});
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/' + this.serviceId + '/notifications/' + this.notificationId + '/details/' + this.type + '/attachments/' + data.id + '';
			if (contentType === 'image') {
				$('#image_preview').removeClass('hide').empty().append('<img src="' + url + '" />');
			}
			if (contentType === 'text') {
				jQuery.ajax({
					url: url,
					type: 'GET',
					data: {},
					dataType: "text",
					contentType: "text/plain; charset=utf-8",
					crossDomain: true,
					beforeSend: function (x) {
						if (x && x.overrideMimeType) {
							x.overrideMimeType("text/plain;charset=UTF-8");
							x.setRequestHeader('Auth-Token', Cookies.sessionId);
						}
					},
					error: function (result, status, obj) {
						//TODO: implement error for no content
					},
					success: function (result, status, obj) {
						$('#text_preview').removeClass('hide').empty().append('<pre>' + result + '</pre>');
					}
				});
			}
			if (contentType === 'html') {
				jQuery.ajax({
					url: url,
					type: 'GET',
					data: {},
					dataType: "html",
					contentType: "text/html; charset=utf-8",
					crossDomain: true,
					beforeSend: function (x) {
						if (x && x.overrideMimeType) {
							x.overrideMimeType("text/html;charset=UTF-8");
							x.setRequestHeader('Auth-Token', Cookies.sessionId);
						}
					},
					error: function (result, status, obj) {
						//TODO: implement error for no content
					},
					success: function (result, status, obj) {
						$('#html_preview').removeClass('hide').empty().append('<xmp>' + result + '</xmp>');
					}
				});
			}
			if (contentType === 'pdf') {
				$('#pdf_preview').removeClass('hide').empty();
				var pdfObject = new PDFObject({
					url: url,
					id: 'myPDF',
					width: '100%',
					height: '100%',
					pdfOpenParams: {view: 'Fit Height'}
				}).embed('pdf_preview');
			}
		}
	},
	/**
	 * Handler function for the click event on the create incident menu item
	 */
	onIncidentCreate: function (e) {
		var accountList = [{
			id: Cookies.CeesoftCurrentAccountId,
			name: Cookies.CeesoftCurrentAccountName
		}];
		let serviceLogs = [this.data.serviceLog];
		if(this.module.initialConfig.closeWindow)
			this.module.initialConfig.closeWindow()
		State.mainApp.navigate(IncidentsRouter.createNew('SERVICE'), {
			extra: {
				serviceId: this.serviceId,
				serviceName: this.serviceName,
				contentCategory: 'SERVICE',
				accountList,
				serviceLogs
			}
		});
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('#pdf_preview').closest('.cw_section');
		var sectionHeight = section.height();
		$('.cw_section_content, #pdf_preview, #image_preview, #text_preview, #html_preview').css('height', sectionHeight - 40);
	},
	/**
	 * Handler function for the expand mechanism
	 */
	onTextareaExpand: function (e) {
		var windowArea = $('.window_area');
		var windowAreaWidth = windowArea.width();
		var textareaWidth = windowAreaWidth - 30 - Math.floor(windowAreaWidth * 2 / 100);
		var container = $(e.currentTarget).closest('.expandable_area');
		var textarea = container.find('textarea');

		textarea.toggleClass('expanded');
		if (textarea.hasClass('expanded')) {
			container.closest('.cw_section_content').css({'overflow': 'visible'});
			windowArea.parent().append('<div class="cw_mask"></div>');
			textarea.css({'width': textareaWidth});
			textarea.parent().css('z-index', '20');
		} else {
			textarea.attr('style', '');
			textarea.parent().attr('style', '');
			windowArea.parent().find('.cw_mask').remove();
		}
	}
});

import CommonType from './commonType';
import {compileName} from "areas/service-boards/widgets/stackChartWidget/helpers";
import Utils from 'tools/utils';

const DEFAULT_CONFIG = {
	chart: {
		plotBackgroundColor: null,
		plotBorderWidth: null,
		plotShadow: false,
		type: 'pie'
	},
	series: [{
		data: []
	}],
	plotOptions: {
		pie: {
			dataLabels: {
				enabled: false
			},
			size: "80%"
		}
	}
};

class PieType extends CommonType {
	defaultConfiguration() {
		return DEFAULT_CONFIG;
	}

	getMetrics(config): Array<any> {
		return config.series[0].data;
	}

	addOriginalMetrics(config: any, originalMetircs: Array<any>) {
		config.series = config.series || [];

		if (!config.series[0]) {
			config.series.push({ data: [] });
		}

		const series = config.series[0];

		if (!series.data) {
			series.data = [];
		}

		series.data = originalMetircs || [];
	}

	setMetrics(config: any, metrics, labelTemplate) {
		config.series = config.series || [];

		if (!config.series[0]) {
			config.series.push({ data: [] });
		}

		const series = config.series[0];

		if (!series.data) {
			series.data = [];
		}

		for (const metric of metrics) {
			const found = series.data.find((series) => series.id === metric.metricId);

			if (found) {
				continue;
			}

			const name = compileName(labelTemplate, metric);

			series.data.push({
				id: metric.metricId,
				color: metric.color,
				name
			});
		}

		const ids = metrics.map((metric) => metric.metricId);

		series.data = series.data.filter((series) => {
			return !(!ids.includes(series.id) && Utils.isGuid(series.id));
		});

	}
}

export default PieType;

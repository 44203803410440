export const FILTERING_TYPES = {
	SERVICE_STATE: 'SERVICE_STATE',
	STATE_DURATION: 'STATE_DURATION',
	INCIDENT_NUMBER: 'INCIDENT_NUMBER',
	TAGS: 'TAGS',
	MONITORS: 'MONITORS',
	ASSETS: 'ASSETS',
	ASSET_GROUPS: 'ASSET_GROUPS'
};

export const GRID_FILTERING_TYPES = {
	MONITORS: 'MONITORS',
	ASSETS: 'ASSETS',
	ASSET_GROUPS: 'ASSET_GROUPS'
}

export const OPERATORS = {
	EQUAL: '==',
	NOT_EQUAL: '!='
};

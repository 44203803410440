import { MultiSelect } from "controls/react/form";
import PropTypes from "prop-types";
import React from "react";
import { useMetricCategories } from "./useMetricCategories";

MetricCategoryMultiSelect.propTypes = {
	accountId: PropTypes.string.isRequired,
	value: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool
};

const options = {
	dataTextField: "path",
	dataValueField: "id"
};

/**
 * @return {null}
 */
export function MetricCategoryMultiSelect(props) {
	const [metricCategories] = useMetricCategories(props.accountId);

	if (!metricCategories) {
		return null;
	}

	let tagTemplate = (data) => {
		return `<span title=${data.path}>${data.path}</span>`;
	}

	return (
		<div>
			<MultiSelect value={props.value}
						 data={metricCategories}
						 onChange={props.onChange}
						 tagTemplate={tagTemplate}
						 options={{...options,...props.options}}/>
		</div>
	);
}

import React from 'react';
import {PLUGIN_STATUS} from "controls/react/rsuite/pluginStatus";
import {TableContext} from "controls/react/rsuite/constants";

export function withPlugins(Component) {
	return function WithPluginsWrapper(props) {
		const pluginsRef = React.useRef(props.plugins || []);
		const statuses = [];

		const {state, dispatch} = React.useContext(TableContext);

		const pluginProps = pluginsRef.current.reduce((previousProps, plugin) => {
			let usePlugin;
			let config;

			if (Array.isArray(plugin)) {
				([usePlugin, config] = plugin);
			} else {
				usePlugin = plugin;
			}

			const [nextProps, status] = usePlugin(previousProps, config, state, dispatch);

			statuses.push(status);

			return nextProps;
		}, props);

		for (const status of statuses) {
			if (status === PLUGIN_STATUS.PREVENT_RENDER) {
				return null;
			}
		}

		return <Component {...pluginProps} />
	}
}

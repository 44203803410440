import React from 'react';
import ReactDOM from "react-dom";

import {Utils} from "tools";
import {Settings} from "settings";
import {Cookies, LocalEventsManager} from "core";
import {AssetGroupSelector, CustomNotification, ExpandableTextarea, Tags} from "controls";
import {AdministrationApi} from "areas/administration/api";
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";

export let AssetGroupForm = function(config){
    Utils.apply(this, config);
    this.initComponent();
};

export default AssetGroupForm;

AssetGroupForm.prototype = {
	/**
	* This is main init function
	*/
	initComponent: function(){
		this.update = true;
		if (this.mode === 'view') {
			this.update = false;
		}
		var topMessage = lang.account.messages.ASSET_GROUP_TOP_MESSAGE;
		var actionButtonText = lang.CREATE;

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('#modal .cw_description')
		});

        this.tagsList = [];

		this.assetsLoaded = false;
		this.dataLoaded = false;
		if (this.mode === 'update') {
			topMessage = lang.account.messages.ASSET_GROUP_TOP_MESSAGE_UPDATE;
			actionButtonText = lang.UPDATE;
		}
		$('#save_asset_group').text(actionButtonText);
		$('#top_message').html(topMessage);
		this.removeListeners();
		this.attachListeners();
		if (this.mode === 'update' || this.mode === 'view') {
			this.load();
			$('.create_asset_group .cw_tier_top').css('height', '285px');
			$('.create_asset_group .cw_tier_bottom').css('top', '285px');
			var assetGroupInput = $('#cw_assetgroup_id');
			assetGroupInput.val(this.id);
			assetGroupInput.closest('.cw_field').removeClass('hide');
		} else {
			var focus = function() {
				$('#asset_group_name').focus();
			};
			setTimeout(focus, 1000);
			this.renderTags();
            this.initKendoComponents();
		}
		if (this.mode === 'view') {
			this.removeListeners();
			this.enableViewMode();
		}
	},
    /**
     * Iniitalizes kendo components
     */
    initKendoComponents: function () {
        Utils.onLiveChange('#asset_group_name', '#card_name', lang.account.ASSET_GROUP_NAME);
        Utils.onLiveChange( '.cw_description textarea', '#card_description', lang.account.ASSET_GROUP_DESCRIPTION);
        //Utils.onLiveChange(this.descriptionHandler.textarea, lang.account.ASSET_GROUP_DESCRIPTION);

        this.actionNotification = new CustomNotification({
            appendToElement: '.window_area',
            status: 'success',
            style: 'top:15px; right:15px; left:15px;',
            type: 'icon',
            hideOnClick: true
        });

        this.modalNotification = new CustomNotification({
            appendToElement: '#modal',
            status: 'success',
            type: 'icon',
            hideOnClick: true
        });

        $('.create_asset_group .cw_tabs_absolute').kendoTabStrip();
        this.assetSelector = new AssetGroupSelector({
            renderTo: $('#cw_asset_selector'),
            accountId: this.accountId,
			mode: this.mode
        });
        if (this.assets) {
            this.assetSelector.setValue(this.assets);
        }

        this.adjustGridHeight();
    },
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#asset_group_name').addClass('is_readonly');
		this.descriptionHandler.readonly(true);
		$('.is_readonly').attr('disabled', true);
		$('#save_asset_group').addClass('hide');
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function(){
		$('#save_asset_group').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function(){
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_asset_group').on('click', $.proxy(this.onSaveButton, this));
        var modalWindow = $('#modal').data("kendoWindow");
        if(modalWindow) {
            modalWindow.bind('resize', $.proxy(this.onModalResize, this));
        }
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function(){
		$('#asset_group_name').removeClass('invalid');
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function(){
		const loadUrl = AdministrationApi.getAssetGroupUrl(this.accountId, this.id, this.update);

		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function(result){
			if (result.success) {
                this.assets = result.data.members;

                this.initKendoComponents();

				$('#asset_group_name').val(result.data.name);
				$('#card_name').text(result.data.name);
				this.descriptionHandler.value(result.data.description);
				$('#card_description').text(result.data.description || lang.account.ASSET_GROUP_DESCRIPTION);
				$('#asset_group_name').focus();
				this.dataLoaded = true;
				this.assetSelector.setValue(result.data.members);
				this.tagsList = result.data.tags;
				this.renderTags();
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function(result){
		if (result.success) {
			//close window
			$('#cancel').trigger('click');
			// show status
			//TODO: where is this status?
			var successText = (this.mode === 'create' ? lang.account.messages.ASSET_GROUP_SUCCESS_CREATED : lang.account.messages.ASSET_GROUP_SUCCESS_UPDATED);
			this.actionNotification.setOptions({
				message: successText,
				status: 'success'
			}).show();
			LocalEventsManager.trigger('assetgroupsaved');
			/*if (this.triggerAddToGroup){
				LocalEventsManager.trigger('assetsAddedToGroup');
			}*/
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function(e){
		this.clearInvalid();
		var assets = this.assetSelector.getValue();
		this.data = {
			name: $('#asset_group_name').val().trim(),
			description: this.descriptionHandler.value().trim(),
			id: (this.id === 'new_asset_group' ? null : this.id),
			members: assets,
			tags: this.tagsList
		};
		if (this.data.name.trim() === '') {
			this.modalNotification.setOptions({
				message: lang.account.messages.ASSET_GROUP_INPUT_NAME,
				status: 'error'
			}).show();

			var assetGroupName = $('#asset_group_name');
			assetGroupName.addClass('invalid');
			assetGroupName.focus();
		}
		else {
			var url = Settings.serverPath + 'accounts/' + (this.accountId || Cookies.CeesoftCurrentAccountId) + '/assetGroups/';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton:  function(e){
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	},
    /**
     * Resize event for Modal Window
     */
    onModalResize: function() {
        this.adjustGridHeight();
    },
    /**
     * Adjust Grids height based on the modal resize event
     */
    adjustGridHeight: function() {
        var gridsWrapper = $('#cw_asset_selector');
        var grids = gridsWrapper.find('.k-grid');
        var gridHeight = gridsWrapper.find('.cw_section:first-child').outerHeight();
        grids.css('height', gridHeight - 42 - 2);
        grids.find('.k-grid-content').css('height', gridHeight - 42 - 29 - 2);
    },
	/**
	 * Populates the assets control with a given list
	 * @param {Array} assets Array with assets ids
	 */
	populateAssets: function(assets){
		var assetsList = $("#cw_assets_list").data('kendoSortedMultiSelect');
		assetsList.value(assets);
		assetsList.enable(false);
	},

	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical style={{maxWidth: '100%'}}>
			<TagsSelect
				mode={'tags'}
				accountId={this.accountId}
				includeSubaccounts={false}
				disabled={this.mode === 'view'}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList}
				placeholder={lang.messages.TAG_CHOOSE} />
		</FormEntry>, $('.cw_group_tags').parent().get()[0]);
	},

	onTagsChange(value) {
		this.tagsList = value || [];
	}
};

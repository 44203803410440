export default function stripZeros(str, decimals = 3) {
	str = str + '';

	let delimiter = -1;

	const pointDelimiter = str.indexOf(".");
	const commaDelimiter = str.indexOf(",");

	if (pointDelimiter !== -1) {
		delimiter = pointDelimiter;
	}
	else if (commaDelimiter !== -1) {
		delimiter = commaDelimiter;
	}
	else {
		return str;
	}

	if (decimals > 0) {
		return str.slice(0, delimiter + decimals + 1);
	}
	else {
		return str.slice(0, delimiter + decimals);
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import {LoadingState} from 'controls/react/rsuite/constants';

function useDataSource(dataSource, page, filters, sorters) {
	const maxPageRef = React.useRef(-1);

	const [data, setData] = React.useState([]);
	const [filterOptions, setFilterOptions] = React.useState({});
	const [filteredBy, setFilteredBy] = React.useState([]);
	const [loadingState, setLoadingState] = React.useState(LoadingState.LOADING);

	const addDataRef = React.useRef();

	const previousFiltersRef = React.useRef(filters);
	const previousSortersRef = React.useRef(sorters);
	const previousDataSourceRef = React.useRef(dataSource);
	const previousVisibleRef = React.useRef();

	addDataRef.current = React.useCallback((rows, reset) => {
		if (reset) {
			setData([...rows])
		}
		else if (rows.length > 0) {
			setData([...data, ...rows])
		}
	}, [data]);

	React.useEffect(() => {
		const fetchData = async () => {
			let reset = false;

			if (previousFiltersRef.current !== filters) {
				reset = true;
			}

			if (previousSortersRef.current !== sorters) {
				reset = true;
			}

			if (previousDataSourceRef.current !== dataSource) {
				reset = true;
			}

			if (!reset && page <= maxPageRef.current) {
				return;
			}

			if (reset) {
				previousVisibleRef.current = null;
			}

			setLoadingState(LoadingState.LOADING);

			const {items: rows, visible, filterOptions, filteredBy} = await dataSource.read(page, filters, sorters, {
				previousVisible: previousVisibleRef.current
			})

			previousSortersRef.current = sorters;
			previousFiltersRef.current = filters;
			previousDataSourceRef.current = dataSource;
			previousVisibleRef.current = visible;

			setLoadingState(LoadingState.LOADED);

			maxPageRef.current = page;

			addDataRef.current(rows, reset);

			setFilterOptions(filterOptions);

			setFilteredBy(filteredBy);
		}

		fetchData();
	}, [dataSource, page, filters, sorters]);

	return [data, filterOptions, loadingState, filteredBy];
}

export default useDataSource;

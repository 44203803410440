import {Api} from "tools/api";

export default class MonitorAPI {
	static allMonitorUrl() {
		return `${Api.accountRoot()}monitors/?includeSubaccounts=true`;
	}

	static getAllMonitors() {
		return Api.fetch(MonitorAPI.allMonitorUrl(), { credentials: undefined });
	}
}

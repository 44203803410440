import produce from "immer";
import {changeHandler} from "core/react/reducerHelpers";

export const ACTIONS = {
};

function _reducer(draft, action) {
	switch(action.type) {
		case 'CHANGE':
			changeHandler(draft, action);
			break;
	}
}

export const reducer = produce(_reducer);

export const reducerWithLogger = produce((draft, action) => {
	const state = _reducer(draft, action);

	console.log(JSON.stringify(draft, null, 4), action)
});

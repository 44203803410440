import React from 'react';
import PropTypes from 'prop-types';

const iconNameByIndex = ['d', 'c', 'b']

function SortIcon(props) {
	const {direction, multiple, index} = props;

	const indexIcon = React.useMemo(() => {
		return multiple ? <span data-icon={iconNameByIndex[index]} />: null;
	}, [index, multiple]);

	const directionIcon = React.useMemo(() => {
		if (direction === 'asc') {
			return 'cw_sort_icon_arrow_up'
		}
		else if (direction === 'desc') {
			return 'cw_sort_icon_arrow_down'
		}
		else {
			return null;
		}
	}, [direction]);

	if (!directionIcon) {
		return null;
	}

	return <span className="cw_grid_sort"><span className={directionIcon} />{indexIcon}</span>
}

SortIcon.propTypes = {
	direction: PropTypes.string,
	multiple: PropTypes.bool,
	index: PropTypes.number
};

export default SortIcon;

import Lang from 'core/localization/lang'

export const aggregationTypeDataSource = [
	{
		text: Lang.HIGH,
		value: 'high'
	}, {
		text: Lang.LOW,
		value: 'low'
	}, {
		text: Lang.AVERAGE,
		value: 'average'
	}
];

export default aggregationTypeDataSource;

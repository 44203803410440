import {Settings} from "settings";
import {Cookies} from "core/cookies";
import {Api} from "tools/api"
import {Team} from './team';

export class TeamApi {
	static getLiteUrl(accountId) {
		return `${Api.accountRoot(accountId)}teams/responsibleTeams/lite`;
	}
	static getAboveTeams(id, teamId){
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/teams/responsibleTeams/${teamId}`)
	}

	static getLite(accountId = null): Team[]{
		return Api.fetch(TeamApi.getLiteUrl(accountId));
	}

	static getResponsibleTeamsLite(accountId, teamIds): Team[]{
		return Api.fetchPost(TeamApi.getLiteUrl(accountId), teamIds);
	}

	static getSearchUrl() {
		return Api.accountRoot() + 'teams/lite?name=';
	}
}

import React from "react";
import {DropDownList} from "controls/react/form";
import {DataSourceSeverity} from "tools";


export class SeverityDropDown extends React.PureComponent {
	constructor(props) {
		super(props);

		this.config = {
			dataSource: DataSourceSeverity,
			dataTextField: 'text',
			dataValueField: 'value',
			autoBind: true,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
		};
	}

	render() {
		return <DropDownList config={this.config} {...this.props}/>
	}
}

export default SeverityDropDown;

import React from "react";
import ReactDOM from "react-dom";

class Portal extends React.PureComponent {
	render() {
		const {children} = this.props;

		return ReactDOM.createPortal(
			children,
			window.document.body
		);
	}
}

export default Portal;

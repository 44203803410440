// import * as React from 'react';
// import { observer } from 'mobx-react';
// import { configure } from 'mobx';

// configure({
// 	enforceActions: 'never'
// })
//
// setTimeout(() => {
// 	configure({
// 		reactionScheduler: (f) => {
// 			setTimeout(f, 1);
// 		},
// 	});
// }, 1);

import * as React from 'react';
import { observer } from 'mobx-react';
import { configure } from 'mobx';

configure({
	enforceActions: 'never'
})

const wrappedComponentsSet = new WeakSet();
const wrappedComponentsMap = new WeakMap();

let inputChanged = false;
let inputChangedTimeout = null;

// @ts-ignore
const createElement = React.createElement;
// @ts-ignore
React.createElement1 = function() {
	const target = arguments[0];
	if (target === 'input' || target === 'textarea') {
		if (arguments[1] && arguments[1].onChange) {
			const changeFn = arguments[1].onChange;
			arguments[1].onChange = (...args) => {
				inputChanged = true;

				clearTimeout(inputChangedTimeout);
				setTimeout(() => {
					inputChanged = false;
				});

				changeFn(...args);
			}
		}
	}

	if (typeof target === 'function' && !wrappedComponentsSet.has(target)) {
		if (target.prototype?.shouldComponentUpdate) {
			delete target.prototype.shouldComponentUpdate;
		}
		//console.log(target);

		wrappedComponentsSet.add(target);
		const wrapped = observer(target);
		arguments[0] = wrapped;
		wrappedComponentsMap.set(target, wrapped);
	} else if (wrappedComponentsSet.has(target)) {
		arguments[0] = wrappedComponentsMap.get(target) || observer(target);
	}
	return createElement.apply(this, arguments);
};


// let reactionSchedulerTimeout = null;
//
// // Configure MobX to auto batch all sync mutations without using action/runInAction
// setTimeout(() => {
// 	configure({
// 		reactionScheduler: (f) => {
// 			if (inputChanged) {
// 				f();
// 			} else {
// 				clearTimeout(reactionSchedulerTimeout);
//
// 				reactionSchedulerTimeout = setTimeout(f);
// 			}
// 		},
// 	});
// }, 1);

import './text.less';

import Utils from 'tools/utils';
import Settings from 'settings';
import {Widget} from "areas/service-boards/widget";

export function TextWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		this.mode = 'detached';
	}
	this.initComponent();
};

export {TextWidget as default}

jQuery.extend(TextWidget.prototype, Widget.prototype, {

	initComponent: function () {
		this.render();
		this.updateTitle();
	},

	render: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		var text = this.instanceConfiguration.text.replace("\n", "<br />", "g");

		var textLinksRegex =
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z0-9]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?;&//=]*)/ig;
		var anchorLinksRegex = /<a[^<>]*?>.*?<\/a>/ig;

		//Find all <a></a> links
		var match;
		var skipMatches = [];
		while ((match = anchorLinksRegex.exec(text)) !== null) {
			skipMatches.push(match);
		}

		//Find all links in text and skip already matched <a></a> links
		var matches = [];
		while ((match = textLinksRegex.exec(text)) !== null) {
			if (!skipMatches.some(this.shouldBeSkipped, match)) {
				matches.push(match);
			}
		}

		//Replace matches starting from the end to avoid changing indexes
		for (var i = matches.length - 1; i >= 0; i--) {
			var url = matches[i][0];
			text = text.substring(0, matches[i].index) +
				'<a href="' + url + '">' + url + '</a>' +
				text.substring(matches[i].index + url.length);
		}

		//widgetContentDiv.empty().append('<div class="cw_custom_text_widget" style="font-size:'+ this.instanceConfiguration.fontSize + 'px;"></div>');
		widgetContentDiv.empty().append('<div class="cw_custom_text_widget"></div>');
		widgetContentDiv.find('.cw_custom_text_widget').append(jQuery.parseHTML(text, false));

		if (this.instanceConfiguration.removeBorder) {
			$('#' + this.id).css({
				'box-shadow': 'none',
				'border-radius': '0'
			});
		}
		if (this.instanceConfiguration.removeHeader) {
			$('#' + this.id).addClass('no_header');
		} else {
			$('#' + this.id).removeClass('no_header');
		}
	},

	shouldBeSkipped: function (skipElement) {
		return this.index >= skipElement.index && this.index <= skipElement.index + skipElement[0].length;
	},

	updateTitle: function () {
		if (!this.title) {
			var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			var title = lang.TEXT;
			var strTitle = title;
			titleSpan.html(title);
			titleSpan.attr('title', strTitle);
		}
	},

	destroy: function () {

	}
});

export function newGuid(){
	// http://www.ietf.org/rfc/rfc4122.txt
	var i, c = "89ab", u = [];
	for (let i = 0; i < 36; i += 1) {
		u[i] = (Math.random() * 16 | 0).toString(16);
	}
	u[8] = u[13] = u[18] = u[23] = "-";
	u[14] = "4";
	u[19] = c.charAt(Math.random() * 4 | 0);
	return u.join("");
}

import React from "react";

import {NumericTextBox} from "controls/react/form/numericTextBox";

export default class Range extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		return <div>
			<NumericTextBox value={this.props.value}
							onChange={this.props.onChange}
							onSpin={this.props.onSpin}
							min={this.props.min}
							max={this.props.max}
							spinners={this.props.spinners}
							enabled={this.props.enabled}
							step={this.props.step}
							id={this.props.id}
							fullWidth={true}
							type='generic'
			/>
		</div>
	}
}

export const severity = Object.freeze({
    NONE: 'NONE',
    MINOR: 'MINOR',
    MAJOR: 'MAJOR',
    CRITICAL: 'CRITICAL'
});

export const severityToCssClass = Object.freeze({
  [severity.NONE]: 'is_ok',
  [severity.MINOR]: 'is_minor',
  [severity.MAJOR]: 'is_major',
  [severity.CRITICAL]: 'is_critical'
});
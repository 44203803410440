import React from 'react';
import {setValueOnPath, getValueOnPath} from "tools/utils";

function applyDefaults(init, defaults) {
	for (const key of Object.keys(defaults)) {
		if (!getValueOnPath(init, key)) {
			setValueOnPath(init, key, defaults[key]);
		}
	}
}

export function useContextReducer(reducer, init = {}, defaults = {}) {
	applyDefaults(init, defaults)
	const [state, dispatch] = React.useReducer(reducer, init);
	return React.useMemo(() => ({state, dispatch}), [state, dispatch]);
}

export function useContextField(Context, path) {
	const fullPath = path;
	const {state, dispatch} = React.useContext(Context);
	const checkRef = React.useRef();
	const outputRef = React.useRef();

	const updateField = React.useCallback((value) => {
		dispatch({ type: 'CHANGE', path: fullPath, value, check: checkRef.current });
	}, []);

	const input = {value: getValueOnPath(state, fullPath), onChange: updateField};

	const checkCallback = React.useCallback((message, callback) => {
		checkRef.current = {message, callback};
		return outputRef.current;
	}, []);

	outputRef.current = {
		...input,
		props: input,
		check: checkCallback
	};

	return outputRef.current;
}

export function useContextFieldChanged(Context, path, callback) {
	const {state, dispatch} = React.useContext(Context);

	React.useEffect(() => {
		callback(state);
	}, [getValueOnPath(state, path)])
}

export function changeHandler(draft, action) {
	setValueOnPath(draft, action.path, action.value);
}

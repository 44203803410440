export function oneOfThemRequired(requiredProps) {
	return (props, propName, componentName) => {
		if (!requiredProps.includes(propName)) {
			return;
		}

		let present = false;

		for (const prop of requiredProps) {
			if (props[prop]) {
				present = true;
				break;
			}
		}

		if (!present) {
			throw new Error(`One of props ${requiredProps.join(', ')} is required for ${componentName}`);
		}
	};
}

import Api from 'tools/api';

export default class DocumentationAPI {
    static getMonitorDocumentationUrl(monitorType) {
      return Api.accountRoot() + `monitors/${monitorType}/documentation`;
    }
    
    static getActionTriggerDocumentationUrl() {
      return Api.accountRoot() + `documentation/actionTrigger`;
    }
}

export {DocumentationAPI}
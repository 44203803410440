var monitors = {
	installedMonitors: [],
	installedMonitorsNames: {},
	installedMonitorSettings: {},
	process: {},
	service: {},
	external: {},
	eventlog: {},
	groovy: {},
	connectivity: {},
	prtg: {},
	vmware: {},
	vmm: {},
	hyperv: {},
	nagios: {},
	snmp: {},
	mscluster: {},
	ibmstorwize: {},
	solarwinds: {},
	nimsoft: {},
	mailgateway: {},
	log: {},
	eatonups: {},
	modbus: {},
	url: {},
	fragments: {},
	all: {},
	config: [{
		type: 'SYSTEM',
		prefix: 'sys',
		modules: {
			view: 'AssetHealthSystemMonitor',
			details: 'MonitorSystem'
		}
	}, {
		type: 'NIMSOFT',
		prefix: 'nim',
		modules: {
			view: 'AssetHealthSystemNimsoft',
			details: 'MonitorNimsoft'
		}
	}, {
		type: 'PROCESSES',
		prefix: 'proc',
		modules: {
			view: 'AssetHealthProcess',
			details: 'MonitorProcess'
		}
	}, {
		type: 'WINSERVICE',
		prefix: 'srv',
		modules: {
			view: 'AssetHealthService',
			details: 'MonitorService'
		}
	}, {
		type: 'CONNECTIVITY',
		prefix: 'connectivity',
		modules: {
			view: 'AssetHealthConnectivity',
			details: 'MonitorConnectivity'
		}
	}, {
		type: 'EXTERNAL',
		prefix: 'ext',
		modules: {
			view: 'AssetHealthExternal',
			details: 'MonitorExternal'
		}
	}, {
		type: 'WINEVENTS',
		prefix: 'evt',
		modules: {
			view: 'AssetHealthEventLog',
			details: 'MonitorEventLog'
		}
	}, {
		type: 'PRTG',
		prefix: 'prtg',
		modules: {
			view: 'AssetHealthPRTG',
			details: 'MonitorPRTG'
		}
	}, {
		type: 'VMWARE',
		prefix: 'vmware',
		modules: {
			view: 'AssetHealthVMware',
			details: 'MonitorVMware'
		}
	}, {
		type: 'VMM',
		prefix: 'vmm',
		modules: {
			view: 'AssetHealthVmm',
			details: 'MonitorVmm'
		}
	}, {
		type: 'HYPERV',
		prefix: 'hyperv',
		modules: {
			view: 'AssetHealthHyperv',
			details: 'MonitorHyperv'
		}
	}, {
		type: 'NAGIOS',
		prefix: 'nagios',
		modules: {
			view: 'AssetHealthNagios',
			details: 'MonitorNagios'
		}
	}, {
		type: 'SNMP',
		prefix: 'snmp',
		modules: {
			view: 'AssetHealthSnmp',
			details: 'MonitorSnmp'
		}
	}, {
		type: 'EATONUPS',
		prefix: 'eatonups',
		modules: {
			view: 'AssetHealthEatonUps',
			details: 'MonitorEatonUps'
		}
	}, {
		type: 'MODBUS',
		prefix: 'modbus',
		modules: {
			view: 'AssetHealthModbus',
			details: 'MonitorModbus'
		}
	}, {
		type: 'URL',
		prefix: 'url',
		modules: {
			view: 'AssetHealthUrl',
			details: 'MonitorUrl'
		}
	}, {
		type: 'FILESEARCH',
		prefix: 'filesearch',
		modules: {
			view: 'AssetHealthFilesearch',
			details: 'MonitorFilesearch'
		}
	}, {
		type: 'XENSERVER',
		prefix: 'xenserver',
		modules: {
			view: 'AssetHealthXenServer',
			details: 'MonitorXenServer'
		}
	}, {
		type: 'REMOTESYSTEM',
		prefix: 'rsm',
		modules: {
			view: 'AssetHealthRemoteSystem',
			details: 'MonitorRemoteSystem'
		}
	}, {
		type: 'IBMSVC',
		prefix: 'ibm',
		modules: {
			view: 'AssetHealthIbmStorwize',
			details: 'MonitorIbmStorwize'
		}
	}, {
		type: 'GROOVY',
		prefix: 'groovy',
		modules: {
			view: 'AssetHealthGroovy',
			details: 'MonitorGroovy'
		}
	},
		{
			type: 'MSCLUSTER',
			prefix: 'mscluster',
			modules: {
				view: 'AssetHealthMScluster',
				details: 'MonitorMScluster'
			}
		}, {
			type: 'MAILGTW',
			prefix: 'mailgtw',
			modules: {
				view: 'AssetHealthMailGateway',
				details: 'MonitorMailGateway'
			}
		}, {
			type: 'LOG',
			prefix: 'log',
			modules: {
				view: 'AssetHealthMonitorLog',
				details: 'MonitorLog'
			}
		}, {
			type: 'SOLARWINDS',
			prefix: 'solarwinds',
			modules: {
				view: 'AssetHealthSolarWinds',
				details: 'MonitorSolarWinds'
			}
		}]
};

if (monitors) {
	var config = monitors.config;
	var length = config.length;

	var _byType = {};
	var _byPrefix = {};
	var _byView = {};
	var _byDetails = {};

	var _item;
	for (var i = 0; i < length; i++) {
		_item = config[i];

		_byType[_item.type] = _item;
		_byPrefix[_item.prefix] = _item;
		_byView[_item.modules.view] = _item;
		_byDetails[_item.modules.details] = _item;
	}
}
export let Monitors = {
	/*
	* Handler function for checking if monitor type exists
	* @param {String} type The monitor type
	* @return {Boolean}
	* */
	isType: function (type) {
		return _byType[type] || false;
	},
	/*
	 * Handler function for checking if monitor module exists
	 * @param {String} type The monitor view module
	 * @return {Boolean}
	 * */
	isViewModule: function (viewModule) {
		return _byView[viewModule] || false;
	},
	/*
	 * Handler function for checking if monitor detail module exists
	 * @param {String} type The monitor detail module
	 * @return {Boolean}
	 * */
	isDetailModule: function (detailModule) {
		return _byDetails[detailModule] || false;
	},
	/*
	 * Handler function for checking if monitor prefix exists
	 * @param {String} type The monitor prefix
	 * @return {Boolean}
	 * */
	isPrefix: function (prefix) {
		return _byPrefix[prefix] || false;
	},
	/*
	 * Handler function for getting the monitor prefix
	 * @param {String} type The monitor type
	 * @return {String} The monitor prefix
	 * */
	getPrefixByType: function (type) {
		return _byType[type] && _byType[type].prefix || false;
	},
	/*
	 * Handler function for getting the monitor type
	 * @param {String} type The monitor prefix
	 * @return {String} The monitor type
	 * */
	getTypeByPrefix: function (prefix) {
		return _byPrefix[prefix] && _byPrefix[prefix].type || false;
	},
	/*
	 * Handler function for checking if the monitor module exists
	 * @param {String} module The monitor module name
	 * @return {String} The monitor type
	 * */
	isModule: function (module) {
		return _byDetails[module] || _byView[module] || false;
	}
}

export default Monitors;

import CommonType from './commonType';

const DEFAULT_CONFIG = {
	xAxis: {
		type: 'datetime',
		labels: {
			staggerLines: 1
		},
		dateTimeLabelFormats: {
			millisecond: '%H:%M:%S',
			second: '%H:%M:%S',
			minute: '%H:%M',
			hour: '%H:%M',
			day: '%e. %b',
			week: '%e. %b',
			month: '%b \'%y',
			year: '%Y'
		},
		title: {
			text: 'Time'
		},
	},
	yAxis: {
		title: {
			text: 'Unit'
		}
	},
	plotOptions: {
		scatter: {
			marker: {
				radius: 5,
				states: {
					hover: {
						enabled: true,
						lineColor: 'rgb(100,100,100)'
					}
				}
			},
			states: {
				hover: {
					marker: {
						enabled: false
					}
				}
			},
			tooltip: {
				headerFormat: '<b>{series.name}</b><br>',
				pointFormat: '{point.x} cm, {point.y} kg'
			}
		}
	},
};

class ScatterType extends CommonType {
	defaultConfiguration() {
		return DEFAULT_CONFIG;
	}
}

export default ScatterType;

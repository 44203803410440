import {Api} from "tools/api";
import Utils from 'tools/utils';

export class AuthenticationAPI {
	static getUrl(accountId, types) {
		if (types?.length) {
			let url = `${Api.accountRoot(accountId)}authentications/list?`;
			for (let type of types) {
				url += `type=${type}&`;
			}
			return url;
		} else {
			return `${Api.accountRoot(accountId)}authentications/list?&dc=${Utils.timestamp()}`;
		}
	}
}

export default AuthenticationAPI;

const store: Map<number, Set<string>> = new Map();
const metricData: Map<string, Map<number, number>> = new Map();

export function addTooltipSeries(metricId: string, data: Array<[number, number]>) {
	metricData.set(metricId, new Map(data));

	for (const point of data) {
		const [x] = point;
		const metrics: Set<string> = store.get(x) || new Set();

		metrics.add(metricId);
		store.set(x, metrics);
	}
}

export function addTooltipMetric(metricId: string, data: [number, number]) {		
		const [x, y] = data;
		metricData.get(metricId).set(x, y)	
		const metrics: Set<string> = store.get(x) || new Set();
		metrics.add(metricId);
		store.set(x, metrics);
}

export function getTooltipMetrics(x: number) {
	return Array.from(store.get(x) || new Set());
}

export function getTooltipMetricY(metricId: string, x: number) {
	return metricData.get(metricId).get(x);
}

import './switch.less'

import React from 'react';
import classes from 'classnames';
import Proptypes from 'prop-types';

import {Switch as SwitchLegacy} from "controls/switch";

let b = require('b_').with('switch');

export class Switch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.value
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange(value) {
		this.setState({
			value: value.value
		}, () => {
			this.props.onChange(this.state.value);
		})
	}

	componentDidMount() {
		const onChange = this.onChange;

		this.switch = new SwitchLegacy({
			renderTo: $(this.ref),
			isEnabled: this.props.isEnabled ? this.props.isEnabled : true,
			change: onChange
		});

		this.switch.setValue(this.state.value);
		if (this.props.isEnabled !== undefined) {
			this.switch.enable(this.props.isEnabled);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.value !== this.props.value) {
			this.switch.setValue(this.props.value);
		}
		if (prevProps.isEnabled !== this.props.isEnabled) {
			this.switch.enable(this.props.isEnabled);
		}
		if (this.props.updateable && this.props.value !== prevProps.value) {
			this.switch.setValue(this.props.value);
			this.setState({
				value: this.props.value
			})
		}
	}

	render() {
		const {containerClass} = this.props;
		const shapeClass = {
			'round': 'cw_switch_mini'
		}
		const position = this.props.position;

		return (<>
			{this.props.label && <label className={b('label')}>{this.props.label}</label>}
			<div className={classes(shapeClass[this.props.shape], containerClass)}>
				<div ref={(el) => this.ref = el} className={classes(position)} title={this.props.title}/>
			</div>
		</>)
	}
}

Switch.propTypes = {
	shape: Proptypes.oneOf(["round", "default"])
}

export default Switch;

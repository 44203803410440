import './slider.less'
import React from 'react';
import PropTypes from "prop-types";
import NumericTextBox from "./numericTextBox";

const b = require('b_').with('slider');

export class Slider extends React.PureComponent {
	constructor (props) {
		super(props);
		this.ref = React.createRef();
	}

	componentDidMount () {
		const {min, max, step, value, largeStep, onChange} = this.props;

		let defaultConfig = this.getDefaultConfig();

		this.slider = $(this.ref.current).kendoSlider({
			min: min ?? defaultConfig.min,
			max: max ?? defaultConfig.max,
			step: step ?? defaultConfig.step,
			value: value ?? defaultConfig.value,
			tickPlacement: this.props.tickPlacement ?? 'none',
			showButtons: this.props.showButtons ?? false,
			smallStep: this.props.smallStep ?? defaultConfig.smallStep,
			largeStep: largeStep ?? defaultConfig.largeStep,
			change: (e) => {
				if (this.props.minValue && e.value < this.props.minValue) {
					setTimeout( () => {
						this.slider.value(this.props.minValue);
						this.slider.trigger('change');
					}, 1);
				}
				onChange(this.slider.value());
			}
		}).data('kendoSlider');
	}

	getDefaultConfig() {
		if (this.props.type === 'percent') {
			return {
				smallStep: 0.01,
				largeStep: 100,
				min: 0,
				max: 100,
				value: 1
			}
		} else if (this.props.type === 'zoom') {
			return {
				smallStep: 0.1,
				largeStep: 1,
				min: 0,
				max: 2,
				value: 1
			}
		}
	}

	componentDidUpdate (prevProps) {
		if (prevProps.value !== this.props.value) {
			this.slider.value(this.props.value);
		}
	}

	render() {
		return (
			<div className={b()}>
				<div className={b('slider-container')}><input ref={this.ref} /></div>

				{this.props.type == 'percent' &&
					<div className={b('text-input-container')}>
						<NumericTextBox value={this.props.value}
					                    onChange={v => this.props.onChange(v)}
						                min={this.props.min}
						                max={this.props.max}
					                    type='percent'
						/>
					</div>
				}
			</div>
		)
	}
}

Slider.propTypes = {
	value: PropTypes.number,
	onChange: PropTypes.func,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	largeStep: PropTypes.number,
	type: PropTypes.oneOf(['zoom', 'percent'])
};

export default Slider;

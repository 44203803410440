import {Api} from "tools/api";
import Settings from "settings";

export class KpiApi {

	static baseUrl(accountId) {
		return Api.accountRoot(accountId) + 'kpi';
	}

	static profilesUrl(accountId) {
		return  KpiApi.baseUrl(accountId) + '/lite?ignoreTags=true';
	}

	static categoriesUrl({profileId}, accountId) {
		return KpiApi.baseUrl(accountId) + `/${profileId}/categories`;
	}

	static ruleTypesUrl({profileId, categoryId}, accountId) {
		return KpiApi.baseUrl(accountId) + `/${profileId}/categories/${categoryId}/types`;
	}

	static sampleRatesUrl({profileId, ruleTypeId}, accountId) {
		return KpiApi.baseUrl(accountId) + `/${profileId}/types/${ruleTypeId}/sampleRates`;
	}

	static kpiAccountsUrl({profileId}, accountId) {
		return KpiApi.baseUrl(accountId) + `/${profileId}/accounts`;
	}
}

import React, {useEffect, useState} from 'react';
import {Api} from 'tools'
import {AssetsApi} from 'areas/assets/api';
import {loadChildEntities} from './loadChildEntities'

export function useMonitorsDataSource(assets) {
	const [data, setData] = useState([]);

	useEffect(() => {
		loadChildEntities(
			assets,
			asset => AssetsApi.getMonitors(asset.id),
			(monitor, asset) => {
				monitor.accountId = asset.accountId;
			},
			setData
		);

	}, [assets?.map(x => x.id).join() || '']);

	return data;
}

import React from 'react';
import PropTypes from 'prop-types';
import {TableContext} from './constants';
import {VARS, INITIAL_STATE, reducer} from './reducer'

function withReducer(WrappedComponent) {
	function WithReducer(props) {
		const {indexKey, ...restProps} = props;

		const [state, dispatch] = React.useReducer(reducer, {...INITIAL_STATE, [VARS.INDEX_KEY]: indexKey});

		const contextValue = React.useMemo(() => {
			return {
				state, dispatch
			}
		}, [state, dispatch]);

		return <TableContext.Provider value={contextValue}>
			<WrappedComponent {...restProps}/>
		</TableContext.Provider>
	}

	WithReducer.propTypes = {
		indexKey: PropTypes.string
	};

	WithReducer.defaultProps = {
		indexKey: 'id'
	};

	return WithReducer;
}

export default withReducer;

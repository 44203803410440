
export default function MultiSelectGridFilter(config) {
	Object.assign(this, config);

	this._initComponent();
};

jQuery.extend(MultiSelectGridFilter.prototype, {
	/**
	 * @config {Object} grid The grid
	 */
	/**
	 * @config {Object} element The UI element passed by kendo to render the multiselect
	 */
	/**
	 * @config {String} field The grid column field string
	 */
	/**
	 * @config {Object} dataSource The multiselect dataSource
	 */
	/**
	 * @config {Object} kendoScope The scope used by grid column ui filter
	 */
	/**
	 * @config {String} dataTextField
	 */
	/**
	 * @config {String} dataValueField
	 */
	/**
	 * @config {String} optionLabel
	 */
	/**
	 * @config {String} itemTemplate
	 */
	/**
	 * @config {String} tagTemplate
	 */
	_initComponent: function () {
		this._setConfig();
		this._render();
	},
	/*
	* Handler function for setting the component config
	* */
	_setConfig: function () {

		this.dataSource = this.dataSource || [];
		this.dataTextField = this.dataTextField || 'text';
		this.dataValueField = this.dataValueField || 'value';

		this.optionLabel = this.optionLabel || lang.grid.FILTER_SELECT_VALUE;

		this.itemTemplate = this.itemTemplate || '#=data.icon# #=data.text#';
		this.tagTemplate = this.tagTemplate || '#=data.icon# #=data.text#';
	},
	/*
	* Handler function for rendering the multiselect
	* @private
	* */
	_render: function () {
		var menu = $(this.element).parent();
		menu.find(".k-filter-help-text").text(lang.SELECT);
		this.element.removeAttr("data-bind");

		this._operatorEl = menu.find("[data-role=dropdownlist]");

		//quickfix for asset summary group; to be further investigated
		if (!$(this.element).parent().parent().parent().is('body')) {
			$(this.element).parent().parent().parent().addClass('cw_filter_panel_custom');
		}

		var filter = this.grid.dataSource.filter() || {filters: []};
		var field = this.field;

		let values = this.getSelectedValues(filter);
		//this.multiSelectSelectedValues = values;

		this.multiSelectControl = this.element.kendoSortedMultiSelect({
			dataSource: this.dataSource,
			value: values,
			dataTextField: this.dataTextField,
			dataValueField: this.dataValueField,
			optionLabel: this.optionLabel,
			itemTemplate: this.itemTemplate,
			tagTemplate: this.tagTemplate,
			// change: $.proxy(function (e) {
			// 	this.multiSelectSelectedValues = e.sender.value();
			// }, this)
		}).data('kendoSortedMultiSelect');

		menu.find('[type=submit]').on('click', $.proxy(this._onFilterClick, this));
		menu.find('[type=reset]').on('click', $.proxy(function () {
			this._clearFilter();
		}, this));
	},
	/*
	* Clears the filter for this column
	* */
	_clearFilter: function () {
		//if user clicked 'clear' button then a 'reset' event will be fired which sets values to initialValues.
		//so if multiselect was created with some initial values they will be shown instead of an empty drop down
		this.multiSelectControl._initialValues = [];

		this.multiSelectControl.value([]);

		var firstLevelFilter = this.grid.dataSource.filter();
		if (!firstLevelFilter) {
			return;
		}

		this.removeFilterEntriesByName(firstLevelFilter);
		this.grid.dataSource.filter(firstLevelFilter);
	},

	getSelectedValues(filter, values = []){
		//the reason we need to go through the whole tree is that kendo grid 'simplifies' filters if possible
		//so if our filter is the only filter value then it will be flatterned and we will get one-level filter on next load
		for(const filterEntry of filter.filters){
			if(filterEntry.field == this.field){
				values.push(filterEntry.value);
			}else if(filterEntry.filters){
				this.getSelectedValues(filterEntry, values)
			}
		}

		return values;
	},

	removeFilterEntriesByName(filter){
		if(!filter || !filter.filters?.length)
			return;

		for(let i = 0; i < filter.filters.length; i++){
			const filterEntry = filter.filters[i];
			let removeEntry = false;

			if(filterEntry.field == this.field){
				removeEntry = true;
			}else if(filterEntry.filters){
				this.removeFilterEntriesByName(filterEntry, this.field);
				if(filterEntry.filters.length == 0){  //in case a filter for the field is found deeper and now collection of filters is empty.
					removeEntry = true;
				}
			}

			if(removeEntry){
				filter.filters.splice(i, 1);
				i--;
			}
		}
	},
	/*
	 * Handler function for filtering click
	 * @param {Object} e
	 * */
	_onFilterClick: function (e) {
		e.preventDefault();
		var menu = $(this.element).parent();
		var op = menu.parent().find("[data-role=dropdownlist]").data('kendoDropDownList').value();
		let values = this.multiSelectControl.value().map(x => x.toJSON != null ? x.toJSON() : x);

		if (!op || !values) {
			return;
		}

		if(!values.length){
			this._clearFilter();
			return;
		}

		var currentFilterKey = -1;
		var filter = this.grid.dataSource.filter();
		var subFilters = [];

		if (!filter) {
			filter = {
				logic: 'and',
				filters: []
			};
		}

		this.removeFilterEntriesByName(filter);

		var forceFilter = this.forceFilter || [];
		if (forceFilter.length) {
			$.each(values, $.proxy(function (i, v) {
				subFilters.push({
					filters: [{
						field: this.field,
						operator: op,
						value: v
					}, {
						filters: [{
							filters: [{
								field: this.field,
								operator: op,
								value: v
							}, {
								field: this.secondField,
								operator: op,
								value: v
							}],
							//logic: 'or'
							logic: op === 'eq' ? 'or' : 'and'
						}, {
							filters: forceFilter,
							//logic: 'or'
							logic: op === 'eq' ? 'or' : 'and'
						}],
						//logic: 'and'
						logic: op === 'eq' ? 'and' : 'or'
					}],
					//logic: 'or'
					logic: op === 'eq' ? 'or' : 'and'
				});
			}, this));
		} else {
			$.each(values, $.proxy(function (i, v) {
				subFilters.push({field: this.field, operator: op, value: v});
			}, this));
		}

		if (this.field === 'tags' && this.static) {
			$.each(values, $.proxy(function (i, v) {
				subFilters.push({
					field: this.field,
					value: v,
					op: op,
					isTag: true,
					operator: function (tags, value) {
						for (let i = 0; i < tags.length; i++) {
							if (op === 'eq') {
								if (tags[i] === value)
									return true;
							}
							if (op === 'neq') {
								let commonTags = tags.filter(x => values.includes(x));
								if (!commonTags.length) {
									return true;
								}
							}
						}
						return false;
					}
				});
			}, this));
		}

		for (let i = 0; i < subFilters.length; i++) {
			if (subFilters[i].value === 'monError') {
				subFilters.splice(i, 1);
				subFilters.push({field: 'isMonitorError', operator: 'eq', value: true});
			}
		}

		filter.filters.push({
			filters: subFilters,
			logic: op === 'eq' ? 'or' : 'and',
		});

		this.grid.dataSource.filter(filter);
		this._operatorEl.data('kendoDropDownList').value(op);

		const th = this.grid.element.find('th[data-field="' + this.field + '"');
		th.data('kendoFilterMenu')?.menuFilter?.popup?.close();
	}
});

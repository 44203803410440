import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './withRequired.less';

const b = require('b_').with('with-required');

const withRequired = (Component) => {
	function WithRequiredWrapper(props) {
		const {required, value, ...otherProps} = props;

		return <div className={b()}>
			<Component value={value} required={required} {...otherProps} />
			{required && !value && <div className={b('wildcard')}>*</div>}
		</div>
	}

	return WithRequiredWrapper;
}

export default withRequired;

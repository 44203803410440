import React, {useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';

import {Section} from 'controls/react/layout/section';
import {Window} from 'controls/react/kendoWrappers/window';
import {Toolbar, ToolbarItemPosition, ToolbarItemsSet} from 'controls/react/layout/toolbar';
import {Button} from 'controls/react/form';
import Lang from 'core/localization/lang'
import './dataSourceWindow.less';
import {GridNative} from 'controls/react/kendoWrappers/gridNative';

function DataSourceWindow(props) {
	const idsRef = useRef();
	const itemsRef = useRef();

	const onSave = useCallback(() => {
		props.onSave(itemsRef.current);
	}, [props.onSave]);

	const onRows = useCallback((ids, items) => {
		idsRef.current = ids;
		itemsRef.current = items;
	});

	return <Window
		title={props.title}
		containerClass={'data-source-window'}
		modal={true}
		width={1000}
		height={500}
		onClose={props.onClose}
		resizable={false} >
		<div className={'data-source-window__content'}>
			<GridNative
				columns={props.columns}
				dataSource={props.dataSource}
				selectedItems={props.values.map(x => x.id)}
				onRowsSelected={onRows}
				height={450}
				resizable={true}
				filterable={{mode: 'row'}} />
		</div>
		<Toolbar>
			<Button title={Lang.SAVE}
				primary={true}
				onClick={onSave}
				position={ToolbarItemPosition.AT_THE_END} />
			<Button title={Lang.CANCEL}
				primary={false}
				onClick={props.onClose}
				position={ToolbarItemPosition.AT_THE_END} />
		</Toolbar>
	</Window>
}

DataSourceWindow.propTypes = {
	dataSource: PropTypes.object,
	values: PropTypes.array.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	columns: PropTypes.array.isRequired
};


export default DataSourceWindow;

class ActionListener {
    constructor() {
        this.callbacks = new Map();
    }

    on(name, callback) {
        this.callbacks.set(name, callback);
    }

    middleware(store) {
        return (next) => {
            return (action) => {
                if (this.callbacks.has(action.type)) {
                    this.callbacks.get(action.type)(action);
                }

                return next(action);
            }
        }
    }

    getMiddleware() {
        return this.middleware.bind(this);
    }
};

export const listener = new ActionListener();

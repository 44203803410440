window.$ = window.jQuery = require('jquery');


$.fn.setClass = function(className, shouldSet){
	if(shouldSet){
		this.addClass(className)
	}else{
		this.removeClass(className)
	}
}

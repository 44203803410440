export default function WebSocketAdapter(config) {
	Object.assign(this, config);
	this._initComponent();

	return this;
};

/*
* readyState
* CONNECTING	0	The connection is not yet open.
* OPEN	        1	The connection is open and ready to communicate.
* CLOSING	    2	The connection is in the process of closing.
* CLOSED	    3	The connection is closed or couldn't be opened.
* */

WebSocketAdapter.prototype = {
	/**
	 * @config {String} url The URL to which to connect; this should be the URL to which the WebSocket server will respond.
	 */
	url: '',
	/**
	 * @config {String} protocol
	 */
	protocol: '',
	/**
	 * @config {Boolean} debug The boolean to set if console.logs should be visible or not
	 */
	debug: true,
	/**
	 * Application initialization
	 */
	_initComponent: function () {
		this.readyStates = ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'];
		this.create();
	},
	/*
	* Handler function for creating the websocket
	* */
	create: function () {
		this.socket = new WebSocket(this.url, this.protocol);
		//console.log(this.socket);
		this._attachListeners();

		return this;
	},
	/*
	* Handler function for
	* */
	_attachListeners: function () {
		this.socket.onopen = $.proxy(this._onOpen, this);
		this.socket.onmessage = $.proxy(this._onMessageReceived, this);

		this.socket.onerror = $.proxy(this._onError, this);
		this.socket.onclose = $.proxy(this._onClose, this);
	},
	/*
	* Handler function for communicating with the socket
	* @param {Object} data The object that is sent
	* */
	send: function (data) {
		if (this.socket.readyState === 1) {
			this.socket.send(JSON.stringify(data));
		} else {
			this.errorHandler('send');
		}
	},
	/*
	* Handler function for when the socket connection has open
	* @param {Object} event The event object
	* */
	_onOpen: function (event) {
		//send session details (like sessionId)
		//console.log('onOpen', event);

		if (this.onOpen) {
			this.onOpen.call(this, event);
		}
	},
	/*
	* Handler function for when the socket receives a close event
	* @param {Object} event The event object
	* */
	_onMessageReceived: function (event) {
		//console.log('onMessageReceived', event);

		if (this.onEvent) {
			this.onEvent.call(this, event);
		}
	},
	/*
	 * Handler function for when the socket receives an error event
	* @param {Object} event The event object
	* */
	_onError: function (event) {
		var code = event.code;
		var reason = event.reason;
		var wasClean = event.wasClean;

		//console.log('onError', event);
	},
	/*
	* Handler function for when the socket receives a close event
	* @param {Object} event The event object
	* */
	_onClose: function (event) {
		var code = event.code;
		var reason = event.reason;
		var wasClean = event.wasClean;

		if (reason === 'Session expired') {
			if (this.onSessionExpired) {
				this.onSessionExpired.call(this, event);
			}
			return;
		}

		if (this.onClose) {
			this.onClose.call(this, event);
		}

		//console.log('onClose', event);
	},
	/*
	* Handler function for closing the socket connection
	* */
	close: function () {
		if (this.socket) {
			/*
			* Todo: check this.socket.bufferedAmount
			* */
			this.socket.close();
		}
	},
	/*
	* Handler function for handling the error in console
	* */
	errorHandler: function (location) {
		//console.log('readyStateString: ', this.readyStates[this.socket.readyState]);
		//console.log('Error on ' + location + '() method, socket object:', this.socket);
	},
	/*
	* Handler function for destroying the socket connection
	* */
	destroy: function () {
		this.close();
	}
};

import Api from 'tools/api';

(function () {
	Sidebar.prototype.addMaterialIconsPalette = function () {
		var style = 'text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;fitText=1;verticalLabelPosition=center;';

		var promise = Api.images.list();

		this.addPalette('material-icons-block', 'Material Icons', false, mxUtils.bind(this, function (content, filter) {
			promise.done($.proxy(function (data) {
				var fns = [];
				var icons = data.icons.material;
				for (var i = 0; i < icons.length; i++) {

					if(!filter(icons[i]))
						continue;

					var iconContainer = '<div class="icon-container"><i class="material-icons">' + icons[i] + '</i></div>';
					fns.push(this.createVertexTemplateEntry(
						style,
						this.thumbWidth - 8,
						this.thumbHeight - 8,
						'<i class="material-icons">' + icons[i] + '</i>',
						icons[i],
						null,
						null,
						'Glyphicons icons ' + icons[i],
						function (iconContainer) {
							return function (parent) {
								parent.appendChild($(iconContainer)[0])
							}
						}(iconContainer)
					));
				}

				for(const fn of fns){
					content.appendChild(fn(content));
				}
			}, this));

		}));
	};

})();

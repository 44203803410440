import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from 'controls/react/kendoWrappers/dropDownList';
import {TextBox} from 'controls/react/form';

function FilterInput(props) {
	const [textBoxValue, setTextBoxValue] = React.useState(props.value);
	
	React.useEffect(() => setTextBoxValue(props.value), [props.value]);

	const debounceOnChange = _.debounce((value) => { props.onChange(value)}, 500);

	const handleTextInput = React.useCallback((value) => {
		setTextBoxValue(value);
		debounceOnChange(value);
	}, [props.onChange, props.value])

	if (props.filterOptions && props.filterOptions.length) {
		return <DropDownList value={props.value} dataSource={props.filterOptions} dataTextField={"text"} dataValueField={"value"} onChange={props.onChange} />
	} else {
		return <TextBox value={textBoxValue} onChange={handleTextInput}/>
	}
}

FilterInput.propTypes = {
	filterOptions: PropTypes.array,
	onChange: PropTypes.func.isRequired
}

export default FilterInput;

import produce from "immer";

export function addImmer(target){
	target.prototype.changeState = function(mutator, callback){
		//state => {mutator(state)} instead of just 'mutator' is there to avoid error
		//when mutator returns something - immer consider that a new state and throws an error
		this.setState(produce(this.state, state => {mutator(state)}), callback);
	}

	target.prototype.changeContext = function(mutator, callback){
		this.context.change(mutator, callback);
	}
}

import './stateWidget.less';

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import {translator} from "core";
import Renderer from 'tools/renderer';
import {healthToSeverity, Severity} from "tools/severity";

const b = require('b_').with('state-widget');

export default class StateWidget extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const statusLineEntries = this.props.statusLineEntries?.length
			? this.props.statusLineEntries
			: [{
				severity: Severity.INVALID,
				value: 1
			}];

		let modificators = {};
		if(this.props.higlightFirstStatusEntry)
		{
			modificators['highlight'] = true;
			modificators['highlight-' + statusLineEntries[0].severity.toLowerCase()] = true;
		}

		let rootClasses = classnames(b(modificators), this.props.containerClass)

		const totalValue = statusLineEntries.reduce((sum, entry) => {
			return sum + entry.value
		}, 0);

		return(
			<div className={rootClasses}>
				<div className={b('text-block')}>
					<div className={b('main-value', {clickable: this.props.onClick != null})}
					     onClick={this.props.onClick}>
						{this.props.value}
					</div>
					<div className={b('sub-value')}>
						{this.props.subValue}
					</div>
				</div>
				<div className={b('status-line')}>
					{totalValue != 0 &&
						statusLineEntries.map((item, i) => {
							return <div key={i} className={b('status-line-entry', {clickable: this.props.onClick != null, [healthToSeverity[item.severity].toLowerCase()]: true})}
							            style={getEntryStyle(item, totalValue)}
							            title={item.title}
							            onClick={() => this.props.onClick && this.props.onClick(item)}>
								{item.label}
							</div>
						})
					}
				</div>

			</div>
		);
	}
}

function getEntryStyle(entry, totalValue){
	return {
		width: (entry.value * 100 / totalValue ) + '%'
	}
}


StateWidget.propTypes = {
	containerClass: PropTypes.string,
	subValue: PropTypes.string,
	statusLineText: PropTypes.string,
	tooltips: PropTypes.object,
	higlightFirstStatusEntry: PropTypes.bool,
	//onClick: PropTypes.function,
	borderColor: PropTypes.string
}

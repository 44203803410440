import React from 'react';
import PropTypes from 'prop-types';
import {TableContext} from './constants';
import {ACTIONS, VARS} from './reducer';

function withReset(WrappedComponent) {
	function WithResetComponent(props) {
		const {onReset, ...restProps} = props;
		const {state, dispatch} = React.useContext(TableContext);
		React.useEffect(() => {
			if(props.onReset)
				props.onReset(reset)			
		}, []);

		React.useEffect(() => {
			if(props.onResetToggle)
				props.onResetToggle(Object.keys(state[VARS.SORTING]).length != 0 || Object.keys(state[VARS.FILTERS]).length != 0)			
		}, [state[VARS.SORTING], state[VARS.FILTERS]]);

		const reset = () => {
			dispatch({ type: ACTIONS.RESET_FILTER_SORTING })
		}

		return <WrappedComponent
			{...restProps} />
	}

	WithResetComponent.propTypes = {
		onReset: PropTypes.func
	}

	return WithResetComponent;
}

export default withReset;

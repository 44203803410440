import './splitterHelper.less';

import { UserSettings } from 'tools';

export async function renderHorizontalKendoSplitter(container, placeholderPane, placeholderLabel, categoryName) {
	return renderHorizontalKendoSplitterExt({
		container, placeholderPane, placeholderLabel, categoryName
	});
}

export async function renderHorizontalKendoSplitterExt({container, placeholderPane, placeholderLabel, categoryName, panes}) {
	if($(container).data("kendoCustomSplitter"))
		return;
		
	const keyPath = 'HorizontalDynamicFilterSplitter-'+categoryName;
	const horizontalSplitterConfig = await UserSettings.get(keyPath, 'horizontalSplitter');
	const collapsed = await UserSettings.get(keyPath, 'collapsed', false);

	if(panes == null){
		panes = [
			{ collapsible: placeholderPane != null, collapsedSize: "20px", max: "40%", size: '25%', collapsed: collapsed },
			{ collapsible: false, min: '60%' }
		]
	}
	if(horizontalSplitterConfig){
		panes[0].size = horizontalSplitterConfig;
	}

	const horizontalSplitter = $(container).kendoCustomSplitter({
		placeholderPane: placeholderPane != null ? $(placeholderPane)[0] : null,
		placeholderLabel: placeholderLabel,
		orientation: "horizontal",
		panes,
		resize: async (e) => {
			const size = parseInt(e.sender.size(".k-pane:first"));
			if (size < 50)
				return;

			let value = Math.floor(100 * size / e.width);

			if (value < 20) {
				value = 20;
			}
			await UserSettings.set(keyPath, {
				horizontalSplitter: value + "%"
			});
		},
		collapse: async (e) => {
			setTimeout(() => { $(container + ' .k-icon.k-expand-prev.k-i-sarrow-w.glyphicons.chevron-right').attr('title', 'Click to maximize') }, 100);
			await UserSettings.set(keyPath, { collapsed: true })
		},
		expand: async (e) => {
			setTimeout(() => { $(container + ' .k-icon.k-collapse-prev.k-i-sarrow-w.glyphicons.chevron-left').attr('title', 'Click to minimize') }, 100);
			await UserSettings.set(keyPath, { collapsed: false })
		}
	}).data('kendoCustomSplitter');
	setSplitterButtonTooltip(container)
}

function setSplitterButtonTooltip(container) {
	const max = $(container + ' .k-icon.k-expand-prev.k-i-sarrow-w.glyphicons.chevron-right');
	if (max.length > 0)
		setTimeout(() => { max.attr('title', 'Click to maximize') }, 100);
	const min = $(container + ' .k-icon.k-collapse-prev.k-i-sarrow-w.glyphicons.chevron-left');
	if (min.length > 0)
		setTimeout(() => { min.attr('title', 'Click to minimize') }, 100);
}

import React from "react";
import { useAccountsLite } from 'api';
import {AntOption, AntSelect} from 'controls/react/ant/antSelect';
import { Cookies } from "core/cookies";
import {getSeverityDataList} from "framework/entities/healthData";
import {SeverityLabel} from "controls/react/severityIndicator";

export function AntAccountDropDown(props) {
	const dataSource = useAccountsLite(Cookies.CeesoftCurrentAccountId);
	return <AntSelect dataList={dataSource}
		nameField={'name'}
		valueField={'id'}
		valueLink={props.valueLink} />
}

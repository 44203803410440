import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {reducer as formReducer} from "redux-form";
import qualifierReducer from "./reduxReducer";
import sagas from "./reduxSagas";
import {fork} from 'redux-saga/effects';
import {listener} from './actionListener';

const middlewares = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (window.REDUX_DEBUG) {
    const { createLogger } = require(`redux-logger`);
    const {Iterable} = require("immutable");

    const logger = createLogger({
        collapsed: true,
        stateTransformer: (state) => {
            let newState = {};

            for (var i of Object.keys(state)) {
                if (Iterable.isIterable(state[i])) {
                    newState[i] = state[i].toJS();
                } else {
                    newState[i] = state[i];
                }
            }

            return newState;
        }
    });

    middlewares.push(logger);
}

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware, listener.getMiddleware());

const reducer = combineReducers({
    form: formReducer,
    qualifier: qualifierReducer
});

export const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(function *() {
    yield fork(sagas);
});



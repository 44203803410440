import React from "react";
import classnames from "classnames";

export default class FieldRow extends React.PureComponent {
	onClick = () => {
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	render() {
		const {label, content, onClick, tooltip} = this.props;

		return <div className="sqinfo-form__row" onClick={this.onClick}>
			<div className="sqinfo-form__label">{label}:</div>
			<span className={classnames("sqinfo-form__content", {cw_link: !!onClick})}>{content}</span>
		</div>
	}
}

import './chartToolbar.less';

import React from 'react';
import PropTypes from 'prop-types';

import {
	FormLayout, Toolbar, ToolbarItemsSet, Section
} from "controls/react/layout";

import {
	IconButton, MultiToggle, DropDownList, FormEntry, DateTimePicker
} from "controls/react/form";
import {timePeriodItems} from "./timePeriodMultiToggle";

import {translator} from 'core';

let b = require('b_').with('chart-toolbar');

const i = translator({

});

class ChartToolbar extends React.PureComponent{
	constructor(props){
		super(props);
		this.state = {
			timePeriod: this.props.timePeriod || 'LASTHOUR'
		}
	}

	render() {
		return (
			<div className={b()}>
				<Toolbar appearance={this.props.appearance}>
					{this.props.chartStyleEnabled &&
						<ToolbarItemsSet noPadding>
							<IconButton iconName="charts"/>
							<IconButton iconName="vector-path-line"/>
							<IconButton iconName="minus"/>
						</ToolbarItemsSet>
					}
					{this.props.chartTypeEnabled &&
						<DropDownList dataSource={chartTypeDataSource}
						              onChange={() => {}}/>
					}
					{this.props.timePeriodEnabled &&
						<MultiToggle items={timePeriodItems}
						             onChange={this.onTimePeriodChanged}
									 value={this.state.timePeriod}/>
					}
					{}
				</Toolbar>
				{this.state.timePeriod == 'CUSTOM' &&
					<Section containerClass={b('custom-period')}>
						<FormLayout type='2-columns'>
							<FormEntry label={i('From')} vertical={true}>
								<DateTimePicker onChange={this.onFromDateChanged}/>
							</FormEntry>
							<FormEntry label={i('To')} vertical={true}>
								<DateTimePicker onChange={this.onToDateChanged}/>
							</FormEntry>
						</FormLayout>
					</Section>
				}
			</div>
		);
	}

	onTimePeriodChanged = (value, isFromWidget, timeToggleId, timeToggleType) => {
		this.isFromWidget = isFromWidget;
		this.timeToggleId = timeToggleId;
		this.timeToggleType = timeToggleType;
		this.setState({
			timePeriod: value,
			fromDate: null,
			toDate: null
		}, this.fireOnTimePeriodChanged);
	}

	onFromDateChanged = value => {
		this.setState({fromDate: value});
		this.fireOnTimePeriodChanged();
	}

	onToDateChanged = value => {
		this.setState({toDate: value});
		this.fireOnTimePeriodChanged();
	}

	fireOnTimePeriodChanged = () => {
		if (!this.props.timePeriodEnabled)
			return;

		if( this.state.timePeriod != 'CUSTOM'){
			this.props.onTimePeriodChanged(this.state.timePeriod, this.isFromWidget, this.timeToggleId, this.timeToggleType);
		}else if( this.state.fromDate != null && this.state.toDate != null ){
			this.props.onTimePeriodChanged(this.state.timePeriod, this.state.fromDate, this.state.toDate);
		}
	}
}

let chartTypeItems = [{
	text: lang.widget.LINE,
	value: 'line'
}, {
	text: lang.widget.AREA,
	value: 'area'
}, {
	text: lang.widget.RANGE,
	value: 'range'
}];

let chartTypeDataSource = new kendo.data.DataSource({data: chartTypeItems});

export default ChartToolbar;

ChartToolbar.propTypes = {
	chartStyleEnabled: PropTypes.bool,
	chartTypeEnabled: PropTypes.bool,
	timePeriodEnabled: PropTypes.bool,
	onTimePeriodChanged: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';
import {ACTIONS} from "areas/services/widgets/filterPanel/reducer";
import Tags from "controls/react/tags";
import {b} from "./filteringPanelShared";
import classnames from 'classnames';

function TagInput(props) {
	const onTagChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_TAGS, value, index: props.index })
	}, []);

	return <>
			<div className={classnames(b('input'), b('reset-field'))}>
				<Tags value={props.tags} onChange={onTagChange} />

			</div>
		</>
}

TagInput.propTypes = {
	index: PropTypes.number.isRequired,
	tags: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default TagInput;

import moment from "moment";

export enum MonthEnum {
	jan = 1,
	feb,
	mar,
	apr,
	may,
	jun,
	jul,
	aug,
	sep,
	oct,
	nov,
	dec
}

export type Month = keyof typeof MonthEnum;
export const months: Array<Month> = moment.monthsShort().map(m => m.toLowerCase()) as Array<Month>;

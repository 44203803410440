import Lang from "core/localization/lang";

export const chartTypeDataSource = [
	{
		text: Lang.AREA_CHART,
		value: 'area'
	}, {
		text: Lang.AREASPLINE_CHART,
		value: 'areaspline'
	}, {
		text: Lang.LINE_CHART,
		value: 'line'
	}, {
		text: Lang.RANGE_CHART,
		value: 'arearange'
	}, {
		text: Lang.SPLINE_CHART,
		value: 'spline'
	}
];

export default chartTypeDataSource;

import './formLayout.less'

import React from 'react';
import classes from 'classnames';
import PropTypes from 'prop-types';


let b = require('b_').with('form-layout');

///
export const FormLayout = props => {

	return (
		<div className={classes(b({[props.type]: true}), props.containerClass)}>
			{props.children}
		</div>
	);
}

FormLayout.propTypes = {
	children: PropTypes.node,
	containerClass: PropTypes.string,
	type: PropTypes.oneOf(['3-columns', '2-columns', '1-column'])
};

FormLayout.defaultProps = {
	type: '1-column'
}

export default FormLayout;

export let Settings = {
	mobile: {
		serverPath: '/rest/mobile/',
		eventPath: window.location.host + '/websocket'
	},
	httpRoot: '/ceeview/',
	managementPath: '/management/',
	serverPath: '/rest/',
	eventPath: window.location.host + '/websocket',
	ssoPath: '/sso/',
	// the language index
	language: 'en',
	//Cookies expiration time (in hours)
	COOKIE_TIMEOUT: 10,
	// logout page
	logoutPage: 'login.jsp',
	defaultLogoutPage: 'login.jsp',
	// for SaaS un-comment next line:
	// logoutPage: 'http://www.ceeview.com',

	// default application loaded
	defaultApp: 'Summary'
};

export default Settings;
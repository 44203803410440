import React, {useEffect, useState} from 'react';
import {Api} from 'tools'
import {ServicesApi} from 'areas/services/api';
import {loadChildEntities} from './loadChildEntities';

export function useServiceElementsDataSource(services) {
	const [data, setData] = useState([]);

	useEffect(() => {
		loadChildEntities(
			services,
			service => ServicesApi.getServiceElements(service.id),
			(element, service) => {
				element.serviceId = service.id;
				element.accountId = service.accountId;
				element.serviceName = service.name;
				element.accountName = service.accountName;
			},
			setData
		);
	}, [services.map(x => x.id).join()]);

	return data;
}

import {i} from 'core/localization/localization';

export const serviceColumns = [
	{
		field: 'id',
		title: lang.SELECTOR,
		template: function (data) {
			return `<input type="checkbox" class="cw_grid_check" />`
		},
		headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
		attributes: {
			'class': 'text_center'
		},
		headerAttributes: {
			'class': 'text_center'
		},
		sortable: false,
		width: 50,
		filterable: false,
	},
	{
		field: 'name',
		title: i('Name'),
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	},
	{
		field: 'accountName',
		title: i('Account'),
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	},
	{
		field: 'description',
		title: i('Description'),
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	}
];

export const serviceElementColumns = [
	{
		field: 'id',
		title: lang.SELECTOR,
		template: function (data) {
			return `<input type="checkbox" class="cw_grid_check" />`
		},
		headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
		attributes: {
			'class': 'text_center'
		},
		headerAttributes: {
			'class': 'text_center'
		},
		sortable: false,
		width: 50,
		filterable: false,
	},
	{
		field: 'name',
		title: i('Name'),
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	},
	{
		field: 'accountName',
		title: i('Account'),
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	},
	{
		field: 'serviceName',
		title: i('Service'),
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	}
];

export function getServiceQualifierColumns(){
	return [
		{
			field: 'id',
			title: lang.SELECTOR,
			template: function (data) {
				return `<input type="checkbox" class="cw_grid_check" />`
			},
			headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
			attributes: {
				'class': 'text_center'
			},
			headerAttributes: {
				'class': 'text_center'
			},
			sortable: false,
			width: 50,
			filterable: false,
		},
		{
			field: 'name',
			title: i('Name'),
			filterable: {
				cell: {
					operator: "contains",
					showOperators: false
				}
			},
		},
		{
			field: 'accountName',
			title: i('Account'),
			filterable: {
				cell: {
					operator: "contains",
					showOperators: false
				}
			},
		},
		{
			field: 'serviceName',
			title: i('Service'),
			filterable: {
				cell: {
					operator: "contains",
					showOperators: false
				}
			},
		},
		{
			field: 'elementName',
			title: i('Service element'),
			filterable: {
				cell: {
					operator: "contains",
					showOperators: false
				}
			},
		}
	];
}

export const ADDABLE_SELECTORS = new Set(['assetId', 'targetId', 'authId']);
export const VALID_DEPENDENCY_TYPES = ['CESelection', 'CEAlgorithm', 'CEAuthentication', 'CETextInput', 'CERange', 'CECheckBox'];
export const LOADABLE_TYPES = new Set(['CESelection', 'CELabel', 'CEAuthentication', 'CEAlgorithm', 'CESelectionMulti', 'CETextInput']);
export const RESETABLE_TYPES = new Set(['CESelection', 'CETextInput']);
export const CHECK_DIRTY_TYPES = new Set(['CETextInput']);
export const VIRTUAL_DEPENDENCIES = new Map(Object.entries({
    'authId': ['authTypes']
}));
export const SESSION_PERSISTED = new Set([
    'accountId',
    'assetId',
    'assetAccountId',
    'agentId'
]);
export const formBuilderForm = 'formBuilder';

import {Api} from "tools";
import Settings from "settings";

export class IncidentApi {
	static getIncidentUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `incidents/${id}/?update=${update}`;
	}

	static getExistingIncidentsUrl(scope, accountId) {
		let url;
		var impact = '';
		if (scope.impact) {
			impact = 'impact=' + scope.impact + '&';
		}
		if (scope.serviceId) {
			url = Settings.serverPath + 'accounts/' + scope.serviceAccountId + '/services/' + scope.serviceId + '/incidents?status=OPEN&' + impact + '_dc=' + Utils.timestamp();
			scope.incidentsFilter = {};
			scope.dontLoadDefault = true;
		} else if (scope.serviceAccountId) {
			url = Settings.serverPath + 'accounts/' + scope.serviceAccountId + '/incidents';
		} else if (scope.assetId) {
			url = Settings.serverPath + 'accounts/' + accountId + '/assets/' + scope.assetId + '/incidents?status=OPEN&' + impact + '_dc=' + Utils.timestamp();
			scope.incidentsFilter = {};
			scope.dontLoadDefault = true;
		} else {
			url = Settings.serverPath + 'accounts/' + accountId + '/incidents/?includeSubaccounts=' + State.includeSubaccounts.toString();
			if(scope.tags?.length) {
				scope.tags.forEach(tag => url+= '&tag=' + encodeURIComponent(tag));
				url+='&showUntagged=' + scope.showUntagged || false;
			}
		}
		return url;
	}
}

import React from 'react';
import {translator} from "core";
import {Tags as TagsLegacy} from "controls"

const i = translator({
  "Tags": {
    "no": "Etiketter"
  }
});

export function Tags(props) {
	const ref = React.useRef();
	const tagsControlRef = React.useRef();

	React.useEffect(() => {
		tagsControlRef.current = new TagsLegacy({
			renderEl: $(ref.current),
			onChange: props.onChange
		})
	}, []);

	React.useEffect(() => {
		if (props.value) {
			tagsControlRef.current.setRawValues(props.value);
		}
	}, [props.value]);

	return <input ref={ref} type="text"/>;
}

export default Tags;

import React from 'react';
import PropTypes from 'prop-types';

function withComponentLoaded(WrappedComponent) {
	function WithComponentLoaded(props) {
		const [components, setComponents] = React.useState(null);

		React.useEffect(() => {
			const importTable = async() => {
				const {Table, Cell, Column, HeaderCell} = await import("rsuite-table");
				setComponents({Table, Cell, Column, HeaderCell});
			}

			importTable();
		}, [])

		if (!components) {
			return null;
		}

		return <WrappedComponent {...components} {...props} />
	}

	WithComponentLoaded.propTypes = {
		dataSource: PropTypes.object
	}

	return WithComponentLoaded;
}

export default withComponentLoaded;

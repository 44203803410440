import {WidgetConfigurationContext} from './context';
import {useStoreCreator} from "core/react/useStoreCreator";
import {usePlainStoreCreator} from "core/react/usePlainStoreCreator";
import {Map} from "immutable";

export const storeRef = {
	current: Map()
};
export const useStore = useStoreCreator(WidgetConfigurationContext, storeRef);
export const usePlainStore = usePlainStoreCreator(WidgetConfigurationContext, storeRef);

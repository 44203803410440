import React from 'react';
import {DropDownList} from 'controls/react/form';
import {CeeViewDataSource, Renderer} from "tools";
import {Settings} from "settings";
import {ErrorHandler} from "core";

const getConfig = () => {
	return {
		dataSource: new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'sessions/timeFormats',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (response) {
					var now = (new Date()).getTime(),
						dates = [], i;
					for (i = 0; i < response.length; i++) {
						var date = {
							format: response[i],
							text: Renderer.browserDateRenderer(now, "time", response[i])
						};
						dates.push(date);
					}
					return dates;
				}
			},
			error: ErrorHandler.kendoServerError
		}),
		dataTextField: 'text',
		dataValueField: 'format'
	};
};

export default class TimeFormatDropDown extends React.Component {
	constructor(props) {
		super(props);
		this.config = getConfig();
	}

	render() {
		return <DropDownList config={this.config} {...this.props}/>
	}
}

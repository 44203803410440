import React from 'react';
import moment from "moment";

const format = "HH:mm";

export class FieldTimePicker extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const onChange = this.props.onChange.bind(this);

		this.timePicker = $(this.el).kendoTimePicker({
			value: this.props.value,
			format,
			change: function(e) {
				onChange(moment(this.value()).format(format));
			}
		}).data("kendoTimePicker");

		this.timePicker.readonly(this.props.readonly ? true: false)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.timePicker.value(this.props.value);
		}

		this.timePicker.readonly(this.props.readonly ? true: false)
	}

	render() {
		return (
			<div className="cw_field">
				<label className="cw_inline">{this.props.title}</label>
				<div className="cw_dropdown_container">
					<input ref={(el) => this.el = el} />
				</div>
			</div>
		)
	}
}

export default FieldTimePicker;

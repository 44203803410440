import Settings from 'settings';
import Cookies from 'core/cookies';
import {State} from "tools";
import {AgentsRouter} from 'areas/management/agents/bundleDescription';

/**
 * @class ceeview.management.ServerDetails
 * This is the Details class for the agents type server which extends the assetDetails
 * @namespace ceeview.management
 * @constructor
 */
export default function ServerDetails (config) {
    Utils.apply(this, config);
    this.module = {        name: 'ServerDetails',
        initialConfig: config
    };

    this.initComponent();
};

jQuery.extend(ServerDetails.prototype, {
    /**
     * @cfg {Object} customFilter (Optional) Filter object for the grid. If set, it is applied before other filters
     */
    /**
     * Main init function
     */
    initComponent: function () {
        this.sessionId = Cookies.sessionId;
        this.getServerAgent();
    },
    /*
     * Handler function for getting the server agent id
     * */
    getServerAgent: function () {
        var url = Settings.serverPath + 'sessions/serverId';
        Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
            if (result.success) {
                this.id = result.data || null;
                if (this.id) {
                    Cookies.serverId = this.id;
                    this.loadAgentDetails();
                } else {
                    this.loadModule('ManagementAgents');
                }
            }
        }, this));
    },
    /*
     * Handler function for loading the agent details
     * */
    loadAgentDetails: function () {
        State.mainApp.navigate(AgentsRouter.details(this.id));
    },
    /**
     * Called when application is unloaded/destroyed
     */
    destroy: function () {

    }
});

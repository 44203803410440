import Utils from 'tools/utils';
import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';


export default function MessageCenterForm(config) {
	Utils.apply(this, config);
	this.initComponent();
};

jQuery.extend(MessageCenterForm.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {	
		this.usersLoaded = false;
		this.teamsLoaded = false;
		this.removeListeners();
		this.attachListeners();
		if (State.mainApp.session.hasRole('NOTIFICATION_SEND') && (State.mainApp.session.hasRole('USER_LIST') || State.mainApp.session.hasRole('TEAM_LIST'))) {
				this.getUsers();
				this.getTeams();
			}
		this.sendToDataSource = [];
		$('#modal').attr('style', 'overflow:auto!important');
		if(this.mode == "view") {
			$('#cw_post_message').addClass('hide');
			$('#cw_close_message_form').addClass('hide');
			this.removeListeners();
			this.makeFormReadOnly();
			this.populateForm();
		}
	},

	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#cw_post_message').off();
		$('#cw_message_type li').off();
		$('#cw_message_type').find('li').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cw_post_message').on('click', $.proxy(this.onMessagePost, this));
		$('#cw_message_type li').on('click', $.proxy(this.onToggleButtons, this));
		$('#cw_message_type').find('li').on('click', $.proxy(this.onMessageTypeSelection, this));
		$('#cw_close_message_form').on('click', $.proxy(this.closeComposeForm, this));
	},
	/**
	 * Makes all fields read only for "view" mode
	 */
	makeFormReadOnly: function() {
		$('#cw_message_body').prop('readonly', true);
		$('#cw_message_important').click(() => {return false;})
	},
	/**
	 * Populate fields for "view" mode
	 */
	populateForm: function() {
		$('#cw_message_body').val(this.dataItem.message);
		$('#cw_message_important').prop('checked', this.dataItem.important);
	},
	/**
	 * Sends the message to the server
	 */
	onMessagePost: function () {
		var url = Settings.serverPath + 'notifications/messages/sendMessage';
		var type = $('#cw_message_type').find('input:checked').attr('value');
		var data = {
			message: $('#cw_message_body').val(),
			type: type,
			users: [],
			teams: []
		};
		switch (type) {
			case 'message':
				data.important = $('#cw_message_important').is(':checked');
				break;
			case 'email':
				data.subject = $('#cw_message_subject').val();
				break;
			case 'tweet':
			case 'sms':
				break;
			case 'mobile':
				data.subject = $('#cw_message_title').val();
				break;
		}
		//separate users and teams
		var receivers = $('#cw_messages_send_to').data('kendoSortedMultiSelect').value();
		for (var i = 0, length = receivers.length; i < length; i++) {
			var receiver = receivers[i];
			for (var j = 0, length1 = this.sendToDataSource.length; j < length1; j++) {
				if (this.sendToDataSource[j].id === receiver) {
					if (this.sendToDataSource[j].type === 'USER') {
						data.users.push(receiver);
					}
					else {
						data.teams.push(receiver);
					}
				}
			}
		}

		if (receivers.length) {
			this.clearComposeForm();
			var container = $('.cw_messages_list').parent().parent();
			kendo.ui.progress(container, true);
			Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
				kendo.ui.progress(container, false);
				if (result.success) {
					var status = $('#cw_message_status');
					status.find('p').addClass('success');
					status.find('p').text(lang.mscenter.messages.MESSAGE_SENT);
					status.slideDown().delay(3000).slideUp();
					this.closeComposeForm();
				}
				else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}
		else {
			$('#cw_messages_send_to').parent().children('div').first().addClass('invalid');
			var status = $('#cw_message_form_status');
			status.find('p').text(lang.mscenter.messages.SEND_TO_EMPTY);
			status.find('p').addClass('error');
			status.slideDown().delay(3000).slideUp();
		}
	},
	/**
	 * Handler function for the clicke event on radio toggle buttons
	 * @param {Object} e The click event
	 */
	onToggleButtons: function (e) {
		var li = $(e.currentTarget);
		if (!li.find('input').is(':checked')) {
			var inputBtn = li.find('input');
			var checked = inputBtn.is(':checked');
			li.parent().find('.is_selected').removeClass('is_selected');
			if (checked === 'checked') {
				inputBtn.prop('checked', false);
				li.removeClass('is_selected');
			}
			else {
				inputBtn.prop('checked', true);
				li.addClass('is_selected');
			}
		}
	},
	/**
	 * Event handler for the message type selection event
	 * @param {Object} e The click event object
	 */
	onMessageTypeSelection: function (e) {
		var type = $(e.currentTarget).find('input').attr('value');
		switch (type) {
			case 'message':
				$('#cw_message_subject_section').addClass('hide');
				$('#cw_message_title_section').addClass('hide');
				$('#cw_message_important_section').removeClass('hide');
				break;
			case 'email':
				$('#cw_message_subject_section').removeClass('hide');
				$('#cw_message_title_section').addClass('hide');
				$('#cw_message_important_section').addClass('hide');
				break;
			case 'tweet':
			case 'sms':
				$('#cw_message_subject_section').addClass('hide');
				$('#cw_message_title_section').addClass('hide');
				$('#cw_message_important_section').addClass('hide');
				break;
			case 'mobile':
				$('#cw_message_subject_section').addClass('hide');
				$('#cw_message_title_section').removeClass('hide');
				$('#cw_message_important_section').addClass('hide');
				break;
		}
		var texareaHeight = $("textarea").height();
		texareaHeight += $("#modal").height() - $("#cw_compose_form").height() - 25;
		$('textarea#cw_message_body').css('height', texareaHeight);
	},
	/**
	 * Gets users for "SendTO" field
	 */
	getUsers: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/lite';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			this.usersLoaded = true;
			for (var i = 0, length = result.length; i < length; i++) {
				this.sendToDataSource.push({
					id: result[i].id,
					name: result[i].firstName + ' ' + result[i].lastName,
					type: 'USER'
				});
			}
			this.initSendToField();
		}, this));
	},
	/**
	 * Gets teams for "SendTO" field
	 */
	getTeams: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/lite';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			this.teamsLoaded = true;
			for (var i = 0, length = result.length; i < length; i++) {
				this.sendToDataSource.push({
					id: result[i].id,
					name: lang.TEAM + ': ' + result[i].name,
					type: 'TEAM'
				});
			}
			this.initSendToField();
		}, this));
	},
	/**
	 * Creates SendTo multiselect control
	 */
	initSendToField: function () {
		//sync requests
		if (this.usersLoaded && this.teamsLoaded) {
			$('#cw_messages_send_to').kendoSortedMultiSelect({
				dataSource: this.sendToDataSource,
				dataTextField: 'name',
				dataValueField: 'id',
				change: function (e) {
					$(e.sender.element).parent().children('div').first().removeClass('invalid');
				},
				dataBound: () => { 
					if(this.mode != "view")
						return;              
				 
				 let multiSelect = $('#cw_messages_send_to').data('kendoSortedMultiSelect');
				 multiSelect.value(this.dataItem.fromUserId);
				 multiSelect.readonly(true);
				 $('#cw_messages_send_to').parent().find('.k-input').addClass('hide');
				}
			});
		}
	},
	/**
	 * Resets the values into compose form
	 */
	clearComposeForm: function () {
		$('#cw_message_body').val('');
		$('#cw_message_subject').val('');
		$('#cw_message_subject_line').addClass('hide');
		$('#cw_msg_important_line').removeClass('hide');
		//$('#cw_messages_type').data('kendoDropDownList').value('message');
		$('#cw_messages_send_to').data('kendoSortedMultiSelect').value([]);
	},
	/**
	 * Closes the modal window
	 */
	closeComposeForm: function () {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}
});

import './numericTextBox.less'

import React from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';

const b = require('b_').with('numeric-textbox');

export class NumericTextBox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.wrapperRef = props.wrapperRef || React.createRef();
	}

	render() {
		let {wrapperClass, invalid, style, id, type, containerClass} = this.props;
		return (
			<div ref={this.wrapperRef} className={classnames(b("wrapper"), wrapperClass, {"invalid-field": invalid})} style={style}>
				<input
					id={id}
					className={classnames('c-textbox', b({percent: type == 'percent'}), containerClass)}
					ref={this.ref} />
			</div>
		)
	}

	componentDidMount() {
		let defaultConfig = this.getDefaultConfig();

		this.numeric = $(this.ref.current).kendoNumericTextBox({
			value: this.props.value,
			spinners: this.props.spinners || defaultConfig.spinners,
			decimals: this.props.decimals || defaultConfig.decimals,
			format: this.props.format || defaultConfig.format,
			min: this.props.min || defaultConfig.min,
			max: this.props.max || defaultConfig.max,
			step: this.props.step,
			change: (e) => {
				this.props.onChange(this.numeric.value(), e);
			},
			spin: (e) => {
				if(this.props.onSpin) {
					this.props.onSpin(this.numeric.value(), e);
				}else{
					this.props.onChange(this.numeric.value(), e);
				}
			}
		}).data("kendoNumericTextBox");

		this.numeric.enable(this.props.enabled);
		if (this.props.fullWidth) {
			this.numeric.wrapper.css('width', '100%');
		}
		if (this.props.width) {
			this.numeric.wrapper.css('width', this.props.width);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.numeric.value(this.props.value);
		}
		this.numeric.enable(this.props.enabled);
	}

	getDefaultConfig() {
		if (this.props.type == 'percent') {
			return {
				decimals: 2,
				spinners: false,
				format:  '#.##',
				min: 0,
				max: 100
			}
		}else{
			return {
				decimals: 0,
				spinners: false,
				format: '#.##'
			}
		};
	}
}

NumericTextBox.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	decimals: PropTypes.number,
	spinners: PropTypes.bool,
	wrapperClass: PropTypes.string,
	format: PropTypes.string,
	type: PropTypes.oneOf(['percent', 'generic'])
}

NumericTextBox.defaultProps = {
	type: 'generic'
}

export default NumericTextBox;

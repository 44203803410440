import React from 'react';
import injectSheet from "react-jss";
import {Utils} from "tools";
import {IconButton} from "controls/react";

const styles = {
	container: {
		marginLeft: "12px",
		'.k-widget .geSidebarContainer & *': {
			boxSizing: "border-box"
		}
	},
	textbox: {
		width: "100%",
		minHeight: "100px",
		border: "1px solid #ccc",
		borderRadius: "0 0 5px 5px",
		boxShadow: "inset 1px 1px #eee",
		whiteSpace: "normal",
		paddingLeft: "5px",
		paddingRight: "5px",
	},
	operator: {
		fontSize: "18px",
		color: "#81BBD0",
		fontWeight: "bold"
	},
	qualifier: {
		lineHeight: "18px"
	},
	header: {
		height: "20px",
		border: "1px solid #ccc",
		borderRadius: "5px 5px 0 0",
		display: "flex",
		flexDirection: "row",
		alignItems: "stretch"
	},
	title: {
		flexGrow: 3
	},
	actions: {
		flexGrow: 1
	},
	icon: {
		float: "right",
		marginRight: "10px",
		marginTop: "-2px"
	}
};

class RuleTextbox extends React.Component {
	renderOperator(element) {
		const {classes} = this.props;

		return <span className={classes.operator}> {element} </span>;
	}

	renderQualifier(element, qualifiers) {
		let name;
		const {classes} = this.props;

		try {
			const regex = /sq\['(.*?)'\]/;
			const match = element.match(regex);
			const id = match[1];

			const qualifier = qualifiers.find((q) => q.id === id);

			name = qualifier.name;

		} catch(e) {
			name = '???';
		}

		return <span className={classes.qualifier}>{name}</span>;
	}

	renderElement(item, elements) {
		let name;
		const {classes} = this.props;

		try {
			const regex = /se\['(.*?)'\]/;
			const match = item.match(regex);
			const id = match[1];

			const element = elements.find((q) => q.id === id);

			name = element.name;

		} catch(e) {
			name = '???';
		}

		return <span className={classes.qualifier}>{name}</span>;
	}

	renderItem(item, qualifiers, elements) {
		if(['or', 'and', 'not'].includes(item)) {
			return this.renderOperator(item);
		}
		else if(item.startsWith('sq[')) {
			return this.renderQualifier(item, qualifiers);
		}
		else if(item.startsWith('se[')) {
			return this.renderElement(item, elements);
		}
		else if(item === '(') {
			return '( ';
		}
		else if(item === ')') {
			return ' )';
		}
		else {
			return item;
		}
	};

	renderExpression(value, qualifiers, elements) {
		if (!value) {
			return [];
		}

		const regex = /(\(|\)|sq\[[^\]]+\]|se\[[^\]]+\]|or|and|not)/g;

		const items = value.match(regex) || [];

		return items.map((item) => <span key={Utils.guid()}>{this.renderItem(item, qualifiers, elements)}</span>);
	};

	render() {
		const {classes, value, qualifiers, elements, onEditClick} = this.props;
		const output = this.renderExpression(value, qualifiers, elements);

		return <div className={classes.container}>
			<div className={classes.header}>
				<div className={classes.title}></div>
				<div className={classes.actions}>
					<IconButton iconName="pencil"
					            embedded
					            containerClass={classes.icon}
					            onClick={onEditClick}/>
				</div>
			</div>
			<div className={classes.textbox}>{output}</div>
		</div>;
	}
}

export default injectSheet(styles)(RuleTextbox)

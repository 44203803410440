import React from 'react';
import PropTypes from 'prop-types';
import {TableContext} from './constants';
import {ACTIONS, VARS} from './reducer';

function withManageableHeader(RHeaderCell) {
	function WithManageableHeader(props) {
		const {state, dispatch} = React.useContext(TableContext);
		const {dataKey, ...restProps} = props;
		const sortingIndex = Object.keys(state[VARS.SORTING]).indexOf(props.dataKey)
		const direction = state[VARS.SORTING][props.dataKey];
		const filter = state[VARS.FILTERS][props.dataKey];
		const filterOptions = React.useMemo(() => {
			if (state[VARS.FILTER_OPTIONS][props.dataKey]) {
				return [
					{text: "", value: ""},
					...state[VARS.FILTER_OPTIONS][props.dataKey]
				]
			}
			else {
				return;
			}
		}, [state[VARS.FILTER_OPTIONS][props.dataKey]]);


		const handleToggleSort = React.useCallback(() => {
			dispatch({type: ACTIONS.TOGGLE_SORTING, key: dataKey})
		}, [dataKey]);

		const handleFilter = React.useCallback((filter) => {
			dispatch({type: ACTIONS.SET_FILTER, key: dataKey, filter })
		}, [dataKey]);
		return <RHeaderCell
			dataKey={dataKey}
			direction={direction}
			sortingIndex={sortingIndex}
			filter={filter}
			filterOptions={filterOptions}
			onToggleSort={handleToggleSort}
			onFilter={handleFilter}
			{...restProps} />
	}

	WithManageableHeader.propTypes = {
		dataKey: PropTypes.string.isRequired
	}

	return WithManageableHeader;
}

export default withManageableHeader;

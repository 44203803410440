import React, {useEffect, useRef} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';


export class MultiSelectV2 extends React.Component{
	kendoRef = null;
	containerRef = null;

	state = {
		id: 1
	}

	render(){
		return <div key={this.state.id} className={this.props.containerClass}>
			<select multiple="multiple" ref={this.containerRefCallback}/>
		</div>;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.dataSource != prevProps.dataSource){
			//kendo component when destroyed does not clean up layout so we need to froce react to re-render it
			//so we changing state -> this will force react to rerender <select tag/> and call containerRefCallback with null first
			//which will destroy kendo component and then with a new node -> a new compmonent wil be created
			this.setState({
				id: this.state.id + 1
			});
		}

		if(prevProps.value != this.props.value){
			this.kendoRef?.value(this.props.value);
		}
	}
	containerRefCallback = (node) => {
		this.containerRef = node;

		if(node == null){
			this.kendoRef?.destroy();
		}else{
			if(this.props.dataSource == null)
				return;

			this.initKendoDropdown();
		}
	}

	initKendoDropdown(){
		const config = {
			dataSource: this.props.dataSource,
			dataTextField: this.props.dataTextField,
			dataValueField: this.props.dataValueField,
			itemTemplate: this.props.itemTemplate,
			enable: !this.props.disabled,
			value: this.props.value,
			disableSorting: true,
			change: () => {
				const onChange = this.props.onChange
				if (this.props.fullItems) {
					onChange(this.kendoRef.dataItems().map((i) => i.toJSON()));
				} else {
					onChange(this.kendoRef.value());
				}
			}
		}

		this.kendoRef = $(this.containerRef)
			.kendoSortedMultiSelect(config)
			.data('kendoSortedMultiSelect');
	}

	componentWillUnmount() {
		this.kendoRef?.destroy();
	}
}

MultiSelectV2.propTypes = {
	dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	value: PropTypes.array.isRequired,
	fullItems: PropTypes.bool,
	containerClass: PropTypes.string,
	dataTextField: PropTypes.string,
	dataValueField: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

MultiSelectV2.defaultProps = {
	fullItems: false,
	dataTextField: "name",
	dataValueField: "id"
};

export default MultiSelectV2;

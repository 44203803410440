export default function handleScrollToSelected(grid, props) {
	return () => {
		if (props.onScrollToSelected) {
			const offset = grid.wrapper.find('.cw_grid_check:checked').closest("tr").offset();
			const tableOffset = grid.wrapper.offset();

			if (offset && tableOffset) {
				grid.trigger('custom:onScrollToSelected', offset.top - tableOffset.top);
			}
		}
	}
}

import {Utils} from "tools";
import {LocalEventsManager} from "core";
import ExpandableTextarea from 'controls/expandableTextarea';

export default function FilterForm(config) {
	Utils.apply(this, config);
	this.initComponent();
}

FilterForm.prototype = {
	/**
	 * @cfg {String} mode
	 */
	mode: 'new',
	/**
	 * @cfg {Number} eventCode
	 */
	eventCode: undefined,
	/**
	 * @cfg {String} sourceName
	 */
	sourceName: '',
	/**
	 * @cfg {String} message
	 */
	message: '',
	/**
	 *@cfg {Grid} grid Mandatory.
	 */
	grid: null,
	/**
	 * @cfg {Record} record Only in update mode
	 */
	record: null,
	/**
	 * 
	 * @cfg {String} type The value can be 'include'/'exclude'. Default set on 'include'
	 */
	type: 'include',
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.eventCodeField = $('#cw_ah_filter_event_code');
		this.removeListeners();
		this.attachListeners();
		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_filter_form_description')
		});
		if (this.mode === 'update') {
			$('#cw_ah_filter_save').text(lang.UPDATE);
			$('#cw_ah_filter_sourcename').val(this.sourceName);
			$('#cw_ah_filter_message').val(this.message);
			this.descriptionHandler.value(this.description);
			this.eventCodeField.val(this.eventCode);
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_ah_filter_save').off();
		$('#cw_ah_filter_cancel').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_ah_filter_cancel').on('click', $.proxy(this.onCancel, this));
		$('#cw_ah_filter_save').on('click', $.proxy(this.onSave, this));
	},
	/**
	 * Handler function for the cancel button 
	 */
	onCancel: function () {
		$('#modal').data('kendoWindow').close();
	},
	/**
	 * Handler function for the save button
	 */
	onSave: function () {
		var data = {
			name: this.name || Utils.guid(),
			eventCode: this.eventCodeField.val().trim(),
			sourceName: $('#cw_ah_filter_sourcename').val().trim(),
			message: $('#cw_ah_filter_message').val().trim(),
			description: this.descriptionHandler.value().trim()
		};
		if (data.eventCode === null && !data.category && !data.message) {
			var status = $('.cw_event_filter_form').next().next();
			status.find('p').text(lang.assethealth.messages.PLEASE_COMPLETE_ONE_FIELD);
			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		} else {
			if (this.mode === 'new') {
				this.grid.dataSource.add(data);
			} else {
				Utils.apply(this.record, data);
				this.grid.refresh();
			}
			LocalEventsManager.trigger('eventlogfilterupdate', {type: this.type});
			this.onCancel();
		}
	}
};

import produce from "immer"
import {FILTERING_TYPES, OPERATORS} from "areas/services/widgets/filterPanel/constants";
import {GRID_FILTERING_TYPES} from "./constants";

export const ACTIONS = {
	TYPE_CHANGE: 'TYPE_CHANGE',

	SET_CONFIG: 'SET_CONFIG',

	SET_OPERATOR: 'SET_OPERATOR',
	SET_STATES: 'SET_STATES',

	SET_DURATION_VALUE: 'SET_DURATION_VALUE',
	SET_DURATION_UNIT: 'SET_DURATION_UNIT',

	SET_INCIDENT_NUMBER: 'SET_INCIDENT_NUMBER',

	SET_TAGS: 'SET_TAGS',

	SET_MONITORS: 'SET_MONITORS',
	SET_ASSETS: 'SET_ASSETS',
	SET_ASSET_GROUPS: 'SET_ASSET_GROUPS',

	REMOVE_ITEM: 'REMOVE_ITEM',
	ADD_ITEM: 'ADD_ITEM'
};

function generateDefaultItem(type) {
	switch(type) {
		case FILTERING_TYPES.SERVICE_STATE:
			return {
				type: FILTERING_TYPES.SERVICE_STATE,
				states: [],
				operator: OPERATORS.EQUAL
			};
		case FILTERING_TYPES.STATE_DURATION:
			return {
				type: FILTERING_TYPES.STATE_DURATION,
				duration: 20,
				unit: 'SECONDS',
				operator: '<'
			};
		case FILTERING_TYPES.INCIDENT_NUMBER:
			return {
				type: FILTERING_TYPES.INCIDENT_NUMBER,
				incidents: 20,
				operator: '>'
			};
		case FILTERING_TYPES.ASSETS:
			return {
				type: FILTERING_TYPES.ASSETS,
				assets: []
			};
		case FILTERING_TYPES.ASSET_GROUPS:
			return {
				type: FILTERING_TYPES.ASSET_GROUPS,
				assetGroups: []
			};
		case FILTERING_TYPES.MONITORS:
			return {
				type: FILTERING_TYPES.MONITORS,
				monitors: []
			};
		case FILTERING_TYPES.TAGS:
			return {
				type: FILTERING_TYPES.TAGS,
				tags: []
			};
	}
}

function setConfig(state, action) {
	state.items = [];
	const config = action.value;
	const filterType = action.filterType;
	if (config.serviceStateList && config.operator) {
		state.items.push({
			type: FILTERING_TYPES.SERVICE_STATE,
			states: config.serviceStateList,
			operator: config.operator
		})
	}

	if (config.duration && config.unit && config.durationOperator) {
		state.items.push({
			type: FILTERING_TYPES.STATE_DURATION,
			duration: config.duration,
			unit: config.unit,
			operator: config.durationOperator
		})
	}

	if (config.incidents && config.incidentsOperator) {
		state.items.push({
			type: FILTERING_TYPES.INCIDENT_NUMBER,
			incidents: config.incidents,
			operator: config.incidentsOperator
		})
	}

	if (config.assets) {
		state.items.push({
			type: FILTERING_TYPES.ASSETS,
			assets: config.assets
		})
	}
	if (config.assetGroups) {
		state.items.push({
			type: FILTERING_TYPES.ASSET_GROUPS,
			assetGroups: config.assetGroups
		})
	}

	if (config.monitors) {
		state.items.push({
			type: FILTERING_TYPES.MONITORS,
			monitors: config.monitors
		})
	}

	if (config.tags && config.tags.length && filterType === "selectiveServicesFilterTypes") {
		const tags = config.tags.map((tag) => {
			if (tag.name) {
				return tag.name;
			}
			else {
				return tag;
			}
		});

		state.items.push({
			type: FILTERING_TYPES.TAGS,
			tags: tags
		})
	}

	if (filterType === "selectiveServicesFilterTypes") {
		if (state.items.length === 0) {
			state.items.push(generateDefaultItem(FILTERING_TYPES.SERVICE_STATE));
		}
	} else {
		if (state.items.length === 0) {
			state.items.push(generateDefaultItem(FILTERING_TYPES.ASSETS));
		}
	}

}

export function stateToObject(state) {
	const out = {};

	if (!state?.items) {
		return out;
	}

	for (const item of state.items) {
		switch(item.type) {
			case FILTERING_TYPES.TAGS:
				out.tags = item.tags;
				break;
			case FILTERING_TYPES.INCIDENT_NUMBER:
				out.incidents = item.incidents;
				out.incidentsOperator = item.operator;
				break;
			case FILTERING_TYPES.STATE_DURATION:
				out.duration = item.duration;
				out.unit = item.unit;
				out.durationOperator = item.operator;
				break;
			case FILTERING_TYPES.SERVICE_STATE:
				out.serviceStateList = item.states;
				out.operator = item.operator;
				break;
			case FILTERING_TYPES.MONITORS:
				out.monitors = item.monitors;
				break;
			case FILTERING_TYPES.ASSETS:
				out.assets = item.assets;
				break;
			case FILTERING_TYPES.ASSET_GROUPS:
				out.assetGroups = item.assetGroups;
				break;
		}
	}

	return out;
}

function removeItem(state, {index}) {
	state.items.splice(index, 1);
}

function addItem(state, {filterType}) {
	const type = filterType !== 'gridFilterTypes' ? FILTERING_TYPES : GRID_FILTERING_TYPES;
	const possibleStates = Array.from(Object.values(type));

	for (const item of state.items) {
		const index = possibleStates.indexOf(item.type);

		if (index >= 0) {
			possibleStates.splice(index, 1);
		}
	}

	if (possibleStates.length === 0) {
		return;
	}

	const nextType = possibleStates[0];

	state.items.push(generateDefaultItem(nextType));
}

function setOperator(state, {index, value}) {
	state.items[index].operator = value;
}

function setDurationValue(state, {index, value}) {
	state.items[index].duration = value;
}

function setDurationUnit(state, {index, value}) {
	state.items[index].unit = value;
}

function setIncidentNumber(state, {index, value}) {
	state.items[index].incidents = value;
}

function setTags(state, {index, value}) {
	state.items[index].tags = value;
}

function setMonitors(state, {index, value}) {
	state.items[index].monitors = value;
}

function setAssets(state, {index, value}) {
	state.items[index].assets = value;
}

function setAssetGroups(state, {index, value}) {
	state.items[index].assetGroups = value;
}

function setStates(state, {index, value}) {
	state.items[index].states = value;
}

function changeType(state, {index, value}) {
	state.items[index] = generateDefaultItem(value);
}

function reducer(state, action) {
	switch(action.type) {
		case ACTIONS.SET_STATES:
			setStates(state, action);
			return;
		case ACTIONS.SET_CONFIG:
			setConfig(state, action);
			return;
		case ACTIONS.REMOVE_ITEM:
			removeItem(state, action);
			return;
		case ACTIONS.ADD_ITEM:
			addItem(state, action);
			return;
		case ACTIONS.SET_OPERATOR:
			setOperator(state, action);
			return;
		case ACTIONS.SET_DURATION_VALUE:
			setDurationValue(state, action);
			return;
		case ACTIONS.SET_DURATION_UNIT:
			setDurationUnit(state, action);
			return;
		case ACTIONS.SET_INCIDENT_NUMBER:
			setIncidentNumber(state, action);
			return;
		case ACTIONS.SET_TAGS:
			setTags(state, action);
			return;
		case ACTIONS.SET_MONITORS:
			setMonitors(state, action);
			return;
		case ACTIONS.SET_ASSETS:
			setAssets(state, action);
			return;
		case ACTIONS.SET_ASSET_GROUPS:
			setAssetGroups(state, action);
			return;
		case ACTIONS.TYPE_CHANGE:
			changeType(state, action);
			return;
	}
}

export default produce(reducer);

export async function loadChildEntities(parentEntities, getLoadPromiseCallback, copyCallback, setData){
	if(parentEntities?.length) {
		const promises = parentEntities.map(entry => {
			return getLoadPromiseCallback(entry);
		});

		const loadedPromises = await Promise.all(promises);

		const result = [];

		for (const [index, parentEntity] of parentEntities.entries()) {
			if (!Array.isArray(loadedPromises[index])) {
				continue;
			}

			for (const childEntity of loadedPromises[index]) {
				copyCallback(childEntity, parentEntity)
				result.push(childEntity);
			}
		}

		setData(new kendo.ceeview.DataSource({
			data: result
		}));
	}
}

import React from 'react';
import {EventsToolbar as EventsToolbarLegacy} from "controls";
import UserPrefs from 'tools/userPrefs';
import Utils from 'tools/utils';

let b = require('b_').with('filter-panel');

export default class EventsControl extends React.PureComponent {
	constructor(props) {
		super(props);
		this.refEl = React.createRef();
		this.refEl = React.createRef();
		this.eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
	}

	componentDidMount() {
		this.eventsControl = new EventsToolbarLegacy({
			renderTo: $(this.refEl),
			callBackFn: this.props.onEventsTimeout,
			subscriberId: this.props.subscriberId,
			startFrom: this.eventStatus.startFrom,
			playing: this.eventStatus.playing
		})
	}

	componentDidUpdate(){

	}

	getKendoGrid(grid){
		if(grid == null )
			return null;

		if( grid.kendoGrid != null )
			return grid.kendoGrid;

		return grid;
	}

	render() {
		return (
			<div className={b()} ref={(el) => { this.refEl = el }}/>
		)
	}
}

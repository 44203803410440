export function getPath(id) {
	const parts = id.split(".");

	const path = [];

	for (let i = 0; i < parts.length; i++) {
		if (i > 0) {
			path.push("children", parts.slice(0, i + 1).join("."));
		}
		else {
			path.push(parts[0])
		}
	}

	return path;
}


export function stripTree(searchText, state) {
	if (!searchText) {
		return state;
	}

	searchText = searchText.toUpperCase();

	for(const key of state.keys()) {
		let item = state.get(key);

		if (item.has("children")) {
			const newChildren = stripTree(searchText, item.get("children"));
			if (newChildren.size === 0) {
				item = item.delete("children");
			}
			else {
				item = item.set("children", newChildren);
			}
		}

		if (item.has("children")) {
			item = item.set("expanded", true);
			state = state.set(key, item);
		}
		else {
			const node = item.get('node');
			const id = item.get('id');
			const path = item.get('path');

			if ((node && node.toUpperCase().includes(searchText)) ||
				(id && id.toUpperCase().includes(searchText)) ||
				(path && path.toUpperCase().includes(searchText))) {
				state = state.set(key, item);
			}
			else {
				state = state.delete(key);
			}
		}
	}

	return state;
}

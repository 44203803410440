import React from "react";
import PropTypes from "prop-types";

import {getValueOnPath, setValueOnPath} from "tools/utils";

export default class ContextField extends React.PureComponent{
	originalOnChange = null;
	render(){
		let child = React.Children.only(this.props.children);
		this.originalOnChange = child.props.onChange;
		child = React.cloneElement(child, {
			value: getValueOnPath(this.props.context, this.props.path),
			onChange: this.onChange
		});

		return child;
	}

	onChange = (value, e) => {
		if( this.props.context && this.props.context.change){
			this.props.context.change( context => {
				setValueOnPath(context, this.props.path, value);
				this.props.onChange && this.props.onChange(context, value, e);
				if( this.originalOnChange ){
					this.originalOnChange(value, e);
				}
			});

		}
	}
}

ContextField.propTypes = {
	children: PropTypes.element.isRequired,
	path: PropTypes.string,
	context: PropTypes.object
}

import Utils from 'tools/utils'

export function Switch(config) {
	Utils.apply(this, config);
	this.initComponent();

	return this;
};

export default Switch;

Switch.prototype = {
	/**
	 * @config {Object} renderTo The jQuery selector where the component will be rendered
	 */
	renderTo: '',
	/**
	 * @config {Object} values Object values for ON/OFF
	 * @property: on
	 * @value: true
	 * @property: off
	 * @value: false
	 */
	values: {
		on: true,
		off: false
	},
	/**
	 * @config {Boolean} isEnabled Parameter for setting the switch state
	 */
	isEnabled: true,
	/**
	 * @config {String} cssClass The CSS class
	 * element
	 */
	cssClass: '',
	/**
	 * @config {String} style The style
	 * element
	 */
	style: '',
	/**
	 * Main init class
	 */
	initComponent: function () {
		var HTML = '';
		this.id = Utils.guid();

		HTML += '<span id="' + this.id + '" class="cw_checkbox cw_switch ' + this.cssClass + '" ' + (this.style ? 'style="' + this.style + '"' : '') + '>';
		HTML += '<span class="cw_checkbox_check"></span>';
		HTML += '<span class="cw_checkbox_button"></span>';
		HTML += '</span>';

		this.renderTo.empty().append(HTML);

		this.switchHandler = $('#' + this.id);

		this.enable(this.isEnabled);

		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Handler function for onChange event
	 * e {Event} event handler
	 */
	onChange: function (e) {
		if (typeof this.change === 'function') {
			this.change.call(this, {
				value: this.value
			});
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.switchHandler.off('click');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.switchHandler.on('click', $.proxy(this.onCheckboxClick, this));
		this.switchHandler.data('Switch', this);
	},
	/*
	 * Handler function for clicking checkbox
	 */
	onCheckboxClick: function (e) {
		if (!this.isEnabled) {
			return;
		}

		var switcher = $(e.currentTarget);
		switcher.toggleClass('is_checked');
		if (switcher.hasClass('is_checked')) {
			this.value = this.values.on;
		} else {
			this.value = this.values.off;
		}

		this.onChange(switcher);
	},
	/*
	 * Handler function for getting the value
	 */
	getValue: function () {
		return this.value;
	},
	/*
	 * Handler function for setting the value
	 */
	setValue: function (value) {
		var switcher = this.switchHandler;
		if (value === this.values.on) {
			this.value = value;
			switcher.addClass('is_checked');
		} else if (value === this.values.off) {
			this.value = value;
			switcher.removeClass('is_checked');
		}

		return this;
	},
	/**
	 * Handler function for triggering events
	 * @param {String} event type
	 */
	trigger: function (event) {
		var events = {
			change: 'onChange'
		};

		if (events[event]) {
			this[events[event]].call(this);
		}
	},
	/**
	 * Handler function for enabling the component
	 * @param {Boolean} value Boolean value for enabling or disabling
	 */
	enable: function (value) {
		if (value) {
			this.renderTo.find('.cw_switch').removeClass('is_disabled');
			this.isEnabled = true;
		} else {
			this.renderTo.find('.cw_switch').addClass('is_disabled');
			this.isEnabled = false;
		}

		return this;
	}
};

import {UserSettings} from "tools/userSettings";

export const DesignerUserSettingsCategory = "DesignerUserSettingsCategory";

export const DesignerActions = Object.freeze({
	SelectConnectors: 'select-connectors', //there is such action in mxgraph, but we need it under different name,
	SelectShapes: 'select-shapes', //the same reason
	SelectAll: 'select-all', //the same reason
	InsertImage: 'insert-image'
});

export const DesignerEvents = Object.freeze({
	Loaded: 'DESIGNER_LOADED',
	RightClickMenuOpening: 'DESIGNER_RCM_OPENING',
	XmlLoaded: 'DESIGNER_XML_LOADED',
	DiagramPropertiesRendered: 'DESIGNER_DIAGRAM_PROPERTIES_RENDERED'
});

export async function loadDesignerSettings(key = null, defaultValue = null){
	return await UserSettings.get(DesignerUserSettingsCategory, key, defaultValue);
}

export async function saveDesignerSettings(settings){
	await UserSettings.set(DesignerUserSettingsCategory, settings);
}

export async function updateDesignerSetting(key, value){
	let settings = await UserSettings.get(DesignerUserSettingsCategory);
	settings[key] = value;
	await UserSettings.set(DesignerUserSettingsCategory, settings);
}

import {Api} from "tools/api";

export class AssetAPI {
	static getTargetsUrl(assetId) {
		return `${Api.accountRoot()}assets/${assetId}/targets`;
	}
	static getAssetsUrl(accountId, includeSubaccounts) {
		return `${Api.accountRoot(accountId)}assets/lite?includeSubaccounts=${includeSubaccounts || false}`;
	}
	static getAssetAgentsUrl(accountId, includeSubaccounts, systemType, sharedAgents) {
		return `${Api.accountRoot(accountId)}assets/lite?includeSubaccounts=${includeSubaccounts || false}&assetType=AGENT&systemType=${systemType || ''}&includeShared=${sharedAgents || false}`;
	}
}

export default AssetAPI;

import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "controls/react/form/multiSelect";
import {ACTIONS} from "./reducer";
import {b} from './filteringPanelShared'
import classnames from 'classnames';
import FilteringPanelApi from "./api";


function Asset(props) {
	const [assetsList, setAssetsList] = React.useState([]);
	const isMounted = React.useRef(true);

	React.useEffect( () => {
		if (isMounted.current) {
			getAssets();
		}
		return () => { isMounted.current = false; }
	}, [])
	const onAssetsChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_ASSETS, value, index: props.index });
	}, [props.dispatch]);

	const getAssets = async() => {
		let assets = await FilteringPanelApi.getAssets();
		let list = [];
		for (let asset of assets.items) {
			list.push({
				text: asset.name,
				value: asset.id
			});
		}
		setAssetsList(list);
	}

	return <>
		<div className={classnames(b('input'), b('reset-field'))}>
			<MultiSelect data={assetsList}
			             value={props.assets}
			             dataTextField={'text'}
			             dataValueField={'value'}
			             onChange={onAssetsChange}/>
		</div>
	</>
}

Asset.propTypes = {
	index: PropTypes.number.isRequired,
	assets: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default Asset;

import Lang from "core/localization/lang";

export const LEGEND_TYPE = {
	NONE: 'none',
	LEGEND: 'legend',
	INFORMATIONAL: 'informational'
};

export function getSelectorTypeDataSource(type) {
	const res = {
		[LEGEND_TYPE.LEGEND]: Lang.LEGEND,
		[LEGEND_TYPE.INFORMATIONAL]: Lang.INFORMATIONAL,
		[LEGEND_TYPE.NONE]: Lang.NONE
	}

	if (type !== 'single_graph') {
		delete res[LEGEND_TYPE.INFORMATIONAL];
	}

	return res;
}

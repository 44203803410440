import {Api, State} from "tools";
import {Settings} from "settings";
import {LocalEventsManager} from "core";
import {CustomNotification, Tags} from "controls";
import Utils from 'tools/utils';
import Renderer from 'tools/renderer';

let ServiceModelForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default ServiceModelForm;

ServiceModelForm.prototype = {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.tagsControl = new Tags({
			renderEl: $('#cw_tags'),
			enabled: this.mode === 'view' ? false : true
		});

		this.removeListeners();
		this.attachListeners();
		this.load();
		Utils.onLiveChange('#sm_template_name', '#card_name', lang.NAME);
		Utils.onLiveChange('#sm_template_description', '#card_description', lang.DESCRIPTION);

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
		if (this.mode === 'view') {
			this.removeListeners();
			this.enableViewMode();
		}
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#sm_template_name').addClass('is_readonly');
		$('#sm_template_description').addClass('is_readonly');
		$('#save_sm_template').addClass('hide');
		$('.is_readonly').attr('disabled', true);
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_sm_template').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_sm_template').on('click', $.proxy(this.onSaveButton, this));
		$('.create_sm_template').on('keyup', '#sm_template_name', $.proxy(this.onNameKeyUp, this));
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#sm_template_name').parent().removeClass('required_form');
	},
	onNameKeyUp: function () {
		const name = $('#sm_template_name').val();
		Utils.setInvalidField(name, $('#sm_template_name'), true);
	},
	/**
	 * Loads the form data from the view and set the values into component fields
	 */
	load: function () {
		if (State.currentApp.dataSource) {
			var dataSource = State.currentApp.dataSource;
			var record = dataSource.get(this.id);
			this.tagsControl.setRawValues(record.tagsArray);
			// set values in form
			var templateNameInput = $('#sm_template_name');
			$('#sm_template_description').val(record.description);
			templateNameInput.val(record.name);
			$('#card_name').text(record.name);
			$('#card_description').text(record.description);
			templateNameInput.focus();
			// update cw_avatar image
			$('#modal .cw_card_avatar').empty().append('<img src="' + Api.templates.preview(this.id) + '" style="max-width: 95px; max-height: 105px; padding: 2px; margin: 0" onError="this.onerror=null;Renderer.setTemplateDefaultIcon(this, \'model\');" onload="$(this).data(\'loaded\', true)" />');
			this.alignPreviewImgVertical();
		}
	},
	alignPreviewImgVertical: function () {
		var checkImageLoaded = setInterval(function() {
			var image = $('#cw_asset_card_avatar img');
			if (image.data('loaded')) {
				var height = $(image).height();
				var maxHeight = parseInt($(image).css('max-height').split('px')[0]);
				$(image).css('margin-top', (maxHeight - height) / 2);
				clearInterval(checkImageLoaded);
			}
		}, 10)
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			// close window
			$('#cancel').trigger('click');
			// show status
			this.statusNotification.setOptions({
				message: lang.account.messages.SERVICE_MODEL_TEMPLATE_SUCCESS_UPDATED,
				status: 'success'
			}).show();

			LocalEventsManager.trigger('smtemplatesaved');
		} else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function (e) {
		this.clearInvalid();
		this.data = {
			name: $('#sm_template_name').val().trim(),
			description: $('#sm_template_description').val().trim(),
			id: this.id,
			tags: this.tagsControl.getRawValues()
		};
		if (this.data.name.trim() === '') {
			this.modalNotification.setOptions({
				message: lang.account.messages.INPUT_SERVICE_MODEL_TEMPLATE_NAME,
				status: 'error'
			}).show();

			$('#sm_template_name').parent().addClass('required_form');
			$('#sm_template_name').focus();
		} else {
			var url = Settings.serverPath + 'templates/' + this.id + '/servicemodels';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}
};

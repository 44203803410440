import {ModalWindow} from "controls/modalWindow";
import {Utils} from "tools/utils";

import AssetConsoleWidget from "./widgets/consoleWidget";
import IbmConsoleWidget from "../service-boards/widgets/ibmConsoleWidget";
import {Localization} from "core/localization";

export function healthToState(health){
	if(!health)
		return 'ok';

	health = health.toLowerCase();

	if(health == 'nohealth')
		return 'none';

	if(health == 'none')
		return 'ok';

	return health;
}

export const sharedLocalization = new Localization({
	'STATIC': {
		en: 'Static',
		no: 'Statisk'
	},
	'DYNAMIC_FILTER': {
		en: 'Dynamic',
		no: 'Dynamisk'
	},
	'EXTERNAL': {
		en: 'External',
		no: 'Ekstern'
	}
});


export function openAssetConsoleWindow(config) {
	var window = new ModalWindow({
		title: config.title,
		width: config.width || 800,
		height: config.height || 540,
		resizable: config.resizable || false,
		modal: true,
		actions: [],
		url: 'include/AssetHealth/AssetConsole.jsp',
		refresh: $.proxy(function () {
			var widget;

			window.kendoWindow.wrapper.off().on('click', '.cw_assetconsole_close', $.proxy(function (e) {
				window.kendoWindow.close();
			}, this));

			switch (config.type) {
				case 'SYSTEM':
					var widget = new AssetConsoleWidget({
						id: Utils.guid(),
						renderTo: 'cw_console_widget',
						configuration: {
							assetId: config.assetId,
							monitorId: config.monitorId,
							accountId: config.accountId
						}
					});
					break;
				case 'IBMSVC':
					widget = new IbmConsoleWidget({
						id: Utils.guid(),
						renderTo: 'cw_console_widget',
						configuration: {
							assetId: config.assetId,
							monitorId: config.monitorId,
							assetName: config.assetName,
							assetDescription: config.assetDescription,
							accountId: config.accountId
						}
					});
					break;
			}
		}, this)
	});
	window.open();
}

import Api from 'tools/api';

export default class BudgetApi {
	static getBudgetList(accountId) {
		return Api.fetch(Api.accountRoot(accountId) + 'cost/profiles');
	}

	static getBudgetItemsList(accountId, budgetId, modelId, showTotal = true, showOther = true) {
		return Api.fetch(`${Api.accountRoot(accountId)}cost/profiles/${budgetId}/models/${modelId}/resources?showTotal=${showTotal}&showOther=${showOther}`);
	}

	static getCostModelsItemsList(accountId, budgetId) {
		return Api.fetch(`${Api.accountRoot(accountId)}cost/profiles/${budgetId}/models`);
	}

	static getResourceCost(payload) {
		return Api.fetchPost(Api.accountRoot(payload.accountId) + 'cost/resourceCost', payload);
	}

	static getAvailableYears(payload){
		return Api.fetch(Api.accountRoot(payload.accountId) + `cost/profiles/${payload.profileId}/models/${payload.modelId}/years`);
	}
}

import './filterPanel.less';

import React from 'react';
import {PreferencesPanel as PreferencesPanelLegacy} from "controls";
import UserPrefs from 'tools/userPrefs';
import Utils from 'tools/utils';

let b = require('b_').with('filter-panel');

export default class FilterPanel extends React.PureComponent {
	constructor(props) {
		super(props);
		this.refEl = React.createRef();
	}

	async componentDidMount() {
		this.filterPanel = new PreferencesPanelLegacy({
			renderTo: $(this.refEl),
			grid: this.getKendoGrid(this.props.grid),
			modulePrefName: this.props.modulePrefName,
			defaultPrefViewKey: this.props.defaultPrefViewKey,
			prefListKey: this.props.prefListKey,
			userPref: this.props.userPref,
			onRemove: this.props.onRemove,
			searchFields: this.props.searchFields,
			searchValue: this.props.searchValue,
			defaultColumns: this.props.defaultColumns,
			activateResetFilterIcon: this.props.activateResetFilterIcon
		});
	}

	setGrid(grid){
		this.filterPanel.setGrid(this.getKendoGrid(grid));
	}

	componentDidUpdate(prevProps){
		if (!this.filterPanel) {
			return;
		}
		if( prevProps.grid != this.props.grid){
			this.filterPanel.setGrid(this.getKendoGrid(this.props.grid));
		}
		if( prevProps.searchValue != this.props.searchValue){
			this.filterPanel.setSearchValue(this.props.searchValue);
		}
	}

	getKendoGrid(grid){
		if(grid == null )
			return null;

		if( grid.kendoGrid != null )
			return grid.kendoGrid;

		return grid;
	}

	getUserPref(userPref){
		if(userPref == null )
			return null;

		if( userPref != null )
			return userPref;

		return userPref;
	}

	loadUserPreferences = async () => {
		const result = await UserPrefs.load(this.props.userPrefKey);
		this.userPref = result.success ? result.data : [];
	}

	render() {
		return (
			<div className={b()} ref={(el) => { this.refEl = el }}/>
		)
	}
}

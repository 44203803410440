import "./overlay.less";

import React from "react";
import PropTypes from "prop-types";

let b = require('b_').with('overlay');

export class Overlay extends React.PureComponent {
	setRef = (ref) => {
		this.ref = ref;
	}

	updateAnchorStyles(){
		if( !this.props.anchor )
			return;

		if( window.getComputedStyle(this.props.anchor).position != 'absolute') {
			this.props.anchor.style.position = 'relative';
		}
	}

	componentDidMount() {
		this.updateAnchorStyles();
	}

	componentDidUpdate() {
	 	this.updateAnchorStyles();
	}

	render() {
		// if (!this.props.anchor) {
		// 	return null;
		// }

		return (
			<div className={b({ hidden: !this.props.show })}
			     ref={this.setRef}>
				{(this.props.spinner || this.props.message) &&
					<div className='wrapper1'>
						{this.props.message != null &&
							<div className={b('message')}>{this.props.message}</div>
						}
						{this.props.spinner && <div className={b('spinner')}/>}
					</div>
				}
			</div>
		);
	}
}

export default Overlay;

Overlay.propTypes = {
	anchor: PropTypes.object,
	show: PropTypes.bool,
	message: PropTypes.string,
	spinner: PropTypes.bool
}

Overlay.defaultProps = {
	spinner: false,
	show: false
}

import React, {useMemo} from "react";
import {CeeViewDataSource} from "tools"

export function useDynamicDataSource(url, filter = null, options = {}) {
	return useMemo(() => {
		return new CeeViewDataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: function (data, type) {
					return kendo.stringify(data);
				}
			},
			schema: {
				data: (data) => {
					return data.items;
				}
			},
			pageSize: 100,
			sort: [{
				field: 'name',
				dir: 'asc'
			}],
			filter: filter,
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			requestEnd: () => {
			},
			...options
		});
	}, [url, filter]);
}

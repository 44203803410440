import React from "react";
import ReactDOM from "react-dom";
import Portal from './portal';

import "./contextMenu.less";

function MenuItem(props) {
	const {icon, text, fn} = props;

	return <div onClick={fn} className="context-menu-component__menu-item">
		<span className={`glyphicons ${icon}`}></span>
		<span className="item-label">{text}</span>
	</div>
}

export default class ContextMenu extends React.PureComponent {
	ref = React.createRef();

	componentDidMount() {
		if (this.props.anchor) {
			this.updatePosition();
		}
	}

	componentDidUpdate() {
		if (this.props.anchor) {
			this.updatePosition();
		}
	}

	updatePosition() {
		const {x, y} = this.props.anchor;
		$(this.ref.current).css({top: y, left: x});
	}

	render() {
		const {anchor, items} = this.props;

		if (!anchor) {
			return null;
		}

		return <Portal>
			<div className="context-menu-component" ref={this.ref}>
				{items.filter(item => item.isAvailable && item.isAvailable() || !item.isAvailable)
					.map(item => <MenuItem key={item.text} {...item} />)}
			</div>
		</Portal>
	}
}


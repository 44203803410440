let qualifiers = {
	getCalculatedProperties( qualifier ){
		let result = {
			inWarning: false,
			inMaintenance: false,
			iconTooltip: '',
			displayName: qualifier.name
		};

		let tooltipParts = []
		if( qualifier.agentStatus === 'AGENT_DOWN' ){
			tooltipParts.push(lang.account.messages.AGENT_WARNING);
			result.inWarning = true;
		}

		if( qualifier.agentStatus == 'AGENT_MAINTENANCE'){
			tooltipParts.push(lang.account.messages.AGENT_MAINTENANCE);
			result.inMaintenance = true;
		}

		if( qualifier.qualifierError){
			tooltipParts.push(lang.account.messages.QUALIFIER_ERROR)
			result.inWarning = true;
		}

		if( qualifier.assetMaintenance){
			tooltipParts.push(lang.account.messages.ASSET_MAINTENANCE)
			result.inMaintenance = true;
		}

		if( qualifier.operatingState === 'IN_MAINTENANCE'){
			tooltipParts.push(lang.SERVICE_MAINTENANCE)
			result.inMaintenance = true;
		}

		tooltipParts.push(
			(qualifier.shared
				? lang.designer.SHARED_SERVICE_QUALIFIER
				: lang.designer.SERVICE_QUALIFIER
			)
			+ ': ' + qualifier.name
		);

		result.iconTooltip = tooltipParts.join(' / ');
		return result;
	},


}

export function updateFromEvent(qualifierOrQualifiers, newData){
	let qualifier = qualifierOrQualifiers;
	if( Array.isArray(qualifierOrQualifiers) ){
		qualifier = qualifierOrQualifiers.find( q => q.id == newData.sourceId)
	}

	if( qualifier == null )
		return false;

	let changed = false;

	if(qualifier.state != newData.status){
		qualifier.state = newData.status;
		changed = true;
	}

	if(qualifier.agentStatus != newData.agentStatus) {
		qualifier.agentStatus = newData.agentStatus;
		changed = true;
	}

	if(qualifier.qualifierError != newData.qualifierError) {
		qualifier.qualifierError = newData.qualifierError;
		changed = true;
	}

	if(qualifier.assetMaintenance != newData.assetMaintenance) {
		qualifier.assetMaintenance = newData.assetMaintenance;
		changed = true;
	}

	if(qualifier.operatingState != newData.operatingState && newData.operatingState !== undefined) {
		qualifier.operatingState = newData.operatingState;
		changed = true;
	}

	return changed;
}

export default qualifiers;

import {Api} from "tools/api";
import {AccountsApi, AssetGroupsApi, AssetsApi, ServicesApi, SlaApi} from "api";
import {ApiRequest} from "framework/api";
import {Geotag} from "framework/entities/geotag";
import {HealthDataHolder} from "framework/entities/healthData";


export interface AssetHealthRequestEntry{
	assetId: string,
	accountId: string
}

export interface ServiceHealthRequestEntry{
	serviceId: string,
	serviceQualifierId?: string,
	accountId: string
}

//the others entries should be added on demand
export type HealthRequestEntry = AssetHealthRequestEntry | ServiceHealthRequestEntry;

export interface AssetHealthResponseEntry extends HealthDataHolder{
	assetId: string;
	assetName: string;
	geotag: Geotag;
}

export type HealthResponseEntry = AssetHealthResponseEntry

export function getHealthInfo(entries: HealthRequestEntry[], sessionId?: string){
	return new ApiRequest<HealthResponseEntry[]>({
		url: 'applications/healthInfo',
		payload: entries
	});
}

import {CeeViewDataSource, State, Utils} from "tools";
import {CustomNotification, ModalWindow} from "controls";
import {Settings} from "settings";
import {ErrorHandler, LocalEventsManager} from "core";
import {AssetGroupForm} from "areas/administration";

export function AssetGroupsListWindow(config) {
	Utils.apply(this, config);
	this.initComponent();
}

export {AssetGroupsListWindow as default}

AssetGroupsListWindow.prototype = {
	/**
	 * @cfg {Array} assets List with the assets id to be added. Mandatory
	 */
	/**
	 * @cfg {String} accountId The id of the account where the asset group and the assets resides. Mandatory
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			$('#cw_create_assetgroup').removeClass('hide');
		} else {
			$('#cw_create_assetgroup').addClass('hide');
		}

		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_asset_groups_list_window',
			status: 'error',
			style: 'top:55px;',
			animationTime: 0,
			hideOnClick: false
		});

		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_close').off();
		$('#cw_update').off();
		$('#cw_create_assetgroup').off();
		$('#select_assets_window').off('keyup', '.cw_search_box');
		$('#select_assets_window').off('click', '.cw_ag_name');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_close').on('click', $.proxy(this.onClose, this));
		$('#cw_update').on('click', $.proxy(this.onUpdate, this));
		$('#cw_create_assetgroup').on('click', $.proxy(this.onCreateNewAssetGroupClick, this));
		$('#select_assets_window').on('keyup', '.cw_search_box', $.proxy(this.onSearchType, this));
		$('#select_assets_window').on('click', '.cw_ag_name', $.proxy(this.onListItemClick, this));

		LocalEventsManager.bind('assetgroupsaved', function () {
			$('#cw_close').trigger('click');
		});
	},
	initKendoComponents: function () {
		this.assetGroupDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + this.accountId + '/assetGroups/lite/?groupType=STATIC',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError,
			change: $.proxy(function(e) {
				var length = e.items.length;
				if (this.isSearching && !length) {
					this.actionNotification.setOptions({
						message: lang.account.messages.ASSET_GROUP_NO_GROUPS
					}).show();
					$('#cw_update').prop('disabled', true);
				} else if (!length) {
					this.actionNotification.setOptions({
						message: lang.account.messages.NO_ASSET_GROUPS
					}).show();
					$('#cw_update').prop('disabled', true);
				} else {
					this.actionNotification.hide();
				}
			}, this)
		})
		this.assetGroupsList = $('#cw_asset_groups_list').kendoListView({
			dataSource: this.assetGroupDataSource,
			selectable: 'single',
			template: '<li class="item" data-id="${id}"><span class="cw_ag_name">${name}</span></li>'
		}).data('kendoListView');
	},
	/*
	 * Handler function for item list click
	 */
	onListItemClick: function() {
		if (this.assetGroupsList.select().length) {
			$('#cw_update').prop('disabled', false);
		} else {
			$('#cw_update').prop('disabled', true);
		}
	},
	/*
	 * Handler functions for searching asset groups
	 * @param {Object} e The keyup event
	 */
	onSearchType: function(e) {
		var target = $(e.currentTarget), value = target.val();
		this.assetGroupDataSource.filter({
			field: "name",
			operator: "startswith",
			value: value
		});
		this.isSearching = true;
	},
	/**
	 * Handler function for the click event on the Cancel/close button
	 * @param {Object} e The click event object
	 */
	onClose: function (e) {
		$(e.currentTarget).closest('.k-window-content').data('kendoWindow').close();
	},
	/**
	 * Handler function for the click event on the save button
	 */
	onUpdate: function () {
		var selectedRow = this.assetGroupsList.select()[0];
		var assetGroupId = this.assetGroupsList.dataItem(selectedRow).id;
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/assetGroups/' + assetGroupId + '/members';
		Utils.ajax(url, 'POST', JSON.stringify(this.assets), $.proxy(function (result) {
			if (result.success) {
				$('#cw_close').trigger('click');
				LocalEventsManager.trigger('assetsAddedToGroup');
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/*
	 * Handler function for clicking create new asset group button
	 * @param {type} e
	 */
	onCreateNewAssetGroupClick: function(e) {
		var modalWindow = new ModalWindow({
			title: lang.account.ASSET_GROUP_CREATE,
			width: 800,
			url: 'include/Administration/AssetGroupForm.jsp',
			refresh: $.proxy(function () {
				$('#cw_close').trigger('click');

				new AssetGroupForm({
					id: 'new_asset_group',
					mode: 'new',
					assets: this.assets,
					accountId: this.accountId,
					triggerAddToGroup: true
				});
			}, this),
			height:640,
			minHeight: 640
		});
		modalWindow.open();
	}
};

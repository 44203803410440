import React from "react"
import PropTypes from "prop-types";
import {DropDownList} from "controls/react/form";
import {Lang} from 'core'
import injectSheet from "react-jss";
import { MultiToggle } from "controls/react";

const ITEMS = {
	LASTHOUR: Lang.periodMultiToggle.HOUR,
	LASTDAY: Lang.periodMultiToggle.DAY,
	LAST7DAYS: Lang.periodMultiToggle.WEEK,
	LAST30DAYS: Lang.periodMultiToggle.MONTH,
	CUSTOM: Lang.CUSTOM
};

const styles = {
	container: {
		display: "flex",
		alignItems: "flex-end"
	},
	item: {
		flexGrow: 1,
		margin: "0 10px"
	}
};

function WidgetSettings(props) {
	const [period, setPeriods] = React.useState(props.componentConfig.period || false);
	const el = React.useRef();

	const {config, classes} = props;

	const onPeriodChange = React.useCallback((value) => {
		if (value === "CUSTOM") {
			return;
		}

		props.events.trigger('period:change', value);
		$(el.current).parent().hide();
	}, [props.events])

	return <div ref={el} className={classes.container}>
		<div className={classes.item}></div>
		<div>
			{config.period &&
				<MultiToggle
					value={period}
					onChange={onPeriodChange}
					items={ITEMS} />}
		</div>
	</div>
}

WidgetSettings.propTypes = {
	config: PropTypes.any,
	componentConfig: PropTypes.any,
	events: PropTypes.any
}

export default injectSheet(styles)(WidgetSettings)

import React, {useEffect, useState} from 'react';
import {Api} from 'tools'
import {ServicesApi} from 'areas/services/api';
import {loadChildEntities} from './loadChildEntities';

export function useServiceQualifiersDataSource(services, elements) {
	const [data, setData] = useState([]);

	let dependency = "";
	if(elements?.length){
		dependency = elements.map(x => x.id).join();
	}else{
		dependency = services.map(x => x.id).join();
	}

	useEffect(() => {
		if (elements?.length) {
			loadChildEntities(
				elements,
				element => ServicesApi.getElementQualifiers(element.serviceId, element.id),
				(qualifier, element) => {
					qualifier.serviceId = element.serviceId;
					qualifier.elementId = element.id;
					qualifier.accountId = element.accountId;
					qualifier.serviceName = element.serviceName;
					qualifier.accountName = element.accountName;
					qualifier.elementName = element.name;
				},
				setData
			);
		}else{
			loadChildEntities(
				services,
				service => ServicesApi.getServiceQualifiers(service.id),
				(qualifier, service) => {
					qualifier.serviceId = service.id;
					qualifier.accountId = service.accountId;
					qualifier.accountName = service.accountName;
					qualifier.serviceName = service.name;
				},
				setData
			)
		}
	}, [dependency]);

	return data;
}

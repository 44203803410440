
export default function TaskManager(interval) {
	interval = interval || 30;
	var tasks = [], tasksToRemove = [], id = 0, running = false;
	let stopThread = function () {
			running = false;
			clearInterval(id);
			id = 0;
		},
		startThread = function (interval) {
			if (!running) {
				running = true;
				id = setInterval(runTasks, interval);
			}
		},
		removeTask = function (t) {
			tasksToRemove.push(t);
			if (t.onStop) {
				t.onStop.apply(t.scope || t);
			}
		},
		runTasks = function () {
			var now = new Date().getTime();

			for (let removeQueueEntry of tasksToRemove) {
				var index = tasks.indexOf(removeQueueEntry);
				if (index > -1) {
					tasks.splice(index, 1);
				}
			}

			tasksToRemove = [];
			if (tasks.length == 0) {
				stopThread();
				return;
			}

			for (let task of tasks) {
				let itime = now - task.taskRunTime;
				if (task.interval <= itime) {
					let taskResult = task.run.apply(task.scope || task, task.args || [++task.taskRunCount]);
					task.taskRunTime = now;
					if (taskResult === false || task.taskRunCount === task.repeat) {
						removeTask(task);
						return;
					}
				}
				if (task.duration && task.duration <= (now - task.taskStartTime)) {
					removeTask(task);
				}
			}
		};
	/**
	 * Starts a new task.
	 * @param {Object} task <p>A config object that supports the following properties:<ul>
	 * <li><code>run</code> : Function<div class="sub-desc"><p>The function to execute each time the task is invoked. The
	 * function will be called at each interval and passed the <code>args</code> argument if specified, and the
	 * current invocation count if not.</p>
	 * <p>If a particular scope (<code>this</code> reference) is required, be sure to specify it using the <code>scope</code> argument.</p>
	 * <p>Return <code>false</code> from this function to terminate the task.</p></div></li>
	 * <li><code>interval</code> : Number<div class="sub-desc">The frequency in milliseconds with which the task
	 * should be invoked.</div></li>
	 * <li><code>args</code> : Array<div class="sub-desc">(optional) An array of arguments to be passed to the function
	 * specified by <code>run</code>. If not specified, the current invocation count is passed.</div></li>
	 * <li><code>scope</code> : Object<div class="sub-desc">(optional) The scope (<tt>this</tt> reference) in which to execute the
	 * <code>run</code> function. Defaults to the task config object.</div></li>
	 * <li><code>duration</code> : Number<div class="sub-desc">(optional) The length of time in milliseconds to invoke
	 * the task before stopping automatically (defaults to indefinite).</div></li>
	 * <li><code>repeat</code> : Number<div class="sub-desc">(optional) The number of times to invoke the task before
	 * stopping automatically (defaults to indefinite).</div></li>
	 * </ul></p>
	 * <p>Before each invocation, Ext injects the property <code>taskRunCount</code> into the task object so
	 * that calculations based on the repeat count can be performed.</p>
	 * @return {Object} The task
	 */
	this.start = function (task) {
		var realInterval;
		tasks.push(task);
		task.taskStartTime = new Date().getTime();
		task.taskRunTime = 0;
		task.taskRunCount = 0;
		if (task.useInterval) {
			realInterval = task.interval;
		} else {
			realInterval = interval;
		}
		startThread(realInterval);
		return task;
	};
	/**
	 * Stops an existing running task.
	 * @param {Object} task The task to stop
	 * @return {Object} The task
	 */
	this.stop = function (task) {
		removeTask(task);
		return task;
	};
	/**
	 * Stops all tasks that are currently running.
	 */
	this.stopAll = function () {
		stopThread();
		for (var i = 0, len = tasks.length; i < len; i++) {
			if (tasks[i].onStop) {
				tasks[i].onStop();
			}
		}
		tasks = [];
		tasksToRemove = [];
	};
	/*
	* Returns if the task manager is running
	* */
	this.isRunning = function () {
		return running;
	}
};

import {compileName} from "areas/service-boards/widgets/stackChartWidget/helpers";
import Utils from 'tools/utils';

const DEFAULT_CONFIG = {
	yAxis: {
		title: {
			text: 'Unit'
		}
	},
	xAxis: {
		type: 'datetime',
		labels: {
			staggerLines: 1
		},
		dateTimeLabelFormats: {
			millisecond: '%H:%M:%S',
			second: '%H:%M:%S',
			minute: '%H:%M',
			hour: '%H:%M',
			day: '%e. %b',
			week: '%e. %b',
			month: '%b \'%y',
			year: '%Y'
		},
		title: {
			text: 'Time'
		},
	},
	plotOptions: {
		series: {
			label: {
				connectorAllowed: false
			},
		}
	}
};

class CommonType {
	defaultConfiguration(): any {
		return DEFAULT_CONFIG;
	}

	getMetrics(config): Array<any> {
		return config.series;
	}

	addOriginalMetrics(config: any, originalMetircs: Array<any>) {
		config.series = originalMetircs || [];
	}

	setMetrics(config: any, metrics, labelTemplate) {
		config.series = config.series || [];

		for (const metric of metrics) {
			const found = config.series.find((series) => series.id === metric.metricId);

			if (found) {
				continue;
			}

			const name = compileName(labelTemplate, metric);

			config.series.push({
				id: metric.metricId,
				idName: metric.categoryPath + metric.instanceName,
				color: metric.color,
				unitTypeSymbol: metric.unitTypeSymbol,
				customUnit: metric.customUnit,
				name,
				showInLegend: name !== ""
			});
		}

		const ids = metrics.map((metric) => metric.metricId);

		config.series = config.series.filter((series) => {
			return !(!ids.includes(series.id) && Utils.isGuid(series.id));
		});
	}
}

export default CommonType;

import React from "react";
import {TextBox as _TextBox} from "controls/react/form/textBox";
import {TextArea as _TextArea} from "controls/react/form/textArea";
import _AccountDropDown from "controls/react/dropdowns/accountDropDown";
import _AgentDropDown from "controls/react/dropdowns/agentDropDown";
import _AssetDropDown from "controls/react/dropdowns/assetDropDown";
import _AssetTargetDropDown from "controls/react/dropdowns/assetTargetDropDown";
import _AuthenticationDropDown from "controls/react/dropdowns/authenticationDropDown";

const ReduxFormField = (component) => {
	return (props) => {
		const {input: {onChange, value}, meta, ...others} = props;

		return React.cloneElement(component, {
			onChange,
			value,
			...others
		});
	}
};

export default ReduxFormField;

export const TextBox = ReduxFormField(<_TextBox />);
export const TextArea = ReduxFormField(<_TextArea />);
export const AccountDropDown = ReduxFormField(<_AccountDropDown />);
export const AgentDropDown = ReduxFormField(<_AgentDropDown />);
export const AssetDropDown = ReduxFormField(<_AssetDropDown />);
export const AssetTargetDropDown = ReduxFormField(<_AssetTargetDropDown assetId="assetId"/>);
export const AuthenticationDropDown = ReduxFormField(<_AuthenticationDropDown />);

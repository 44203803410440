import Utils from 'tools/utils'

export let MultiToggle = function (config) {
	this.type = 'icon';

	Utils.apply(this, config);
	this.initComponent();
};

export default MultiToggle;

MultiToggle.prototype = {
	/**
	 * @config {String} id The id of the div where the control will be rendered
	 */
	/**
	 * @config {Array} items Array with the selectable items (id, title, selected, cssClass, fn, scope, label)
	 */
	/**
	 * @config {String} value The initial value
	 */
	/**
	 * @config {String} type The type of the multitoggle
	 * @value icon, label, both
	 */
	/**
	 * @config {Boolean} disabled Disables the toggle
	 * @value false
	 */
	/**
	 * @config {String} cssClass The CSS class that will be applied to the UL
	 * element
	 */

	/**
	 * Main init class
	 */
	initComponent: function () {
		var item;
		this.hasCustomDates = false;
		this.customSelector = null;
		this.clickedItem = null;

		if (this.id) {
			this.list = $('#' + this.id);
		} else if (this.selector) {
			this.list = this.selector;
		}
		this.list.addClass(this.cssClass);
		//this.list.css('height', '27px');

		if (this.disabled) {
			this.list.addClass('is_disabled');
		}

		for (var i = 0, length = this.items.length; i < length; i++) {
			item = this.items[i];
			if (!item.id) {
				item.id = Utils.guid();
			}
			if (this.type === 'label') {
				this.list.append('<li class="item" title="' + (item.title || '') + '"><input id="' + item.id + '" name="multitoggle" type="radio" value="' + item.value + '" data-selected="' + item.selected + '"/>' + item.label + '</li>');
			} else if (this.type === 'icon') {
				this.list.append('<li class="item" title="' + (item.title || '') + '"><input id="' + item.id + '" name="multitoggle" type="radio" value="' + item.value + '" data-selected="' + item.selected + '"/><span class="' + (item.cssClass || '') + '"></span></li>');
			} else if (this.type === 'both') {
				this.list.append('<li class="item" title="' + (item.title || '') + '"><input id="' + item.id + '" name="multitoggle" type="radio" value="' + item.value + '" data-selected="' + item.selected + '"/><span class="glyphicons ' + item.cssClass + '"></span><span>' + item.label + '</span></li>');
			}

			if (item.type === 'custom') {
				this.hasCustomDates = true;
			}

			if (i === 0) {
				this.list.children().first().addClass('first_btn');
			}
			if (i === length - 1) {
				this.list.children().last().addClass('last_btn');
			}
		}

		if (this.hasCustomDates) {
			this.customSelector = 'cw_custom_' + this.id;

			this.list.after(this.setCustomContent());
			this.initKendoCustomDates();
		}

		this.list.find('input[data-selected="true"]').closest('li').addClass('is_selected');
		this.removeListeners();
		this.attachListeners();
	},

	initKendoCustomDates: function () {
		this.fromDate = $('#' + this.customSelector).find('.cw_date_from').kendoDatePicker({
			format: 'dd/MM/yyyy'
			//change: $.proxy(this.onFromChange, this)
		}).data('kendoDatePicker');

		this.toDate = $('#' + this.customSelector).find('.cw_date_to').kendoDatePicker({
			format: 'dd/MM/yyyy'
			//change: $.proxy(this.onToChange, this)
		}).data('kendoDatePicker');

		$('#' + this.customSelector).on('click', '.k-button', $.proxy(this.onCustomPeriodsSelect, this));
	},
	/*
	 * Disables the multiToggle
	 */
	disable: function (value) {
		if (value) {
			this.list.addClass('is_disabled');
		} else {
			this.list.removeClass('is_disabled');
		}
		this.disabled = value;
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.list.off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.list.on('click', 'li', $.proxy(this.onItemClick, this));
	},
	/**
	 * Handler function for the click event on a toggle item.
	 * Passes the value of the clicked item to the item function
	 * @param e
	 */
	onItemClick: function (e) {
		if (!this.disabled) {
			var id = $(e.currentTarget).find('input').attr('id');
			this.select(id);
			for (var i = 0, length = this.items.length; i < length; i++) {
				var item = this.items[i];
				if (id === item.id) {
					if (item.fn) {
						if (item.type === 'custom') {
							$('#' + this.customSelector).toggleClass('hide');
							this.clickedItem = item;
						} else {
							$('#' + this.customSelector).addClass('hide');
							item.fn.call(item.scope, item.value, e.target);
						}
					}
					break;
				}
			}
		}
	},
	/**
	 * Gets the value of the current selected item
	 * @return {String} value
	 */
	getValue: function () {
		return this.list.find('.is_selected').find('input').attr('value');
	},
	/**
	 * Gets the selected item
	 */
	getSelectedItem: function () {
		return this.list.find('.is_selected');
	},
	/*
	* Handler function for setting the item by sending the value
	* @param {String} value The value of the item that has to be selected
	* */
	setSelectedItem: function (value) {
		var item = this.list.find('input[value="' + value + '"]');
		this.list.find('input').prop('checked', false);
		this.list.find('li').removeClass('is_selected');

		item.prop('checked', true);
		item.parent().addClass('is_selected');
	},
	/**
	 * Selects an item based on id
	 * If called without parameter, it returns the selected item
	 * @param {String} id
	 * @return {Object} item The selected item
	 */
	select: function (id) {
		var length = arguments.length, item, i, len;

		if (length) {
			for (let i = 0, len = this.items.length; i < len; i++) {
				if (id === this.items[i].id) {
					item = this.items[i];
					item.selected = true;
				} else {
					this.items[i].selected = false;
				}
			}
			this.list.find('.is_selected').removeClass('is_selected');
			this.list.find('#' + id).closest('li').addClass('is_selected');
		} else {
			for (let i = 0, len = this.items.length; i < len; i++) {
				item = this.items[i];
				if (item.selected) {
					break;
				}
			}
		}

		return item;
	},
	/*
	 * Handler function for deselect all buttons
	 */
	removeSelections: function () {
		this.list.find('li').removeClass('is_selected');
	},

	/*
	 * Handler function
	 */
	setCustomContent: function () {
		var html = '', customSelector = $('#' + this.customSelector);

		if (customSelector.length) {
			customSelector.remove();
		}

		html += '<div id="' + this.customSelector + '" class=" cw_multitoggle_custom_period hide">';
		html += '<input class="cw_date_from" type="text" />';
		html += '<span> - </span>';
		html += '<input class="cw_date_to" type="text" />';
		html += '<button class="k-button k-primary right">' + lang.SELECT + '</button>';
		html += '</div>';

		return html;
	},

	onCustomPeriodsSelect: function () {
		var item = this.clickedItem, from = this.fromDate.value(), to = this.toDate.value();

		if (from && to) {
			item.fn.call(item.scope, {
				from: from,
				to: to
			});
		}
	}
}

import Lang from "core/localization/lang";

export const chartTypeDataSource = [
	{
		text: Lang.AREA_CHART,
		value: 'area'
	},
	{
		text: 'Area range',
		value: 'arearange'
	},
	{
		text: 'Area Spline',
		value: 'areaspline'
	},
	{
		text: 'Area Spline Range',
		value: 'areasplinerange'
	},
	{
		text: Lang.LINE_CHART,
		value: 'line'
	},
	{
		text: 'Bar',
		value: 'bar'
	},
	{
		text: 'Bell Curve',
		value: 'bellcurve'
	},
	{
		text: 'Box Plot',
		value: 'boxplot'
	},
	{
		text: 'Bubble',
		value: 'bubble'
	},
	{
		text: 'Bullet',
		value: 'bullet'
	},
	{
		text: 'Column',
		value: 'column'
	},
	{
		text: 'Column Pyramid',
		value: 'columnpyramid'
	},
	{
		text: 'Column Range',
		value: 'columnrange'
	},
	{
		text: 'Cylinder',
		value: 'cylinder'
	},
	{
		text: 'Dependency Wheel',
		value: 'dependencywheel'
	},
	{
		text: 'Dumb Bell',
		value: 'dumbbell'
	},
	{
		text: 'Error Bar',
		value: 'errorbar'
	},
	{
		text: 'Funnel',
		value: 'funnel'
	},
	{
		text: 'Funnel 3D',
		value: 'funnel3d'
	},
	{
		text: 'Gauge',
		value: 'gauge'
	},
	{
		text: 'Heatmap',
		value: 'heatmap'
	},
	{
		text: 'Histogram',
		value: 'histogram'
	},
	{
		text: 'Item',
		value: 'item'
	},
	{
		text: 'Lollipop',
		value: 'lollipop'
	},
	{
		text: 'Network Graph',
		value: 'networkgraph'
	},
	{
		text: 'Organization',
		value: 'organization'
	},
	{
		text: 'Packed Bubble',
		value: 'packedbubble'
	},
	{
		text: 'Pareto',
		value: 'pareto'
	},
	{
		text: 'Polygon',
		value: 'polygon'
	},
	{
		text: 'Pie',
		value: 'pie'
	},
	{
		text: 'Pyramid',
		value: 'pyramid'
	},
	{
		text: 'Pyramid 3D',
		value: 'pyramid3d'
	},
	{
		text: 'Spline',
		value: 'spline'
	},
	{
		text: 'Sankey',
		value: 'sankey'
	},
	{
		text: 'Scatter',
		value: 'scatter'
	},
	{
		text: 'Scatter 3D',
		value: 'scatter3d'
	},
	{
		text: 'Solid Gauge',
		value: 'solidgauge'
	},
	{
		text: 'Stream Graph',
		value: 'streamgraph'
	},
	{
		text: 'Sunburst',
		value: 'sunburst'
	},
	{
		text: 'Tilemap',
		value: 'tilemap'
	},
	{
		text: 'Timeline',
		value: 'timeline'
	},
	{
		text: 'Treemap',
		value: 'treemap'
	},
	{
		text: 'Variable Pie',
		value: 'variablepie'
	},
	{
		text: 'Variwide',
		value: 'variwide'
	},
	{
		text: 'Vector',
		value: 'vector'
	},
	{
		text: 'Venn',
		value: 'venn'
	},
	{
		text: 'Waterfall',
		value: 'waterfall'
	},
	{
		text: 'Windbarb',
		value: 'windbarb'
	},
	{
		text: 'Word Cloud',
		value: 'wordcloud'
	},
	{
		text: 'X Range',
		value: 'xrange'
	}
];

export default chartTypeDataSource;

// {
// 	text: Lang.AREA_CHART,
// 		value: 'area'
// }, {
// 	text: Lang.AREASPLINE_CHART,
// 		value: 'areaspline'
// }, {
// 	text: Lang.LINE_CHART,
// 		value: 'line'
// }, {
// 	text: Lang.RANGE_CHART,
// 		value: 'arearange'
// }, {
// 	text: Lang.SPLINE_CHART,
// 		value: 'spline'
// }

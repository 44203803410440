import {Application, Cookies, ErrorHandler, LocalEventsManager} from 'core';
import {CustomNotification, ExpandableTextarea, ImageUploader, Tags} from 'controls';
import {State, Utils, CeeViewDataSource} from 'tools';
import TeamForm from 'areas/administration/teamForm';
import Settings from 'settings';
import Renderer from 'tools/renderer';
import Api from 'tools/api';
import ModalWindow from 'controls/modalWindow';

export let AccountForm = function (config) {
	Utils.apply(this, config);
	if (this.onlyMediaLibrary) {
		var modalWindow = $('#account_modal').data("kendoWindow");
		modalWindow.setOptions({
			title: lang.CHOOSE_LOGO_IMAGE
		});
		$('#cancel').on('click', $.proxy(function() {
			modalWindow.close();
		}, this));
		$('#save_account').text(lang.UPDATE).on('click', $.proxy(function() {
			if (this.isFromAccount) {
				LocalEventsManager.trigger('logoImageChanged', this.selectedImageData);
			}
			modalWindow.close();
		}, this));
		$('#card_name').html(this.name);
		$('#card_description').addClass('hide');
		$('#account_glyph_icons').css({'height': '520px'});
		this.onMediaLibraryButton();
	} else {
		this.initComponent();
	}
};

export default AccountForm;

jQuery.extend(AccountForm.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.update = true;
		if (this.mode === 'view') {
			this.update = false;
		}
		var actionButtonText = lang.CREATE;
		this.tagsDataSource = [];
		this.recentlyUsedIcons = [];
		this.userPref = [];

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('#account_modal .cw_description')
		});

		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;
		}
		$('#save_account').text(actionButtonText);
		$('#current_account_name').text(Cookies.CeesoftCurrentAccountName);
		this.initKendoComponents();
		this.removeListeners();
		this.attachListeners();
		//this.renderUploaderImagesControl();
		if (this.mode === 'update') {
			$('#js_inner_auth_token_form').removeClass('hide');
			this.load();
		}
		else {
			var focus = function () {
				$('#account_title_input').focus();
			};
			setTimeout(focus, 2000);
		}

		Utils.onLiveChange('#account_title_input', '#card_name', lang.account.ACCOUNT_NAME);
		Utils.onLiveChange('.cw_description textarea', '#card_description', lang.account.ACCOUNT_DESCRIPTION);
		//Utils.onLiveChange(this.descriptionHandler.textarea, lang.account.ACCOUNT_DESCRIPTION);

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			type: 'icon',
			icon: 'glyphicons ok-circle',
			// style: 'top:15px; right:15px; left:15px;',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#account_modal',
			status: 'error',
			type: 'icon',
			icon: 'glyphicons exclamation-sign',
			hideOnClick: true
		});
		if (this.mode === 'view') {
			this.load();
			this.removeListeners();
			this.enableViewMode();
		}
	},
	/**
	 * Initializes kendo controls
	 */
	initKendoComponents: function () {
		var queryAccount = this.mode === 'update' ? this.id : Cookies.CeesoftCurrentAccountId;

		this.responsibleTeamList = $('#account_responsibleTeamId').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				change: e => {
					var count = e.items.length;
					if (count === 0 && this.mode == 'create') {
						$('#cw_account_team_combo').addClass('hide');
						$('#cw_account_team_input').removeClass('hide');
					}
				},
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + queryAccount + '/teams/responsibleTeams/lite',
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			dataTextField: 'name',
			dataValueField: 'id',
			template: Renderer.templates.responsibleTeam,
			valueTemplate: Renderer.templates.responsibleTeam,
			dataBound: $.proxy(this.teamDataBound, this)
		}).data('kendoDropDownList');

		var placeholder = this.tagsDataSource.length ? lang.messages.TAG_CHOOSE : lang.messages.NO_TAGS;
		this.tagsControl = new Tags({
			renderEl: $('.cw_account_tags'),
			enabled: this.mode === 'view' ? false : true,
			placeholder: placeholder
		});
	},
	teamDataBound: function () {
		if (this.lastSavedItem) {
			this.responsibleTeamList.value(this.lastSavedItem);
		}
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#account_title_input').addClass('is_readonly');
		$('#cw_account_external_id').addClass('is_readonly');
		this.descriptionHandler.readonly(true);
		$('#save_account').addClass('hide');
		$('.is_readonly').attr('disabled', true);
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
	},
	/**
	 * Handler function for the click event on the icon button
	 */
	onMediaLibraryButton: function () {
		if ($('.cw_media_library_content').hasClass('hide')) {
			if (!this.uploaderImagesControl) {
				this.renderUploaderImagesControl();
				// if (this.logoId) {
				// 	this.uploaderImagesControl.setSelectedImageId(this.logoId);
				// }
			}
			var modalWindow = $('#account_modal').data("kendoWindow");
			$('.form_content').addClass('hide');
			$('.form_content_minimal').show();
			$('.cw_media_library_content').removeClass('hide');
			$('.actions button').hide();
			$('.actions #back').removeClass('hide');
			$('.cw_media_library').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the form expand button
	 */
	onFormExpandButton: function () {
		$('.form_content_minimal').hide();
		var modalWindow = $('#account_modal').data("kendoWindow");
		$('.cw_media_library_content').addClass('hide');
		$('.form_content').removeClass('hide');
		modalWindow.center();
		$('.cw_media_library').removeClass('hide');
		$('.actions button').show();
		$('.actions #back').addClass('hide');
	},
	/**
	 * Renders the uploader images control.
	 */
	renderUploaderImagesControl: function () {
		this.isFromAccount = true;
		this.uploaderImagesControl = new ImageUploader({
			id: 'account_glyph_icons',
			type: 'account',
			livePreviewSelector: '#cw_account_card_avatar',
			//height: '254px',
			imagesTabEnabled: false,
			accountId: this.id,
			isFromAccount: this.isFromAccount,
			onImageSelected: $.proxy(function(imageData) {
				this.selectedImageData = imageData;
			}, this)
		});
		if (this.mode === 'update') {
			$('#account_glyph_icons').css({'height': '520px'});
		}
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_account').off();
		$('#cancel').off();
		$('.cw_avatar_account').find('.close_minibtn').off();
		$('.cw_media_library').off();
		$('#back').off();
		$('#cw_create_team_btn').off();
		$('#cw_team_cancel_btn').off();
		$('#cw_auth_key_clear').off();
		$('#cw_auth_key_generate').off();
		$('#account_external_id').off();
		LocalEventsManager.unbind('loadRecentIcons');
		LocalEventsManager.unbind('teamSaved');
		LocalEventsManager.unbind('teamCreated');
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_account').on('click', $.proxy(this.onSaveButton, this));
		$('.cw_avatar_account').find('.close_minibtn').on('click', $.proxy(this.onRemovePicture, this));
		$('.cw_media_library').on('click', $.proxy(this.onMediaLibraryButton, this));
		$('#back').on('click', $.proxy(this.onFormExpandButton, this));
		$('#cw_create_team_btn').on('click', $.proxy(this.onCreateTeam, this));
		$('#cw_team_cancel_btn').on('click', $.proxy(this.onCancelTeam, this));
		$('#cw_auth_key_clear').on('click', $.proxy(this.onClearAuthKey, this));
		$('#cw_auth_key_generate').on('click', $.proxy(this.onGenerateAuthKey, this));
		$('#account_external_id').on('keydown', function (e) {
			if (e.which === 32) { // do not allow space
				e.preventDefault();
			}
		});
		LocalEventsManager.bind('teamSaved', $.proxy(this.onTeamSavedHandler, this));
		LocalEventsManager.bind('teamCreated', $.proxy(this.onTeamCreatedHandler, this));
		LocalEventsManager.bind('loadRecentIcons', $.proxy(this.loadRecentIcons, this));
	},
	/**
	 * Handler for the create team button
	 */
	onCreateTeam: function () {
		var scope = this;
		if (State.mainApp.session.hasRole('TEAM_CREATE')) {
			var width = 400;
			this.newTeamWindow = new ModalWindow({
				title: lang.account.TEAM_CREATE,
				width: width,
				height: 567,
				minHeight: 567,
				url: 'include/Administration/TeamForm.jsp',
				refresh: function () {
					new TeamForm({
						id: null,
						mode: 'create',
						accountMode: scope.mode,
						isFromAccount: true,
						accountId: scope.id
					});
				}
			});
			this.newTeamWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	},
	onTeamSavedHandler: function (response) {
		this.lastSavedItem = response.result.data.id;
		$('#account_responsibleTeamId').data('kendoDropDownList').dataSource.read();
	},
	onTeamCreatedHandler: function (response) {
		this.teamData = response;
		this.teamData.id = Utils.guid();
		this.responsibleTeamList.dataSource.add({
			id: this.teamData.id,
			name: this.teamData.name,
			accountName: null
		});
		this.responsibleTeamList.value(this.teamData.id);
	},
	/**
	 * Handler for the cancel team button
	 */
	onCancelTeam: function () {
		$('#cw_account_team_input').addClass('hide');
		$('#cw_team_cancel_btn').addClass('hide');
		$('#cw_account_team_combo').removeClass('hide');
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#account_title_input').removeClass('invalid');
	},
	/**
	 * Handler function for the click event on image remove button
	 */
	onRemovePicture: function (e) {
		if (this.uploaderImagesControl && this.uploaderImagesControl.getSelectedImage().id) {
			//TODO: not sure if this is still relevant because the way of getting image is changed
			//this.uploaderImagesControl.('');
		}
		this.logoId = null;
		var accountModal = $(e.target).parents().eq(6);
		var id = accountModal.find('.accountId').attr('id');
		// remove in modal
		var previewDiv = accountModal.find('.cw_avatar_account');
		previewDiv.find('.close_minibtn').addClass('hide');
		previewDiv.removeClass('no_bg');
		previewDiv.find('img, span').remove();
	},

	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + this.id + '/?update=' + this.update;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				$('#account_title_input').val(result.data.name);
				this.descriptionHandler.value(result.data.comment);
				$('#card_name').text(result.data.name);
				$('#cw_account_auth_key').val(result.data.authenticationToken);
				$('#cw_account_external_id').val(result.data.externalId || '');
				Renderer.monitorCVID(this.id);
				var tags = [];
				if (result.data.tags) {
					for (var i = 0, length = result.data.tags.length; i < length; i++) {
						tags.push({
							id: result.data.tags[i],
							name: result.data.tags[i],
							editable: true
						});
					}
				}
				this.tagsControl.setValues(tags);
				if (!result.data.authenticationToken) {
					$('#cw_auth_key_clear').addClass('hide');
					$('#cw_auth_key_generate').removeClass('hide');
				}
				if (result.data.comment) {
					$('#card_description').text(result.data.comment);
				}

				let teamFromDataSource = this.responsibleTeamList.dataSource.data()
					.find( item => item.id == result.data.responsibleTeamId );

				if (!teamFromDataSource) {
					this.responsibleTeamList.dataSource.add({
						id: result.data.responsibleTeamId,
						name: result.data.teamName,
						accountName: null
					});
				}

				this.responsibleTeamList.value(result.data.responsibleTeamId);

				if (result.data.logoId) {
					this.logoId = result.data.logoId;
					this.iconPack = result.data.iconPack;
					var imagePreviewDiv = $('#cw_account_card_avatar .cw_avatar_account');
					imagePreviewDiv.addClass('no_bg');
					if (Utils.isGuid(this.logoId)) {
						imagePreviewDiv.prepend('<img src="' + Api.images.urls.image(this.logoId, this.id) + '" />');
					} else {
						if (this.iconPack === 'glyph') {
							imagePreviewDiv.prepend('<span class="glyphicons ' + this.logoId + '"></span>');
						} else {
							imagePreviewDiv.prepend('<i class="material-icons">' + this.logoId + '</i>');
						}
					}
					imagePreviewDiv.find('.close_minibtn').removeClass('hide');
					//TODO: not sure if this is still relevant because the way of getting image is changed
					//if (this.uploaderImagesControl) {
					//	this.uploaderImagesControl.setSelectedImageId(result.data.logoId);
					//}

				}
				$('#account_title_input').focus();
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			this.logoId = result.data.logoId;
			this.iconPack = result.data.iconPack;
			if (!Utils.isGuid(result.data.logoId)) {
				for (var i = 0; i < this.recentlyUsedIcons.length; i++) {
					if (this.logoId === this.recentlyUsedIcons[i].id) {
						this.recentlyUsedIcons.splice(i, 1);
					}
				}
				this.recentlyUsedIcons.unshift({
					id: this.logoId,
					iconPack: this.iconPack
				});
				if (this.recentlyUsedIcons.length === 9) {
					this.recentlyUsedIcons.pop();
				}
				var preferences = [{
					key: 'recentIcons',
					value: JSON.stringify(this.recentlyUsedIcons)
				}];
				this.saveUserPrefs({
					category: 'RecentlyUsedIcons',
					preferences: preferences
				});
			}
			//close window
			var modalWindow = $('#account_modal').data("kendoWindow");
			modalWindow.close();
			modalWindow.destroy();

			if (Cookies.CeesoftCurrentAccountId === this.id) {
				Renderer.setAccountLogo(result.data.logoId);
			}
			//update status
			var successText = (this.mode === 'create' ? lang.account.messages.ACCOUNT_SUCCESS_CREATED : lang.account.messages.ACCOUNT_SUCCESS_UPDATED);
			this.statusNotification.setOptions({
				message: successText,
				status: 'success',
				style: 'top:15px; left:15px; right:15px;'
			}).show();

			LocalEventsManager.trigger('accountsaved');
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
		$('#save_account').removeAttr('disabled');
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function (e) {
		var valid = true, message = '', invalidField;
		var logoId, iconPack;
		if (this.uploaderImagesControl) {
			logoId = this.uploaderImagesControl.getSelectedImage().id || '';
			iconPack = this.uploaderImagesControl.getSelectedImage().iconPack || '';
		} else {
			iconPack = this.iconPack;
			logoId = this.logoId;
		}
		this.clearInvalid();

		var tagsObj = this.tagsControl.getValues(), tags = [];
		for (var i = 0, length = tagsObj.length; i < length; i++) {
			tags.push(tagsObj[i].name);
		}
		var data = {
			id: this.id,
			name: $('#account_title_input').val().trim(),
			comment: this.descriptionHandler.value(),
			parentId: Cookies.CeesoftCurrentAccountId,
			logoId: logoId,
			iconPack: iconPack,
			authenticationToken: $('#cw_account_auth_key').val(),
			externalId: $('#cw_account_external_id').val().trim(),
			tags: tags
		};
		if (this.teamData) {
			data.team = {
				description: this.teamData.description,
				members: this.teamData.members,
				name: this.teamData.name,
				tags: this.teamData.tags
			};
		} else {
			data.responsibleTeamId = this.responsibleTeamList.value();
		}
		if (data.name.trim() === '') {
			valid = false;
			message = lang.account.messages.ACCOUNT_ENTER_NAME;
			invalidField = $('#account_title_input');
		}

		if (!valid) {
			invalidField.addClass('invalid');

			this.modalNotification.setOptions({
				message: message,
				status: 'error'
			}).show();
		} else {
			if ($('#cw_account_team_input').hasClass('hide')) {
				//data.responsibleTeamId = $('#account_responsibleTeamId').data('kendoDropDownList').value();
				this.save(data);
			} else {
				var teamName = $('#account_responsibleTeam').val();
				if (!teamName) {
					$('#account_responsibleTeam').addClass('invalid');

					this.modalNotification.setOptions({
						message: lang.service.messages.ENTER_TEAM,
						status: 'error'
					}).show();
				}
				else {
					data.team = teamName;
					this.save(data);
				}
			}
		}
	},
	/**
	 * Saves an account
	 * @param {Object} data The account data to be saved
	 */
	save: function (data) {
		$('#save_account').attr('disabled', 'disabled');
		var url = Settings.serverPath + 'accounts';
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(this.onDataSuccessfullySaved, this));
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		if ($('.form_content').hasClass('hide')) {
			$('.form_content').removeClass('hide');
			$('.form_content_minimal').hide();
			$('.cw_media_library_content').addClass('hide');
			$('.actions button').show();
			$('.actions #back').addClass('hide');
			$('.cw_media_library').removeClass('hide');
			$('#cw_account_card_avatar').removeClass('cw_account_preview_image');
			$('.image_preview_text').remove();
			$('#cw_account_card_content').removeClass('hide');
		} else {
			var modalWindow = $('#account_modal').data("kendoWindow");
			modalWindow.close();
			modalWindow.destroy();
		}
	},
	/**
	 * Handler function for the click event on Clear auth key button
	 * @param {Object} e The click event object
	 */
	onClearAuthKey: function (e) {
		var url = Settings.serverPath + 'accounts/' + this.id + '/authenticationToken/';
		Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
			if (result.success) {
				$('#cw_account_auth_key').val('');
				$('#cw_auth_key_clear').addClass('hide');
				$('#cw_auth_key_generate').removeClass('hide');
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the click event on Generate auth key button
	 * @param {Object} e The click event object
	 */
	onGenerateAuthKey: function (e) {
		var url = Settings.serverPath + 'accounts/' + this.id + '/authenticationToken/';
		Utils.ajax(url, 'POST', {}, $.proxy(function (result) {
			if (result.success) {
				$('#cw_account_auth_key').val(result.data.authenticationToken);
				$('#cw_auth_key_generate').addClass('hide');
				$('#cw_auth_key_clear').removeClass('hide');
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * @deprecated
	 * Saves a new team
	 */
	saveNewTeam: function () {
		var teamData = {
			name: $('#account_responsibleTeam').val(),
			id: null,
			description: ' ',
			members: []
		};
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/';
		Utils.ajax(url, 'POST', JSON.stringify(teamData), $.proxy(function (result) {
			if (result.success) {
				var data = {
					id: null,
					name: $('#account_title_input').val(),
					comment: this.descriptionHandler.value(),
					parentId: Cookies.CeesoftCurrentAccountId,
					logoId: this.uploaderImagesControl.getSelectedImage().id || ''
				};
				this.save(data);
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadRecentIcons: function (e) {
		this.recentlyUsedIcons = e.recentIcons;
	}
});

import Utils from 'tools/utils';


export default function PlayEventsButton(config) {
	Utils.apply(this, config);
	this._initComponent();
};

PlayEventsButton.prototype = {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the control is rendered
	 */
	renderTo: null,

	/**
	 * @cfg {Grid} grid The related grid object.Mandatory
	 */
	grid: null,
	/**
	 * @cfg {Object} view The view object that contains the subscribe/unsubscribe methods. Mandatory
	 */
	view: null,

	/**
	 * @cfg {Boolean} playing True if the events are not blocked, false otherwise. Default to true
	 */
	playing: true,

	/**
	 * Main init function
	 */
	_initComponent: function () {
		/*var html = '<span class="label">';
		html += this.playing ? lang.PAUSE_EVENTS : lang.PLAY_EVENTS,
		html += '</span>';*/
		var html = '<span class="glyphicons ';
		html += this.playing ? 'pause' : 'play';
		html += '"></span>';
		this.renderTo.addClass('cw_button_toggle pointer').append(html).attr('data-playing', this.playing ? 'true' : 'false');
		this.renderTo.attr('title', this.playing ? lang.PAUSE_EVENTS : lang.PLAY_EVENTS);
		this._attachListeners();
	},

	_attachListeners: function () {
		this.renderTo.off().on('click', $.proxy(this._onClick, this));
	},

	_onClick: function (e) {
		if (this.playing) {
			this.view.unsubscribe();
		} else {
			this.grid.dataSource.read();
			this.view.subscribe();
		}
		this.playing = !this.playing;
		this.renderTo.attr('data-playing', this.playing ? 'true' : 'false');
		this.renderTo.attr('title', this.playing ? lang.PAUSE_EVENTS : lang.PLAY_EVENTS);
		this.renderTo.find('.glyphicons').removeClass().addClass('glyphicons').addClass(this.playing ? 'pause' : 'play');
	}
};		
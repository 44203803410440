import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import PreferencesPanel from 'controls/preferencesPanel';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {getServiceState} from 'controls/stateRenderer/serviceState';

export default function QualifierStatesView(config) {
	// initializing data inside this class
	Utils.apply(this, config);
	this.module = {
		name: 'QualifierStatesView'
	};
	this.initComponent();
};

jQuery.extend(QualifierStatesView.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		// var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
		//         startFrom: 0,
		//         playing: true
		//     };
		// this.eventsToolbar = new EventsToolbar({
		//      renderTo: $('#cw_states_events_toolbar'),
		//      callBackFn: $.proxy(this.onEventsTimeout, this),
		//      startFrom: eventStatus.startFrom,
		//      playing: eventStatus.playing
		// });

		this.defaultColumns = {
			state: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 300
			},
			elementName: {
				hidden: false,
				width: 200
			},
			time: {
				hidden: false,
				width: 150
			},
			cause: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false,
				width: 200
			}
		};

		this.loadUserPreferences();

		this.removeListeners();
		this.attachListeners();

		this.removeMask();
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#cw_qualifier_states_grid').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cw_qualifier_states_grid').on('click', '.k-grid-content table tbody tr', $.proxy(this.onServiceQualifierStatesRowClick, this));
	},
	/*
	 * Handler function for service qualifier states row click
	 * @param {Object} e The click event object
	 * */
	onServiceQualifierStatesRowClick: function (e) {
		var uid = $(e.currentTarget).closest('tr').data('uid');
		var item = this.grid.dataSource.getByUid(uid);

		//var offset = moment.utc(item.time).tz(Cookies.CeesoftTimezone).utcOffset() * 60000;
		State.mainApp.navigate(ServicesRouter.viewer(item.serviceId, {
			serviceElementId: item.elementId,
			serviceQualifierId: item.id,
			configuration: {
				period: 'CUSTOM',
				startDate: item.time - 1800000,
				endDate: item.time + 1800000
			},
		}));
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter, columns = this.defaultColumns;
		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		var ahStatesSort, ahStatesFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			ahStatesSort = pref.sort;
			ahStatesFilter = pref.filter;
			this.ahStatesColumns = pref.columns;
		}

		//if no sorting set default grid sorting
		if (!ahStatesSort) {
			ahStatesSort = [{
				field: 'name',
				dir: 'desc',
				compare: null
			}];
		}

		ahStatesFilter = UserPrefs.restoreDateFilter(ahStatesFilter);
		var statesColumns = this.ahStatesColumns || this.defaultColumns;
		statesColumns = Utils.completeColumns(statesColumns, this.defaultColumns);

		this.grid = $('#cw_qualifier_states_grid').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'services/' + this.serviceId + '/statehistory/qualifierBreaches?fromTime=' + this.stateFromTime + '&toTime=' + this.stateToTime + '&state=' + this.itemState + '';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						id: 'id',
						fields: {
							state: {
								type: 'boolean'
							},
							name: {
								type: 'string'
							},
							elementName: {
								type: 'string'
							},
							time: {
								type: 'number'
							},
							cause: {
								type: 'string'
							},
							description: {
								type: 'string'
							}
						}
					}
				},
				filter: ahStatesFilter,
				sort: ahStatesSort,
				error: ErrorHandler.kendoServerError
			}),
			resizable: true,
			selectable: 'row',
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						eq: filterMessages.EQ,
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			columns: [{
				field: 'state',
				//template: '#= Renderer.summaryState(state ? 2 : 0) #',
				template: item => getServiceState(item.state ? 2 : 0),
				title: lang.STATE,
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.DOWN,
								value: '0'
							}, {
								text: lang.UP,
								value: '2'
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							//template: '#=Renderer.summaryState(value, text)#'
							template: item => getServiceState(item.value, item.text),
						});
					}
				},
				width: statesColumns.state.width,
				hidden: statesColumns.state.hidden
			}, {
				field: 'name',
				template: '<span data-id="#= id#">#= name#</span>',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				width: statesColumns.name.width,
				hidden: statesColumns.name.hidden,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'elementName',
				title: lang.designer.SERVICE_ELEMENT,
				sortable: true,
				filterable: true,
				width: statesColumns.elementName.width,
				hidden: statesColumns.elementName.hidden,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'time',
				title: 'Breach time',
				template: '#= Renderer.browserDateRenderer(time, "datetime") #',
				sortable: true,
				filterable: {
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				width: statesColumns.time.width,
				hidden: statesColumns.time.hidden
			}, {
				field: 'cause',
				title: lang.service.CAUSE,
				template: '<span class="cw_tooltip">#= cause || ""#</span>',
				sortable: true,
				filterable: true,
				width: statesColumns.cause.width,
				hidden: statesColumns.cause.hidden,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				template: '#= "<span class=\'cw_tooltip\'>" + description + "</span>" || ""#',
				sortable: true,
				filterable: true,
				width: statesColumns.description.width,
				hidden: statesColumns.description.hidden,
				attributes: {
					'class': 'expand ellipsis'
				}
			}],
			columnMenu: true,
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);

		this.columnTooltip = $("#cw_service_qualifiers_states").kendoTooltip({
			filter: ".cw_tooltip",
			content: function (e) {
				return $(e.target).text();
			}
		}).data("kendoTooltip");

		this.filterPanel = new PreferencesPanel({
			renderTo: 'cw_qualifier_states_filters',
			grid: this.grid,
			modulePrefName: 'QualifierServiceStates',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'qualifierStatesFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name'],
			searchValue: UserPrefs.get('statesSearchPhrase') || '',
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler for the grid Data Bound event
	 * @param {Object} e The dataBoundEvent
	 */
	onDataBound: function (e) {
		//$('#cw_service_qualifiers_states').find('.k-grid-content table tbody tr').addClass('pointer');
		this.adjustSectionHeight();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/*
	 * Handler function for events timeout
	 * */
	onEventsTimeout: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'ServiceQualifierStates',
			filterPanel: this.filterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'statesSearchPhrase',
				columns: 'columns',
				sort: 'statesSort',
				filter: 'statesFilter',
				FsViews: 'statesFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		UserPrefs.load('ServiceQualifierStates', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;

				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);
		this.saveUserPreferences();

		//if (this.eventsToolbar) {this.eventsToolbar.destroy();}
	}
});

import {Application} from "core/application";
import {Cookies} from "core/cookies";
import {LocalEventsManager} from "core/localEventsManager";
import {Api} from "tools/api";
import {UserPrefs} from "tools/userPrefs";
import {Utils} from "tools/utils";
import {CustomNotification} from "controls/customNotification";
import {Dialog} from "controls/dialog";

export let ImageUploader = function (config) {
	Utils.apply(this, config);

	if (this.livePreviewSelector == null && this.closeWhenImageUploaded == null) {
		this.closeWhenImageUploaded = true;
	}
	this.selectedImage = null;
	this.initComponent();
};

export default ImageUploader;

jQuery.extend(ImageUploader.prototype, {
	root: null,
	progressBarShowTimerId: null,

	//configurable options
	livePreviewSelector: null,
	imagesTabEnabled: true,
	iconsTabEnabled: true,
	closeWhenImageUploaded: null,

	getImageUploadUrl: function () {
		if (this.imagesTabEnabled) {
			return Api.images.urls.save(this.accountId);
		} else {
			return Api.images.urls.upload(this.accountId);
		}
	},
	//---

	//api
	getSelectedImage: function () {
		return this.selectedImage;
	},
	//-----

	initComponent: function () {
		this.root = $('#' + this.id);

		this.createLayout();

		this.attachListeners();

		this.loadUserPreferences();
	},

	createLayout: function () {
		this.root.addClass('uploader_images_control w-image-uploader');
		this.root.css('width', this.width);
		this.root.css('height', this.height);
		this.root.append('<div class="b-actions"></div>');

		$('.b-actions').append(
			'<div class="b-extra-panel">' +
			'<div class="b-upload-image"><input name="files" id="cw_upload_image" type="file" title="' + lang.CLICK_TO_UPLOAD + '" /></div>' +
			'<div class="b-search"><input type="text" name="search" class="k-input" placeholder="' + lang.SEARCH + '" /></div>' +
			'</div>'
		);

		if (this.imagesTabEnabled && this.iconsTabEnabled) {
			$('.b-actions').append(
				'<div class="b-tabs">' +
				'<div class="uploader-glyphicons b-tab active" data-target="icons">' + lang.account.GLYPH_ICONS + '</div>' +
				'<div class="uploader-user-images b-tab" data-target="images">' + lang.account.USER_IMAGES + '</div>' +
				'</div>'
			);
		}

		var tabsContent = $('<div class="b-tabs-content"></div>');

		if (this.iconsTabEnabled) {
			tabsContent.append(
				'<div id="' + this.id + '_glyphs" class="b-tab-content icons">' +
				'<div class="b-progress-bar"><div class="filler"></div></div>' +
				'<div class="hide b-images-list b-recently-used">' +
				'<div class="recentlyUsed">' + lang.RECENTLY_USED + '</div>' +
				'</div>' +
				'<div class="wrapper-row">' +
				'<div class="wrapper-container"><div class="b-images-list fullscreen all-icons"></div></div>' +
				'</div>'
			);
		}

		if (this.imagesTabEnabled) {
			tabsContent.append(
				'<div id="' + this.id + '_user" class="' + (this.iconsTabEnabled ? 'hide ' : '') + 'b-tab-content images">' +
				'<div class="wrapper">' +
				'<div class="b-progress-bar"><div class="filler"></div></div>' +
				'<div class="b-images-list fullscreen all-images"></div>' +
				'</div>' +
				'</div>'
			);
		}

		this.root.append(tabsContent);

		if (this.type === 'account') {
			this.root.append('<div class="cw_resolution_tip hide"><div class="cw_preview">120 x 40</div>' + lang.account.messages.LOGO_BEST_RESOLUTION + '</div>');
		}
	},

	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.root.on('click', '.b-images-list .item', $.proxy(this.onItemClicked, this));
		this.root.on('click', '.b-images-list .item .close', $.proxy(this.onDeleteImageClicked, this));

		$('.b-tab', this.root).on('click', $.proxy(this.onTabClicked, this));
		$('[name=search]', this.root).on('keyup', $.proxy(this.onSearchIcon, this));
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		var oThis = this;
		$('#cw_upload_image').kendoUpload({
			async: {
				withCredentials: true,
				autoUpload: true,
				saveUrl: this.getImageUploadUrl(),
				saveField: 'file'
			},
			multiple: false,
			showFileList: false,
			localization: {
				select: lang.UPLOAD,
				headerStatusUploaded: '',
				statusUploaded: ''
			},
			upload: function (e) {
				var xhr = e.XMLHttpRequest;
				if (xhr) {
					xhr.addEventListener("readystatechange", function (e) {
						if (xhr.readyState === 1 /* OPENED */) {
							xhr.setRequestHeader('Auth-Token', Cookies.sessionId);

						}
					});
				}
				var files = e.files;
				// Check the extension of each file and abort the upload if it is
				// not .jpg, .jpeg or .png
				$.each(files, function () {
					var ext = this.extension.toLowerCase()
					if (ext !== ".jpg"
						&& ext !== ".jpeg"
						&& ext !== ".png"
						&& ext !== '.gif'
					) {
						oThis.statusNotification.setOptions({
							message: lang.account.messages.ASSET_FILE_TYPES,
							status: 'error'
						}).show();
						e.preventDefault();
					}
					if (this.size > 5024000) {
						oThis.statusNotification.setOptions({
							message: lang.messages.FILE_SIZE_LIMIT,
							status: 'error'
						}).show();
						e.preventDefault();
					}
				});

				this.progressBarShowTimerId = setTimeout(1000, $.proxy(function () {
					$('.b-progress-bar', this.root).slideDown();
				}, this));
			},

			progress: $.proxy(function (e) {
				$('.b-progress-bar .filler', this.root).css('width', e.percentComplete + '%');
			}, this),

			success: $.proxy(function (e) {
				if (this.progressBarShowTimerId != null) {
					clearTimeout(this.progressBarShowTimerId);
					this.progressBarShowTimerId = null;
				}

				$('.b-progress-bar', this.root).slideUp();
				$('.b-progress-bar .filler', this.root).css('width', 0);

				$('.k-upload-status-total').remove();
				var imageId = e.response.data;

				this.onImageUploaded(imageId);
				this.addImageToList({id: imageId});
				//this.renderImages();

				if (this.imagesTabEnabled) {
					$('img[data-id=' + imageId + ']', this.root).trigger('click');
				}
			}, this)
		});
		$('#cw_upload_image').attr('accept', 'image/*');
		this.statusNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			icon: 'glyphicons ok-circle',
			style: 'top: 40px; left:20px; right:20px',
			hideOnClick: true
		});
	},
	/**
	 * Renders the images collection
	 * @param {Boolean} triggerEvent True if iconsRender event is triggered
	 */
	renderImages: function (triggerEvent) {
		Api.images.list().done($.proxy(function (result) {
			if (this.iconsTabEnabled) {
				if (!this.isFromAccount) {
					this.renderRecentIcons();
				}

				var iconsContainer = $('.b-tab-content.icons .b-images-list.all-icons', this.root);
				iconsContainer.empty();

				iconsContainer.append('<h4>' + lang.GLYPHICON_ICONS + '</h4>');
				for (var i = 0; i < result.icons.glyph.length; i++) {
					var iconId = result.icons.glyph[i];
					iconsContainer.append('<span data-type="icon" data-icon-pack="glyph" data-id="' + iconId + '" title="' + iconId + '" id="uploader_glyph_' + i + '" class="uploaderGlyphIcon item glyphicons ' + iconId + '"></span>');
				}

				iconsContainer.append('<hr /><h4>' + lang.MATERIAL_ICONS + '</h4>');
				for (var i = 0; i < result.icons.material.length; i++) {
					var iconId = result.icons.material[i];
					iconsContainer.append('<i data-type="icon" data-icon-pack="material" data-id="' + iconId + '" title="' + iconId + '" id="uploader_material_' + i + '" class="uploaderGlyphIcon item material-icons">' + iconId + '</i>');
				}
			}

			if (this.imagesTabEnabled) {
				//var imagesContainer = $('.b-tab-content.images .b-images-list.all-images', this.root);
				//imagesContainer.empty();
				for (var i = 0; i < result.images.length; i++) {
					this.addImageToList(result.images[i]);
				}

				if (result.images.length == 0) {
					this.getImagesContainer().append("<div class='empty-list'>There are no images uploaded</div>")
				}
			}
		}, this));
	},

	getImagesContainer: function () {
		return $('.b-tab-content.images .b-images-list.all-images', this.root);
	},

	addImageToList: function (image) {
		var imageId = image.id;
		var title = (image.text || "");

		if (image.resolution)
			title += " (" + image.resolution + "px)";

		this.getImagesContainer()
			.append(
				'<div class="item" data-type="image"' +
				'data-id="' + imageId + '"' +
				'title="' + title + '"' +
				'>' +
				'<img  src="' + Api.images.urls.thumbnail(imageId) + '"/>' +
				'<div class="overlay">' +
				'<div class="dimmer"></div>' +
				'<div class="close"><a title="' + lang.DELETE + '"><span class="glyphicons remove"></span></a></div>' +
				'</div>' +
				'</div>');
	},

	/**
	 * Handler function for the click event on glyph icons tab
	 * @param {Object} e The click event
	 */
	onTabClicked: function (e) {
		$('.b-tab', this.root)
			.removeClass('active');

		var target = $(e.target)
			.addClass("active")
			.data('target');

		$('.b-tab-content', this.root).addClass('hide');
		$('.b-tab-content.' + target, this.root).removeClass('hide');
	},

	onItemClicked: function (e) {
		var item = $(e.currentTarget);

		item.parent().find('.selected').removeClass('selected');
		item.addClass('selected');

		this.selectedImage = {
			type: item.data("type"),
			iconPack: item.data('icon-pack'),
			id: item.data('id'),
			title: item.attr('title')
		};

		if (item.data('type') === 'icon') {
			if (this.type) {
				$('.cw_card_content').removeClass('hide');
			}
			if (this.type === 'account') {
				$('#cw_account_card_content').removeClass('hide');
				$('#cw_account_card_avatar').removeClass('cw_account_preview_image');
				$('.image_preview_text').remove();
			}
		}

		this.onClick(this.selectedImage);
		this.onImageSelected && this.onImageSelected(this.selectedImage);
	},

	onDeleteImageClicked: function (e) {
		var item = $(e.currentTarget).closest('.item');
		e.stopPropagation();

		var dialog = new Dialog({
			title: lang.INFO,
			msg: lang.imageUploader.DELETE_CONFIRMATION,
			icon: 'INFO',
			actionText: 'DELETE',
			fn: $.proxy(function (value, button) {
				if (button === 'ok') {
					Api.images.delete(item.data('id'))
						.done($.proxy(function () {
							this.onImageDeleted(item)
						}, this));
				}
			}, this)
		});
		dialog.show();
	},

	onImageDeleted: function (item) {
		item.remove();
	},

	/**
	 * Abstract, to be passed as config parameter on constructor
	 */
	onClick: function (e) {
		if (!this.livePreviewSelector)
			return;

		var uploadDiv = $(this.livePreviewSelector);
		uploadDiv.find('.cw_avatar').addClass('no_bg');
		if (this.selectedImage.type === 'image') {
			uploadDiv.find('img, span').remove();
			uploadDiv.find('.cw_avatar').prepend('<img src="' + Api.images.urls.thumbnail(this.selectedImage.id) + '" />');
		} else {
			uploadDiv.find('span, img').remove();
			uploadDiv.find('i').remove();
			if (this.selectedImage.iconPack === 'glyph') {
				uploadDiv.find('.cw_avatar').prepend('<span class="glyphicons ' + this.selectedImage.id + '"></span>');
			}
			else {
				uploadDiv.find('.cw_avatar').prepend('<i class="material-icons">' + this.selectedImage.id + '</i>');
			}
		}
		if (!this.isFromAccount) {
			uploadDiv.find('.close_minibtn').removeClass('hide');
		}
	},

	/**
	 * Abstract, to be passed as config parameter on constructor
	 */
	onImageUploaded: function (id) {
		if (this.isFromAccount) {
			$('#account_modal').data('kendoWindow').close();
			LocalEventsManager.trigger('logoImageChanged', id);
		} else {
			this.selectedImage = {
				id: id,
				type: 'image'
			};

			$('#cw_user_card_avatar').find('i').remove();
			$('#cw_asset_card_avatar').find('i').remove();

			if (this.type === 'account') {
				$('#cw_account_card_avatar').find('i').remove();
				$('#cw_account_card_content').addClass('hide');
				$('#cw_account_card_avatar').addClass('cw_account_preview_image');
				$('#modal .create_account .cw_card').find('.image_preview_text').remove();
				$('#modal .create_account .cw_card').append('<p class="image_preview_text">' + lang.LOGO_AREA + '</p>');
			}

			if (this.livePreviewSelector) {
				var uploadDiv = $(this.livePreviewSelector);
				uploadDiv.find('.cw_avatar').addClass('no_bg');
				uploadDiv.find('img, span').remove();
				uploadDiv.find('.cw_avatar').prepend('<img src="' + Api.images.urls.image(this.selectedImage.id) + '" />');
			}

			this.onImageSelected && this.onImageSelected(this.selectedImage);
			this.onImageUploadedAndSelected && this.onImageUploadedAndSelected();
		}
	},
	/**
	 * Abstract, to be passed as config parameter on constructor
	 */
	onImagesRendered: function () {
	},

	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('RecentlyUsedIcons', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
				this.renderImages();
				this.onImagesRendered();
				$('.k-dropzone').find('em').remove();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Renders the recently used icons
	 */
	renderRecentIcons: function () {
		var recentIconsContainer = $('.b-recently-used', this.root);
		recentIconsContainer.empty();

		var recentIcons = JSON.parse(UserPrefs.get('recentIcons')) || [];

		LocalEventsManager.trigger('loadRecentIcons', {
			recentIcons: recentIcons
		});

		if (!recentIcons.length)
			return;

		recentIconsContainer.removeClass('hide');
		for (var i = 0, length = recentIcons.length; i < length; i++) {
			if (recentIcons[i]) {
				var id = recentIcons[i].id || recentIcons[i];
				if (recentIcons[i].iconPack && recentIcons[i].iconPack === 'glyph') {
					recentIconsContainer.append('<span data-type="icon" data-icon-pack="glyph" data-id="' + id + '" title="' + id + '" id="uploader_glyph_' + i + '" class="uploaderGlyphIcon item glyphicons ' + id + '"></span>');
				} else if (recentIcons[i].iconPack === 'material') {
					recentIconsContainer.append('<i data-type="icon" data-icon-pack="material" data-id="' + id + '" title="' + id + '" id="uploader_glyph_' + i + '" class="uploaderGlyphIcon item material-icons">' + id + ' </i>');
				}
			}
		}

	},
	/**
	 * Handler function for keyup on icon search field
	 * @param e
	 */
	onSearchIcon: function (e) {
		var target = $(e.currentTarget);
		var inputText = target.val();
		var glyphsContainer = $('#' + this.id + '_glyphs span');
		var materialContainer = $('#' + this.id + '_glyphs i');
		var recentContainer = $('.b-recently-used span', this.root);
		var imagesContainer = $('.b-tab-content.images .b-images-list.all-images .item', this.root);

		imagesContainer.removeClass('hide');
		glyphsContainer.removeClass('hide');
		recentContainer.removeClass('hide');
		materialContainer.removeClass('hide');

		if (inputText && (glyphsContainer.not('[data-id*="' + inputText + '"]')
			|| recentContainer.not('[data-id*="' + inputText + '"]')
			|| materialContainer.not('[data-id*="' + inputText + '"]')
			|| imagesContainer.not('[title*="' + inputText + '"]'))) {
			glyphsContainer.not('[data-id*="' + inputText + '"]').addClass('hide');
			materialContainer.not('[data-id*="' + inputText + '"]').addClass('hide');
			recentContainer.not('[data-id*="' + inputText + '"]').addClass('hide');
			imagesContainer.not('[title*="' + inputText + '"]').addClass('hide');
		}
	}
});

import State from 'tools/state';

import './customNotification.less'

export {CustomNotification, CustomNotification as default}

class CustomNotification {
	constructor(config) {
		this.config = {
			...{
				status: 'info',
				title: '',
				message: '',
				icon: '',
				style: '',
				appendToElement: '',
				type: 'plain',
				animationTime: 2500,
				hideOnClick: true,
				show: null,
				hide: null,
				destroyOnHide: false,
			},
			...config
		};
		this.initComponent();
	}

	initComponent() {

		this.iconClass = {
			info: 'glyphicons info-sign',
			error: 'glyphicons exclamation-sign',
			success: 'glyphicons ok-circle'
		};

		let container = $('.cw_status_message');

		if(container.length == 0){
			container = $("<div class='cw_status_message'></div>").appendTo('body');
		}

		this.kendoNotification = container.kendoNotification({
			show: e => {
				e.element.attr('style', this.config.style);
				this.isVisible = true;
				this.config.show && this.config.show(e);
				if (State.currentApp?.dashboardDesigner) {
					e.element.css('pointer-events', 'initial');
				}
			},
			hideOnClick: this.config.hideOnClick || State.currentApp?.dashboardDesigner,
			autoHideAfter: this.config.autoHideAfter != null ? this.config.autoHideAfter : this.config.animationTime,
			appendTo: this.config.appendToElement,
			hide: e => {
				this.isVisible = false;
				this.config.hide && this.config.hide(e);
				if(this.config.destroyOnHide){
					this.destroy();
				}
			},
			templates: [{
				type: 'plain',
				template: '<div class="content #= status #"># if (title) { # <h3 class="title">#= title #</h3> # } #<p class="message">#= message #</p></div>'
			}, {
				type: 'icon',
				template: '<div class="content-icon #= status #"><span class="#= icon#"></span># if (title) { # <h3 class="title">#= title #</h3> # } #<p class="message">#= message #</p></div>'
			}]
		}).data("kendoNotification");
	}

	setOptions(config) {
		this.config = {...this.config, ...config};

		this.kendoNotification.setOptions({
			hideOnClick: this.config.hideOnClick,
			autoHideAfter: this.config.autoHideAfter != null ? this.config.autoHideAfter : this.config.animationTime
		});

		return this;
	}

	show() {
		this.hide();

		if ($(this.config.appendToElement).length) {
			let element = $(this.config.appendToElement);
			if(element.css("position") == "static") {
				element.css("position", "relative");
			}
			this.kendoNotification.show({
				status: this.config.status,
				icon: this.config.icon || this.iconClass[this.config.status],
				title: this.config.title,
				message: this.config.message
			}, this.config.type);
		}
	}

	hide() {
		if( this.isVisible ) {
			this.kendoNotification.hide();
		}
	}

	destroy(){
		if( this.kendoNotification){
			this.kendoNotification.destroy();
		}
	}
}

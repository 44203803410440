import React from 'react';
import {DropDownList} from 'controls/react/form';
import {CeeViewDataSource} from "tools";
import {Settings} from "settings";

const getConfig = () => {
	return {
		autoBind: true,
		dataSource: new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'sessions/timezones/',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			}
		})
	}
};

export default class TimeZonesDropDown extends React.Component {
	constructor(props) {
		super(props);
		this.config = getConfig();
	}

	render() {
		return <DropDownList config={this.config} {...this.props}/>
	}
}

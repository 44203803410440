import AreaType from "./areaType";
import CommonType from "./commonType";
import PieType from "./pieType";
import ScatterType from "./scatterType";

class TypeFactory {
	static get(type: string) {
		switch(type) {
			case "pie":
				return new PieType();
			case "area":
				return new AreaType();
			case "scatter":
				return new ScatterType();
			default:
				return new CommonType();
		}
	}
}


export default TypeFactory;

import Utils from 'tools/utils';
import Settings from 'settings';

var metrics = {
	loaded: false,
	loading: false,
	errorCodes: {}
};

var callStackFunc = [], callStackPreloaders = [];

var callback, preloader, canStack = false;

export default {
	setOptions: function (config) {
		if (typeof config.callback === 'function') {
			canStack = true;
			callback = config.callback;
			preloader = config.preloader;
		} else {
			callback = null;
			preloader = null;
		}
		this.sessionId = config.sessionId;

		return this;
	},
	/*
	* Handler function for loading the error codes
	* */
	load: function () {
		if (this._isLoaded()) {
			this._setEnd();
		} else if (this._isLoading()) {
			if (canStack) {
				callStackFunc.push(callback);
				if (preloader) {
					callStackPreloaders.push(preloader);
					this._setPreloader();
				}
			}
		} else {
			var url;
			this._setStart();
			if (this.sessionId) {
				url = Settings.serverPath + 'sessions/' + this.sessionId + '/metrics/errorCodes';
			} else {
				url = Settings.serverPath + 'metrics/errorCodes';
			}
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				if (result) {
					var length = result.length, item;
					for (var i = 0; i < length; i++) {
						item = result[i];
						metrics.errorCodes[item.code] = {
							value: item.value,
							text: item.text
						};
					}
				}
				this._setEnd();
			}, this), $.proxy(function () {
				this._setEnd();
			}, this));
		}
	},
	/*
	* Handler function for getting the error code by code
	* @param {Int} code The error code
	* */
	get: function (code) {
		var errorCode = metrics.errorCodes[code];

		if (!errorCode) {
			errorCode = {
				text: lang.widget.errorCodes.UNKNOWN,
				value: 'UNKNOWN'
			}
		}

		return errorCode;
	},
	/*
	* Handler function for calling the callback
	* */
	_doCallback: function () {
		if (callback) {
			callback.call(this);
		}

		for (var i = 0, length = callStackFunc.length; i < length; i++) {
			if (typeof callStackFunc[i] === 'function') {
				callStackFunc[i].call(this);
			}
		}

		callStackFunc = [];
	},
	/*
	* Handler function for checking if we are waiting the loading the finish
	* */
	_isLoading: function () {
		return metrics.loading;
	},
	/*
	* Handler function for checking if the loading has finished
	* */
	_isLoaded: function () {
		return metrics.loaded;
	},
	/*
	* Handler function for setting the states
	* */
	_setStart: function () {
		metrics.loading = true;
		metrics.loaded = false;

		this._setPreloader();
	},
	/*
	* Handler function for resetting the states
	* */
	_setEnd: function () {
		metrics.loading = false;
		metrics.loaded = true;

		this._doCallback();
		this._endPreloaders();
	},
	_setPreloader: function () {
		if (preloader && preloader.length) {
			kendo.ui.progress(preloader, true);
		}
	},
	_endPreloaders: function () {
		for (var i = 0, length = callStackPreloaders.length; i < length; i++) {
			if (callStackPreloaders[i].length) {
				kendo.ui.progress(callStackPreloaders[i], false);
			}
		}

		callStackPreloaders = [];
	}

}
import Lang from 'core/localization/lang';


export default [{
	text: Lang.CRITICAL,
	color: 1,
	value: 'CRITICAL'
}, {
	text: Lang.MAJOR,
	color: 2,
	value: 'MAJOR'
}, {
	text: Lang.MINOR,
	color: 3,
	value: 'MINOR'
}, {
	text: Lang.NONE,
	color: 5,
	value: 'NONE'
}];


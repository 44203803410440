import "./multiToggle.less"

import React from "react";
import PropTypes from 'prop-types';

let b = require('b_').with('multitoggle')

export class MultiToggle extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.value || Object.keys(this.props.items)[0]
		};
	}

	onUpdate(key) {
		this.setState({selected: key}, () => this.props.onChange(this.state.selected));
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setState({
				selected: this.props.value
			})
		}
	}

	render() {
		const keys = Object.keys(this.props.items);

		return <ul className={b({disabled: this.props.disabled === true})}>
			{keys.map((key) => {
				const value = this.props.items[key];
				const isSelected = key === this.state.selected;

				return (<li
					key={key}
					className={b('item', {'is-selected': isSelected})}
					onClick={!this.props.disabled ? (() => this.onUpdate(key)) : null}>
					{value}
				</li>)
			})}
		</ul>
	}
}

MultiToggle.propTypes = {
	value: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	items: PropTypes.object
};

export default MultiToggle;

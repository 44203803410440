import {Api} from "tools/api";

export class AgentAPI {
	static getLiteUrl(accountId, sharedAgents) {
		const includeShared = (sharedAgents === false ? false : true);
		return `${Api.accountRoot(accountId)}agents/lite?includeShared=${includeShared}&includeSubAgents=true`;
	}
}

export default AgentAPI;

export const Severity = Object.freeze({
	NONE: 'NONE',
	MINOR: 'MINOR',
	MAJOR: 'MAJOR',
	CRITICAL: 'CRITICAL',
	MAINTENANCE: 'MAINTENANCE', //asset in maintenance but we know the last health,
	INVALID: 'INVALID', //no health for some reasons
	INACTIVE:  'INACTIVE' // maintenance + invalid

});

export const SeverityColor = Object.freeze({
	CRITICAL: '#e45f48',
	MAJOR: '#f3b749',
	MINOR: '#f7e400',
	NONE: '#7cc623',
	INVALID: "#c4c4c4",
	INACTIVE: "#c4c4c4",
	MAINTENANCE: "#c4c4c4",
});


export const defaultSeveritiesOrder = [Severity.CRITICAL, Severity.MAJOR, Severity.MINOR, Severity.NONE, Severity.MAINTENANCE];

export const healthToSeverity = {
	critical: Severity.CRITICAL,
	CRITICAL: Severity.CRITICAL,
	major: Severity.MAJOR,
	MAJOR: Severity.MAJOR,
	minor: Severity.MINOR,
	MINOR: Severity.MINOR,
	ok: Severity.NONE,
	OK: Severity.NONE,
	NONE: Severity.NONE,
	none: Severity.NONE,
	noHealth: Severity.INVALID,
	nohealth: Severity.INVALID,
	NOHEALTH: Severity.INVALID,
	maintenance: Severity.INVALID,
	MAINTENANCE: Severity.INVALID,
	INVALID: Severity.INVALID,
	invalid: Severity.INVALID
}

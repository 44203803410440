import LangEn from './lang-en'
import LangNo from './lang-no'
import Cookies from 'core/cookies'

let Lang;

if (Cookies.CeesoftUserLocale == 'no') {
	Lang = LangNo;
} else {
	Lang = LangEn;
}

Lang.Locale = Cookies.CeesoftUserLocale;

export default Lang;

import './filterBox.less';
import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { AntInput } from "controls/react/ant/antInput";
import { AntSelect } from 'controls/react/ant/antSelect';
import { FilterConditionList } from './constants'

const b = require('b_').with('rsuite-filterBox');

function FilterBox(props) {

    const [filterValue, setFilterValue] = React.useState(props.value);
    const [conditionValue, setConditionValue] = React.useState(props.condition || 'startswith');
    const [filterPopoverVisibility, setFilterPopoverVisibility] = React.useState(false);


    useEffect(()=>{
        if(!props.value || props.value=='')
            onClear();
    }, [props.value])

    const handleFilterValueChange = React.useCallback((value) => {
        if (Array.isArray(value) && value.length == 0)
            value = null;
        setFilterValue(value);
    }, [props.value])

    const onConditionChange = React.useCallback((value) => {
        setConditionValue(value)
    }, [props.value])

    const onFilter = () => {
        if (filterValue == null || filterValue == undefined || filterValue == '')
            props.onChange(null);
        else
            props.onChange({ value: filterValue, condition: conditionValue, type: props.columnConfig?.type });
        setFilterPopoverVisibility(false);
    }

    const onClear = React.useCallback((value) => {
        setFilterValue(null);
        setConditionValue('startswith');
        setFilterPopoverVisibility(false);
        props.onChange(null);

    }, [props.onChange])

    const togglePopover = () => {
        setFilterPopoverVisibility(!filterPopoverVisibility);
    }

    const content = <div className="k-animation-container" >
        <div className={`k-filter-menu k-group k-reset k-state-border-up ${b('bordered-box')}`}>
            {(!props.columnConfig?.type || props.columnConfig.type == 'box') &&
                <><div className="k-filter-help-text">Show items with value that:</div>
                    <AntSelect dataList={FilterConditionList} className={'k-widget k-dropdown k-header'} value={conditionValue} onChange={onConditionChange} />
                    <AntInput className={'k-textbox'} value={filterValue} onChange={handleFilterValueChange} />
                </>
            }
            {(props.columnConfig?.type == 'multiSelect' || props.columnConfig?.type == 'select') &&
                <AntSelect dataList={props.columnConfig.source} className={'k-widget k-dropdown k-header'}
                    value={filterValue} onChange={handleFilterValueChange} mode={props.columnConfig?.type == 'multiSelect' ? 'multiple' : ''} />
            }
            <div>
                <button type="submit" className="k-button k-primary" onClick={onFilter}>Filter</button>
                <button type="reset" className="k-button" onClick={onClear}>Clear</button>
            </div>
        </div>
    </div>
    return <div className={b()}>
        <Popover placement="bottomLeft" content={content} visible={filterPopoverVisibility}>
            <a className="k-grid-filter k-state-border-down" onClick={togglePopover}>
                <span className="k-icon k-i-filter">Filter</span>
            </a>
        </Popover>
    </div>
}

FilterBox.propTypes = {
    filterOptions: PropTypes.array,
    onChange: PropTypes.func.isRequired
}

export default FilterBox;

class TempData{
	store = {};

	get(key){
		let value = this.store[key];
		delete this.store[key];
		return value;
	}

	set(key, value){
		this.store[key] = value;
	}
}

let instance = new TempData();

export {instance as default, instance as TempData};

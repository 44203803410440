class CookiesStorageWrapper{
	collection = {};
	parsedString = "";

	init(){
		this.collection = {};
		this.parsedString = document.cookie;

		var allCookies = this.parsedString.split('; ');

		for (const item of allCookies) {
			var cookiePair = item.split('=');
			this.collection[cookiePair[0]] = cookiePair[1];
		}
	}

	get(name){
		if(document.cookie != this.parsedString){
			this.init();
		}

		return this.collection[name];
	}

	create(name, value, hours, path = '/') {
		let expires;
		if (hours) {
			var date = new Date();
			date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
			expires = "; expires=" + date.toGMTString();
		} else {
			expires = "";
		}
		document.cookie = name + "=" + value + expires + "; path=" + path;

		this.collection[name] = value;
	}

	erase(name){
		this.create(name, '', -1);
		delete this.collection[name];
	}
}

class SessionStorageWrapper{

	constructor(sessionId) {
		this.sessionId = sessionId;
	}

	init(){

	}

	get(key){
		return sessionStorage.getItem(this.sessionId + key);
	}

	create(key, value, hours, path = '/') {
		sessionStorage.setItem(this.sessionId + key, value);
	}

	erase(key){
		sessionStorage.removeItem(this.sessionId + key);
	}
}

const storage = {
	internalStorage: new CookiesStorageWrapper(),

	init: function () {
		this.internalStorage.init();
	},

	create: function (name, value, hours, path = '/') {
		this.internalStorage.create(name, value, hours, path);
	},

	erase: function (name) {
		this.internalStorage.erase(name);
	},

	useSessionStorage(sessionId){
		this.internalStorage = new SessionStorageWrapper(sessionId);
		this.init();
	},

	get(name){
		return this.internalStorage.get(name);
	}
}

export let Cookies = new Proxy(storage, {
	get(target, prop) {
		if (target[prop])
			return target[prop];

		return target.get(prop);
	}
});

Cookies.init();

export default Cookies;

import React from "react";
import "./notification.less";

let b = require ('b_').with('notification');

export default class Notification extends React.PureComponent {
	static defaultProps = {
		timeout: 5000
	};

	state = {
		message: null
	}

	startShowing(message) {
		this.setState({message});

		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		const self = this;

		this.timeout = setTimeout(function () {
			self.timeout = null;
			self.setState({message: null});
		}, this.props.timeout);
	}

	componentDidMount() {
		if (this.props.message) {
			this.startShowing(this.props.message);
		}
	}

	componentDidUpdate(prevProps) {
		const isMessage = !!this.props.message;
		const isMessageChanged = prevProps.message !== this.props.message;

		if (isMessage && isMessageChanged) {
			this.startShowing(this.props.message);
		}
	}

	render() {
		const {message} = this.state;

		if (!message || !message.message) {
			return null;
		}

		return <div className={b({[message.severity || this.props.severity || "info"]: true})}>{message.message}</div>
	}
}
import './page.less'

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ReactHelpers from "tools/helpers/react";

import {CustomNotification} from "controls/customNotification";


let b = require('b_').with('page');

export class Page extends React.PureComponent{
	constructor(props) {
		super(props);

		this.contentRef = React.createRef();
	}

	render() {
		let {header, others} = ReactHelpers.sortOutContent(this.props.children);

		if(header != null) {
			header = React.cloneElement(header, {containerClass: b('header'), type: "transparent"});
		}

		const contentClasses = classnames(
			b('content', {
				'scrollable': this.props.scrollable,
				'no-paddings': this.props.noContentPaddings
			}),
			this.props.contentClass
		);

		return (
			<div className={classnames(b({'no-paddings': this.props.noContentPaddings}), this.props.containerClass)}>
				{header}
				<div className={contentClasses} ref={this.contentRef}>
					{others}
				</div>
			</div>
		);
	}

	componentDidUpdate(prevProps){
		if( prevProps.notification != this.props.notification){
			this.showNotification();
		}
	}

	componentDidMount() {
		if (this.props.notification) {
			this.showNotification();
		}
	}

	showNotification(){
		let notification = new CustomNotification({
			...{
				appendToElement: this.contentRef.current,
				animationTime: 5000,
				destroyOnHide: true
			},
			...this.props.notification
		});

		notification.show();
	}
}

Page.propTypes = {
	containerClass: PropTypes.string,
	contentClass: PropTypes.string,
	notification: PropTypes.object,
	scrollable: PropTypes.bool,
	noContentPaddings: PropTypes.bool
}

Page.defaultProps = {
	scrollable: false,
	noContentPadding: false
}

export default Page;

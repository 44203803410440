export function handleSelection(grid, props) {
	return (e) => {
		if (!props.selectedItems) {
			return;
		}

		for (const item of grid.items()) {
			const data = grid.dataItem(item);
			const fieldValue = data[props.selectionField];

			if (props.selectedItems.includes(fieldValue)) {
				$(item).find('.cw_grid_check').prop('checked', true);
				
				if (!props.onRowSelectionChanged) {
					$(item).find('.cw_grid_check').trigger("change");
				}
			}
		}

		grid.trigger("custom:selectionApplied");
	}
}

import {UserSettings} from "tools/userSettings";

class CustomTheme {
	classPrefix = '.custom-theme';

	async apply() {
		if (this.element) {
			this.element.remove();
		}

		// $('head').append('<style>.x-custom .window_area { background-color: red; }</style>');
		const content = await this.generateContent();

		this.element = $(`<style>${content}</style>`);

		$('head').append(this.element);
	}


	async setCss(selector, css, reset = false) {
		await UserSettings.set('CustomTheme', { [selector]: css }, reset);
		await this.apply();
	}

	async getCss(selector) {
		return await UserSettings.get('CustomTheme', selector)
	}

	async generateContent() {
		let out = '';

		const css = await this.getCss(null);

		const selectors = Object.keys(css);

		for (const selector of selectors) {
			const rules = css[selector];
			const ruleKeys = Object.keys(rules);

			out += `${this.classPrefix} ${selector}, ${this.classPrefix}${selector} {\n`;

			for(const ruleKey of ruleKeys) {
				out += `    ${ruleKey}: ${rules[ruleKey]};\n`;
			}

			out += `}\n`;
		}

		return out;
	}

}

export default new CustomTheme();

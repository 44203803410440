import Utils from 'tools/utils'
import Settings from 'settings'
import LocalEventsManager from 'core/localEventsManager';
import ErrorHandler from 'core/errorHandler';

/**
 * @class ceeview.incidents.ChangeStatusWindow
 * @namespace ceeview.incidents
 * @constructor
 * This class is used for changing the status of multiple incidents at once
 * @param {Object} config The config object
 */
export default function ChangeStatusWindow(config) {
	Utils.apply(this, config);
	this.initComponent();
};


ChangeStatusWindow.prototype = {
	/**
	 * @cfg {Array} incidents List with the incidents ids to have their status changed. Mandatory
	 */

	/**
	 * @cfg {String} contentType The content type of the incidents. Mandatory.
	 */

	/**
	 * @cfg {String} accountId The id of the account where the incidents resides. Mandatory
	 */


	/**
	 * Main init function
	 */
	initComponent: function () {
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},

	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_close').off();
		$('#cw_update').off();
	},

	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_close').on('click', $.proxy(this.onClose, this));
		$('#cw_update').on('click', $.proxy(this.onUpdate, this));
		LocalEventsManager.bind('incidentsstatuschanged', function () {
			$('#cw_close').trigger('click');
		});
	},

	initKendoComponents: function () {
		var statusUrl = Settings.serverPath + 'accounts/' + this.accountId + '/incidents/validStatusTypes';
		this.statusDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: statusUrl,
					contentType: 'application/json; charset=utf-8',
					type: 'POST',
					dataType: 'json',
					cache: false
				},
				parameterMap: $.proxy(function (options) {
					//options.id = this.id; //optional?
					options.accountId = this.accountId;
					options.contentCategory = this.contentCategory;
					return JSON.stringify(options);
				}, this)
			},
			error: ErrorHandler.kendoServerError
		});
		this.statusCombo = $('#cw_incident_status_list').kendoDropDownList({
			autoBind: true,
			dataSource: this.statusDataSource,
			optionLabel: {
				text: lang.incidents.SELECT_STATUS,
				value: ''
			},
			dataTextField: 'text',
			dataValueField: 'value',
			change: function () {
				$('#cw_update').removeAttr('disabled');
			}
		}).data('kendoDropDownList');

	},

	/**
	 * Handler function for the click event on the Cancel/close button
	 * @param {Object} e The click event object
	 */
	onClose: function (e) {
		$(e.currentTarget).closest('.k-window-content').data('kendoWindow').close();
	},

	/**
	 * Handler function for the click event on the save button
	 */
	onUpdate: function () {
		var statusId = this.statusCombo.value();
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/incidents/updateStatus/' + statusId;
		Utils.ajax(url, 'POST', JSON.stringify(this.incidents), $.proxy(function (result) {
			if (result.success) {
				$('#cw_close').trigger('click');
				LocalEventsManager.trigger('incidentsstatuschanged');
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}
};










export const translations = {
	'Maximized':{
		no: 'Maksimert'
	},
	'Minimized':{
		no: 'Minimert'
	},
	'Maximize down elements':{
	},
	"Maximize all elements if down":{
	}
}

const i = require('core/localization').translator(translations);

export const PresentationMode = Object.freeze({
	ShowAllChildren: "maximized",
	ShowNothing: "minimized",
	ShowDownElementsOnly: "maximize-down-elements",
	ShowAllChildrenIfDown: "maximized-all-on-down"
});

let dataSource = null;
export function getPresentationModeDataSource() {
	if(dataSource == null){
		dataSource = [
			{text: i('Maximized'), value: PresentationMode.ShowAllChildren},
			{text: i('Minimized'), value: PresentationMode.ShowNothing},
			{text: i('Maximize down elements'), value: PresentationMode.ShowDownElementsOnly},
			{text: i('Maximize all elements if down'), value: PresentationMode.ShowAllChildrenIfDown}
		]
	}

	return dataSource;
}

export function getPresentationModeLabelByValue(value){
	if(value == null)
		return getPresentationModeDataSource()[0].text;

	return getPresentationModeDataSource()
		.find( x => x.value == value)
		?.text || '';
}


translations[PresentationMode.ShowAllChildren] = translations["Maximized"];
translations[PresentationMode.ShowNothing] = translations["Minimized"];
translations[PresentationMode.ShowDownElementsOnly] = translations["Maximized on down"];
translations[PresentationMode.ShowAllChildrenIfDown] = translations["Maximize all elements if down"];

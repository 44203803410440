import CommonType from './commonType';

const DEFAULT_CONFIG = {
	xAxis: {
		type: 'datetime',
		labels: {
			staggerLines: 1
		},
		dateTimeLabelFormats: {
			millisecond: '%H:%M:%S',
			second: '%H:%M:%S',
			minute: '%H:%M',
			hour: '%H:%M',
			day: '%e. %b',
			week: '%e. %b',
			month: '%b \'%y',
			year: '%Y'
		},
		title: {
			text: 'Time'
		},
	},
	yAxis: {
		title: {
			text: 'Unit'
		},
	},
	tooltip: {
		pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
	},
	plotOptions: {
		area: {
			marker: {
				enabled: false,
				symbol: 'circle',
				radius: 2,
				states: {
					hover: {
						enabled: true
					}
				}
			}
		}
	}
};

class AreaType extends CommonType {
	defaultConfiguration() {
		return DEFAULT_CONFIG;
	}
}

export default AreaType;

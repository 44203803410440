import {Application, Cookies, ErrorHandler, LocalEventsManager} from "core";
import {CeeViewDataSource, Utils} from "tools";
import {Settings} from "settings";
import {CustomNotification, ImageUploader} from "controls";
import {AdministrationApi} from "areas/administration/api";

let UserForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default UserForm;

export const invalidateField = (field_selector) => () => {
	const $field = $(`#modal ${field_selector}`);
	const value = $field.val();
	Utils.setInvalidField(value, $field, true);
}

jQuery.extend(UserForm.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.imageId = '';
		this.teamsLoaded = false;
		this.recentlyUsedIcons = [];
		this.userPref = [];

		// set listeners for live controls
		Utils.onLiveChange('#modal #first_name', '#modal #card_firstname', lang.FIRST_NAME);
		Utils.onLiveChange('#modal #last_name', '#modal #card_lastname', lang.LAST_NAME);
		Utils.onLiveChange('#modal #email', '#modal #card_email', lang.EMAIL);
		Utils.onLiveChange('#modal #username', '#modal #card_username', lang.USERNAME);

		if (this.mode === 'update') {
			this.load();
		} else {
			var input = $('#modal #cw_change_password');
			input.prop('checked', false);
			input.parent().removeClass('cw_checked');
			$('#modal #cw_password_reset').removeClass('hide');
			$('#modal #cw_autogenerate_password').parent().removeClass('is_disabled');
			$('#modal #cw_autogenerate_password').removeAttr('disabled');
		}

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		this.getAccounts();
		this.getUserCategories();
		this.getUserLanguages();
		this.getUserTimezones();
		this.populateTeamsList();
		this.removeListeners();
		this.attachListeners();
		this.onPasswordKeyUp();
		this.onPasswordConfirmKeyUp();
		this.onUsernameKeyUp();
		// this.renderUploaderImagesControl();

		$('#modal .form_content').css('height', '410');
	},
	/**
	 * Generates a random 6 characters password
	 * @returns {String} password The generated password string
	 */
	randomPassword: function () {
		var length = 6, charset = 'abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=', password = '';
		for (var i = 0, n = charset.length; i < length; ++i) {
			password += charset.charAt(Math.floor(Math.random() * n));
		}
		return password;
	},
	/**
	 * Handler function for the click event on the checkboxes
	 * @param {Object} e The click event object
	 */
	onCheckboxClick: function (e) {
		var input = $(e.currentTarget).find('input');
		if (!$(e.currentTarget).hasClass('is_disabled')) {
			input.prop('checked', !input.is(':checked'));
			$(e.currentTarget).toggleClass('cw_checked');

			if (this.mode === 'create' && $(input).is('#modal #cw_change_password')) {
				return;
			}
		}
		if ($(input).is('#modal #cw_change_password')) {
			if ($(input).is(':checked')) {
				$('#modal #cw_password_reset').removeClass('hide');
				$('#modal #cw_autogenerate_password').parent().removeClass('is_disabled');
				$('#modal #cw_autogenerate_password').removeAttr('disabled');
			} else {
				$('#modal #cw_password_reset').addClass('hide');
				$('#modal #password').val('');
				$('#modal #password_confirm').val('');
				$('#modal #cw_autogenerate_password').parent().removeClass('cw_checked').addClass('is_disabled');
				$('#modal #cw_autogenerate_password').prop('checked', false);
				$('#modal #cw_autogenerate_password').attr('disabled', 'disabled');
			}
		}
		if ($(input).is('#modal #cw_lock_user')) {
			if ($(input).is(':checked')) {
				$('#modal #cw_change_password').parent().removeClass('cw_checked').addClass('is_disabled');
				$('#modal #cw_change_password').prop('checked', false);
				$('#modal #cw_change_password').attr('disabled', 'disabled');
				$('#modal #cw_autogenerate_password').parent().removeClass('cw_checked').addClass('is_disabled');
				$('#modal #cw_autogenerate_password').prop('checked', false);
				$('#modal #cw_autogenerate_password').attr('disabled', 'disabled');
				$('#modal #password').val('');
				$('#modal #password_confirm').val('');
				$('#modal #cw_password_reset').addClass('hide');
			} else {
				$('#modal #cw_change_password').removeAttr('disabled');
				$('#modal #cw_change_password').parent().removeClass('is_disabled');
				$('#modal #cw_password_reset').removeClass('hide');
				if (!$('#modal #cw_password_reset').hasClass('hide')) {
					$('#modal #cw_autogenerate_password').removeAttr('disabled');
					$('#modal #cw_autogenerate_password').parent().removeClass('is_disabled');
				}
			}
		}
		if ($(input).is('#modal #cw_autogenerate_password')) {
			if ($(input).is(':checked')) {
				var password = this.randomPassword();
				$('#modal #password').val(password);
				$('#modal #password_confirm').val(password);
			} else {
				$('#modal #password').val('');
				$('#modal #password_confirm').val('');
			}
		}
	},
	/**
	 * Launches Media Library.
	 */
	onMediaLibraryButton: function () {
		if ($('#modal .cw_media_library_content').hasClass('hide')) {
			if (!this.uploaderImagesControl) {
				this.renderUploaderImagesControl();
                //TODO: not sure if this is still relevant because the way of getting image is changed
                // if (this.pictureId) {
					// this.uploaderImagesControl.setSelectedImageId(this.pictureId);
                // }
			}

			var modal = $('#modal');
			modal.find('.form_content').addClass('hide');
			modal.find('.form_content_minimal').show();
			modal.find('.cw_media_library_content').removeClass('hide');
			modal.find('.actions button').hide();
			modal.find('.actions #back').removeClass('hide');
			modal.find('.cw_media_library').addClass('hide');
		}
	},
	/*
	 * Handler function for expanding the form
	 * */
	onFormExpandButton: function () {
		$('#modal .form_content_minimal').hide();
		var modalWindow = $('#modal').data("kendoWindow");
		$('#modal .cw_media_library_content').addClass('hide');
		$('#modal .form_content').removeClass('hide');
		modalWindow.center();
		$('#modal .cw_media_library').removeClass('hide');
		$('#modal .actions button').show();
		$('#modal .actions #back').addClass('hide');
	},
	/**
	 * Renders the uploader images control.
	 */
	renderUploaderImagesControl: function () {
		this.uploaderImagesControl = new ImageUploader({
			id: 'user_create_glyph_icons',
			type: 'user',
			livePreviewSelector: '#modal #cw_user_card_avatar',
			width: '100%',
			height: '344px',
		});
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#modal #save_user.step_1').off();
		$('#modal #cancel').off();
		$('#modal .cw_avatar_user').find('.close_minibtn').off();
		$('#modal .cw_media_library').off();
		$('#back').off();
		$('#modal .cw_checkbox').off();
		LocalEventsManager.unbind('loadRecentIcons');
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		var modalWindow = $('#modal').data("kendoWindow");
		$('#modal #cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#modal #save_user.step_1').on('click', $.proxy(this.onNextButton, this));
		$('#modal .cw_avatar_user').find('.close_minibtn').on('click', $.proxy(this.onRemovePicture, this));
		$('#modal .cw_media_library').on('click', $.proxy(this.onMediaLibraryButton, this));
		$('#back').on('click', $.proxy(this.onFormExpandButton, this));
		$('#modal .cw_checkbox').on('click', $.proxy(this.onCheckboxClick, this));
		$('#modal').on('keyup', '#username', this.onUsernameKeyUp);
		$('#modal').on('keyup', '#password', this.onPasswordKeyUp);
		$('#modal').on('keyup', '#password_confirm', this.onPasswordConfirmKeyUp);
		LocalEventsManager.bind('loadRecentIcons', $.proxy(this.loadRecentIcons, this));
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#modal #username').removeClass('invalid');
		$('#modal #password').removeClass('invalid');
		$('#modal #old_password').removeClass('invalid');
		$('#modal #first_name').removeClass('invalid');
		$('#modal #last_name').removeClass('invalid');
		$('#modal #email').removeClass('invalid');
		$('#modal #user_language').prev().removeClass('invalid');
		$('#modal #user_category').prev().removeClass('invalid');
		$('#modal #user_timezone').prev().removeClass('invalid');
	},
	onUsernameKeyUp: invalidateField('#username'),
	onPasswordKeyUp: invalidateField('#password'),
	onPasswordConfirmKeyUp: invalidateField('#password_confirm'),
	/*
	* Handler function for getting accounts
	* */
	getAccounts: function () {
        var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/subaccounts/firstLevel/?ignoreTags=true';
        this.loginAccountList = $('#modal #user_login_account').kendoDropDownList({
            dataSource: new CeeViewDataSource({
                transport: {
                    read: {
                        url: url,
                        contentType: "application/json; charset=utf-8",
                        type: "GET",
                        dataType: "json",
                        cache: false
                    }
                }
            }),
			value: Cookies.CeesoftCurrentAccountId,
            dataTextField: 'name',
            dataValueField: 'id',
            autoBind: (this.mode === 'update') ? true : false,
            text: (this.mode === 'update') ? undefined : lang.account.USER_SELECT_ACCOUNT
        }).data('kendoDropDownList');
	},
	/**
	 * Defines and retrives the user category list
	 */
	getUserCategories: function () {
		this.categoryDataSource = new CeeViewDataSource({
			change: function () {
				if ($('.userCategoryId').length) {
					var updateCategory = function () {
						var userCategoryField = $("#modal #user_category").data("kendoDropDownList");
						userCategoryField.value($('.userCategoryId').attr('id'));
					};
					setTimeout(updateCategory, 200);
				}
			},
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/usercategories/lite/',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			sort: [{
					field: 'name',
					dir: 'asc',
					compare: null
				}],
			error: ErrorHandler.kendoServerError
		});
		this.categoryDataSource.read();
		$('#modal #user_category').kendoDropDownList({
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: (this.mode === 'update') ? true : false,
			text: (this.mode === 'update') ? undefined : lang.account.USER_SELECT_CATEGORY,
			dataSource: this.categoryDataSource
		});
	},
	/**
	 * Defines and retrives the languages list
	 */
	getUserLanguages: function () {
		$('#modal #user_language').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/locales/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
                        cache: false
					}
				}
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			autoBind: true,
			value: (this.mode === 'update') ? undefined : Cookies.CeesoftUserLocale
		});
	},
	/**
	 * Defines and retrives the timezoen list
	 */
	getUserTimezones: function () {
		$('#modal #user_timezone').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
                        cache: false
					}
				}
			}),
			autoBind: true,
			// text: (this.mode === 'update') ? undefined :
			// lang.account.USER_SELECT_TIMEZONE
			value: (this.mode === 'create') ? Cookies.CeesoftTimezone : undefined
		});
	},
	/**
	 * Defines and retrieves the teams list
	 */
	populateTeamsList: function () {
		//user_team
		var loadUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/lite';
		var modal = $('#modal');
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			var values = [], length = result.length;
			if (length >= 0) {
				this.teamsLoaded = true;

				this.teamsControl = modal.find('#user_team').kendoSortedMultiSelect({
					dataSource: new CeeViewDataSource({
						data: result
					}),
					placeholder: lang.account.messages.CHOOSE_TEAMS,
					dataTextField: 'name',
					dataValueField: 'id'
				}).data('kendoSortedMultiSelect');


			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		var loadUrl = AdministrationApi.getUserUrl(this.id);

		var oThis = this;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var userCategoryField = $("#modal #user_category").data("kendoDropDownList");
				userCategoryField.value(result.data.userCategoryId);
				$("#modal #user_language").data("kendoDropDownList").value(result.data.locale);
				$("#modal #user_timezone").data("kendoDropDownList").value(result.data.timeZone);
				$("#modal .create_account").append('<div class="userCategoryId" id="' + result.data.userCategoryId + '"></div>');
				$('#modal #first_name').val(result.data.firstName || '');
				$('#modal #last_name').val(result.data.lastName || '');
				$('#modal #email').val(result.data.email || '');
				$('#modal #username').val(result.data.username);
				$('#modal #twitter').val(result.data.twitterAccountName || '');
				$('#modal #mobile').val(result.data.phoneNumber || '');
				$('#modal #card_firstname').text(result.data.firstName || '');
				$('#modal #card_lastname').text(result.data.lastName || '');
				$('#modal #card_email').text(result.data.email || '');
				$('#modal #card_username').text(result.data.username);
				$('#modal #password').val('');
				$('#modal #password_confirm').val('');
				$('#modal #cw_lock_user').prop('checked', result.data.lockUser);
				$('#modal #cw_lock_user').parent().addClass(result.data.lockUser ? 'cw_checked' : '');
				if (result.data.lockUser === true) {
					$('#modal #cw_change_password').prop('checked', false);
					$('#modal #cw_change_password').attr('disabled', 'disabled');
					$('#modal #cw_change_password').parent().addClass('is_disabled');
				} else {
					$('#modal #cw_change_password').prop('checked', result.data.changePassword);
					$('#modal #cw_change_password').parent().addClass(result.data.changePassword ? 'cw_checked' : '');
				}
				this.teamsLoaded = true;
				this.selected = false;
				this.teams = result.data.teams;
				this.selectTeams();
				if (result.data.pictureId) {
					this.pictureId = result.data.pictureId;
					var imagePreviewDiv = $('#modal #cw_user_card_avatar .cw_avatar_user');
					imagePreviewDiv.addClass('no_bg');
					imagePreviewDiv.prepend('<img src="' + Settings.serverPath + 'sessions/' + Cookies.sessionId + '/accounts/' + Cookies.CeesoftCurrentAccountId + '/users/' + oThis.id + '/picture/' + this.pictureId + '?_dc=' + Utils.timestamp() + '" />');
					imagePreviewDiv.find('.close_minibtn').removeClass('hide');
                    //TODO: not sure if this is still relevant because the way of getting image is changed
                    // if (this.uploaderImagesControl) {
						// this.uploaderImagesControl.setSelectedImageId(result.data.pictureId);
                    // }
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	selectTeams: function () {
		if (this.teamsLoaded && !this.selected) {
			this.selected = true;

			this.teamsControl.value(this.teams);
		}
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			if (!Utils.isGuid(result.data.pictureId)) {
				var index = this.recentlyUsedIcons.indexOf(result.data.pictureId);
				if (index !== -1) {
					//remove item from array
					this.recentlyUsedIcons.splice(index, 1);
				}
				this.recentlyUsedIcons.unshift(result.data.pictureId);
				this.recentlyUsedIcons = this.recentlyUsedIcons.slice(0, 8);
				var preferences = [{
					key: 'recentIcons',
					value: JSON.stringify(this.recentlyUsedIcons)
				}];
				this.saveUserPrefs({
					category: 'RecentlyUsedIcons',
					preferences: preferences
				});
			}
			var successText = (this.mode === 'create' ? lang.account.messages.USER_SUCCESS_CREATED : lang.account.messages.USER_SUCCESS_SAVED);
			this.statusNotification.setOptions({
				message: successText,
				status: 'success'
			}).show();

			LocalEventsManager.trigger('usersaved');

			// close window
			$('#modal #cancel').trigger('click');
		} else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on image remove button
	 */
	onRemovePicture: function (e) {
		if (this.uploaderImagesControl.getSelectedImage().id) {
            //TODO: not sure if this is still relevant because the way of getting image is changed
			//this.uploaderImagesControl.setSelectedImageId('');
			var userModal = $(e.target).parents().eq(6);
			var id = userModal.find('.userId').attr('id');
			// remove on modal
			var previewDiv = userModal.find('.cw_avatar_user');
			previewDiv.find('.close_minibtn').addClass('hide');
			previewDiv.removeClass('no_bg');
			previewDiv.find('img, span').remove();
		}
	},
	/**
	 * Handler function for the click event on Next button
	 * @param {Object} e The click event object
	 */
	onNextButton: function (e) {
		this.clearInvalid();
		var createButton = $('#modal #save_user');
		var userCategoryField = $("#modal #user_category").data("kendoDropDownList");
		var userLanguageField = $("#modal #user_language").data("kendoDropDownList");
		var userTimezoneField = $("#modal #user_timezone").data("kendoDropDownList");
		var username = $('#modal #username').val().trim();
		var password = $('#modal #password').val();
		var passwordConfirm = $('#modal #password_confirm').val();
		var oldPassword = $('#modal #old_password').val();
		var timezone = userTimezoneField.value();
		var lockUser = $('#modal #cw_lock_user').is(':checked') ? true : false;
		var changePassword = $('#modal #cw_change_password').is(':checked') ? true : false;
		var userCategoryId = userCategoryField.value();
		var locale = userLanguageField.value();
		this.data = {
			id: (this.id === 'new_user' ? null : this.id),
			userCategoryId: userCategoryId,
			username: username,
			password: (password !== null) ? password : '',
			locale: locale,
			timeZone: timezone,
			lockUser: lockUser,
			changePassword: changePassword,
            loginAccountId: this.loginAccountList.value() || Cookies.CeesoftAccountId
		};
		var valid = true, message;
		var errorCount = 0;
		if (username.trim() === '') {
			valid = false;
			message = lang.account.messages.USER_INPUT_USERNAME;
			errorCount++;
		}
		if (password.length < 6 && password.length !== 0) {
			valid = false;
			message = lang.account.messages.USER_PASSWORD_LENGTH;
			$('#modal #password').addClass('invalid');
			errorCount++;
		}
		if (password.trim().length === 0 && this.mode === 'create') {
			valid = false;
			message = lang.account.messages.USER_PASSWORD_LENGTH;
			$('#modal #password').addClass('invalid');
			errorCount++;
		}
		if (password === username) {
			valid = false;
			message = lang.account.messages.USER_PASSWORD_DIFF_USERNAME;
			$('#modal #password').addClass('invalid');
			errorCount++;
		}
		if (passwordConfirm !== password) {
			valid = false;
			message = lang.account.messages.USER_WRONG_PASSWORD;
			$('#modal #password_confirm').addClass('invalid');
			errorCount++;
		}
		if (userCategoryId === '') {
			valid = false;
			message = lang.account.USER_SELECT_CATEGORY;
			$('#modal #user_category').prev().addClass('invalid');
			errorCount++;
		}
		if (locale.trim() === '') {
			valid = false;
			message = 'Please, select user language';
			$('#modal #user_language').prev().addClass('invalid');
			errorCount++;
		}
		if (timezone.trim() === '') {
			valid = false;
			message = lang.account.USER_SELECT_TIMEZONE;
			$('#modal #user_timezone').prev().addClass('invalid');
			errorCount++;
		}
		if (!valid) {
			this.modalNotification.setOptions({
				message: message,
				status: 'error'
			}).show();
		} else {
			$('#modal .cw_uf_step1').addClass('hide');
			$('#modal #save_user').removeClass('step_1').addClass('step_2');
			$('#modal #save_user.step_2').off();
			$('#modal #save_user.step_2').on('click', $.proxy(this.onSaveButton, this));
			if (this.mode === 'update') {
				createButton.text(lang.UPDATE);
			} else {
				createButton.text(lang.CREATE);
			}
			$('#modal #step_back').removeClass('hide');
			$('#modal #step_back').off();
			$('#modal #step_back').on('click', $.proxy(this.onStepBackButton, this));
			$('#modal .cw_uf_step2').removeClass('hide');
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function (e) {
		this.clearInvalid();
		var firstName = $('#modal #first_name').val().trim();
		var lastName = $('#modal #last_name').val().trim();
		var email = $('#modal #email').val().trim();
		var teams = this.teamsControl.value();
		var twitterAccountName = $('#modal #twitter').val();
		var phoneNumber = $('#modal #mobile').val().trim();

		this.data.firstName = firstName;
		this.data.lastName = lastName;
		this.data.email = email;
		this.data.twitterAccountName = twitterAccountName;
		this.data.phoneNumber = phoneNumber;

		this.data.teams = (teams !== null) ? teams : [];
		if (this.uploaderImagesControl) {
			this.data.pictureId = this.uploaderImagesControl.getSelectedImage().id || '';
		} else {
			this.data.pictureId = this.pictureId;
		}

		var valid = true, message;
		var errorCount = 0;
		if (firstName.trim() === '') {
			valid = false;
			message = lang.account.messages.USER_INPUT_FIRST_NAME;
			$('#modal #first_name').addClass('invalid');
			errorCount++;
		}
		if (lastName.trim() === '') {
			valid = false;
			message = lang.account.messages.USER_INPUT_LAST_NAME;
			$('#modal #last_name').addClass('invalid');
			errorCount++;
		}
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(email)) {
			valid = false;
			message = lang.account.messages.USER_INVALID_EMAIL;
			$('#modal #email').addClass('invalid');
			errorCount++;
		}
		if (errorCount > 1) {
			message = lang.account.messages.USER_COMPLETE_FIELDS;
		}
		if (!valid) {
			this.modalNotification.setOptions({
				message: message,
				status: 'error'
			}).show();
		} else {
			var urlCreate = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/createUser';
			var urlUpdate = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/updateUser';
			var url = (this.mode === 'create' ? urlCreate : urlUpdate);
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Back button
	 * @param {Object} e The click event object
	 */
	onStepBackButton: function (e) {
		var createButton = $('#modal #save_user');
		$('#modal .cw_uf_step2').addClass('hide');
		$('#modal #save_user').removeClass('step_2').addClass('step_1');
		createButton.text(lang.NEXT);
		$('#modal #save_user.step_1').off();
		$('#modal #save_user.step_1').on('click', $.proxy(this.onNextButton, this));
		$('#modal #step_back').addClass('hide');
		$('#modal .cw_uf_step1').removeClass('hide');
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");

		modalWindow.close();
		modalWindow.destroy();
	},
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadRecentIcons: function (e) {
		this.recentlyUsedIcons = e.recentIcons;
	}
});

import React, {useContext, useCallback, useRef, useEffect} from "react";
import {newGuid} from "tools/guid";

export function useStoreCreator(context, storeRef) {
	return function useStore(path, defaultValue) {
		const [_, setStore] = useContext(context);

		const setInStore = useCallback((value) => {
			storeRef.current = storeRef.current.setIn(path, value);
			setStore(newGuid());
		});

		const removeInStore = useCallback(() => {
			storeRef.current = storeRef.current.removeIn(path);
			setStore(newGuid())
		});

		let value = storeRef.current.getIn(path);

		if (defaultValue !== undefined && value === undefined) {
			value = defaultValue;
			storeRef.current = storeRef.current.setIn(path, defaultValue);
		}

		return [value, setInStore, removeInStore];
	}
}

import Settings from "settings";
import {ErrorHandler} from "core";

export function timezoneDataSource() {
	return new kendo.ceeview.DataSource({
		transport: {
			read: {
				url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
				contentType: "application/json; charset=utf-8",
				type: "GET",
				dataType: "json",
				cache: false
			}
		},
		error: ErrorHandler.kendoServerError
	})
}

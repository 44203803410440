import './tabStrip.less';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

let b = require('b_').with('c-tab-strip');

export const Tab = props => {
	return <div></div>
};

Tab.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string.isRequired
};

export class TabStrip extends React.PureComponent {
	constructor(props) {
		super(props);

		this.ref = React.createRef();
	}

	render() {
		let titles = [];
		let contents = [];
		React.Children.forEach(this.props.children, c => {
			if (c == null)
				return;

			if (c.type == Tab) {
				titles.push(<li key={c.props.title + 'title'}>{c.props.title}</li>);
				contents.push(<div key={c.props.title + 'content'}>{c.props.children}</div>);
			}

		});

		return (
			<div className={classnames(b({scrollable: this.props.scrollable !== false}), this.props.containerClass)}
			     ref={this.ref}>
				<ul>{titles}</ul>
				{contents}
			</div>
		);
	}

	componentDidMount() {
		this.kendoRef = $(this.ref.current).kendoTabStrip({
			animation: false,
			activate: this.props.activate
		}).data('kendoTabStrip');

		this.setActiveTab();
		this.setDisabledTabs();

		this.props.kendoRef && this.props.kendoRef(this.kendoRef);
	}

	componentDidUpdate(prevProps) {
		if (this.kendoRef.select().index() != this.props.activeTab
			&& this.props.activeTab != prevProps.activeTab) {
			this.setActiveTab();
		}

		if(!_.isEqual(prevProps.disabledTabs, this.props.disabledTabs)){
			this.setDisabledTabs();
		}
	}

	setActiveTab() {
		let activeTab = this.props.activeTab || 0;
		this.kendoRef.activateTab(this.kendoRef.items()[activeTab]);
	}

	setDisabledTabs(){
		const disabledTabs = this.props.disabledTabs || [];
		for( let i = 0; i < this.kendoRef.items().length; i++){
			let tab = this.kendoRef.items()[i];
			this.kendoRef.enable(tab, !disabledTabs.includes(i));
		}
	}
}

import {State, Utils} from 'tools';
import {LocalEventsManager} from 'core';
import {CustomNotification, Tags} from 'controls';
import Settings from 'settings';


let ServiceQualifierForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default ServiceQualifierForm;

ServiceQualifierForm.prototype = {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.tagsControl = new Tags({
			renderEl: $('#cw_tags'),
			enabled: this.mode === 'view' ? false : true
		});

		this.removeListeners();
		this.attachListeners();
		this.load();
		Utils.onLiveChange('#sq_template_name', '#card_name', lang.NAME);
		Utils.onLiveChange('#sq_template_description', '#card_description', lang.DESCRIPTION);

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
		if (this.mode === 'view') {
			this.enableViewMode();
		}
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#sq_template_name').addClass('is_readonly');
		$('#sq_template_description').addClass('is_readonly');
		$('#save_sq_template').addClass('hide');
		$('.is_readonly').attr('disabled', true);
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_sq_template').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_sq_template').on('click', $.proxy(this.onSaveButton, this));
		$('.create_sq_template').on('keyup', '#sq_template_name', $.proxy(this.onNameKeyUp, this));
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#sq_template_name').parent().removeClass('required_form');
	},
	onNameKeyUp: function () {
		const name = $('#sq_template_name').val();
		Utils.setInvalidField(name, $('#sq_template_name'), true);
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		if (State.currentApp.dataSource) {
			var dataSource = State.currentApp.dataSource;
			var record = dataSource.get(this.id);
			// set values in form
			this.tagsControl.setRawValues(record.tagsArray);
			$('#sq_template_name')
				.focus()
				.val(record.name);
			$('#sq_template_description').val(record.description);
			if(record.shared) {
				$('#sq_template_shared').attr('checked', 'checked');
			}
			$('#card_name').text(record.name);
			$('#card_description').text(record.description);
		}
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			// close window
			$('#cancel').trigger('click');
			// show status
			this.statusNotification.setOptions({
				message: lang.account.messages.SERVICE_QUALIFIER_TEMPLATE_SUCCESS_UPDATED,
				status: 'success'
			}).show();

			LocalEventsManager.trigger('sqtemplatesaved');
		} else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function (e) {
		this.clearInvalid();
		this.data = {
			name: $('#sq_template_name').val().trim(),
			description: $('#sq_template_description').val().trim(),
			id: this.id,
			shared: $('#sq_template_shared').is(':checked'),
			tags: this.tagsControl.getRawValues()
		};
		if (this.data.name.trim() === '') {
			this.modalNotification.setOptions({
				message: lang.account.messages.INPUT_SERVICE_QUALIFIER_TEMPLATE_NAME,
				status: 'error'
			}).show();
			$('#sq_template_name').parent().addClass('required_form');
			$('#sq_template_name').focus();
		} else {
			var url = Settings.serverPath + 'templates/' + this.id + '/servicequalifiers';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}
};

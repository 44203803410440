export const prepareQualifierData = (state, data) => {
	const {configuration: {values, warning}} = data;

	let currentValues = {...values};

	currentValues.warning = warning;

	return {
		properties: currentValues
	}
};

const populateAllDependencies = (key, map, deps) => {
	let out = [];

	for (let dep of deps) {
		out.push(dep);

		if (!map[dep]) {
			continue;
		}

		out = [...out, ...populateAllDependencies(dep, map, map[dep])]
	}

	return out;
};

export function preparedProperties(properties, forms, groups = {}, plain = {}, info = {}) {
	for (const property of properties) {
		const {id} = property;

		if (property.properties) {
			preparedProperties(property.properties, forms, groups, plain, info)
		} else {
			let group;

			if (!property.group) {
				group = `default_${forms[id]}`;
			} else {
				group = property.group;
			}

			groups[group] = groups[group] || [];
			groups[group].push(id);
		}

		plain[id] = property;
		info[id] = {
			value: property.value,
			text: property.text
		}
	}

	return {groups, plain, info};
}

export const itemToForm = (forms) => {
	const out = {};

	for (const [index, form] of forms.entries()) {
		for (const field of form.components) {
			out[field] = index;
		}
	}

	return out;
};
export const setQualifierData = (state, action) => {
	const {data} = action;
	const qualifierId = data.id;

	return state.setIn(['qualifierId'], qualifierId)
		.setIn(['qualifierMap', qualifierId, 'original'], data)
};

import {Api, State, Utils} from "tools";
import {CustomNotification, Tags} from "controls";
import Settings from "settings";
import {LocalEventsManager} from "core";


let ServiceElementForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default ServiceElementForm;

ServiceElementForm.prototype = {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.tagsControl = new Tags({
			renderEl: $('#cw_tags'),
			enabled: this.mode === 'view' ? false : true
		});

		this.removeListeners();
		this.attachListeners();
		this.load();
		Utils.onLiveChange('#se_template_name', '#card_name', lang.NAME);
		Utils.onLiveChange('#se_template_description', '#card_description', lang.DESCRIPTION);

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
		if (this.mode === 'view') {
			this.enableViewMode();
		}
	},
	/**
	 * Handler function for making form readonly if user does not have update permission
	 */
	enableViewMode: function () {
		$('#se_template_name').addClass('is_readonly');
		$('#se_template_description').addClass('is_readonly');
		$('#save_se_template').addClass('hide');
		$('.is_readonly').attr('disabled', true);
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_se_template').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_se_template').on('click', $.proxy(this.onSaveButton, this));
		$('.create_se_template').on('keyup', '#se_template_name', $.proxy(this.onNameKeyUp, this));
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#se_template_name').removeClass('invalid');
	},
	onNameKeyUp: function () {
		const name = $('#se_template_name').val();
		Utils.setInvalidField(name, $('#se_template_name'), true);
	},
	/**
	 * Loads the form data from the view and set the values into component fields
	 */
	load: function () {
		if (State.currentApp.dataSource) {
			var dataSource = State.currentApp.dataSource;
			var record = dataSource.get(this.id);
			// set values in form
			this.tagsControl.setRawValues(record.tagsArray);
			var templateNameInput = $('#se_template_name');
			$('#se_template_description').val(record.description);
			templateNameInput.val(record.name);
			$('#card_name').text(record.name);
			$('#card_description').text(record.description);
			templateNameInput.focus();
			// update cw_avatar image
			$('#modal .cw_avatar_se_template').empty().append('<img src="' + Api.templates.preview(this.id) + '"/>');
		}
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			// close window
			$('#cancel').trigger('click');
			// show status
			this.statusNotification.setOptions({
				message: lang.account.messages.SERVICE_ELEMENT_TEMPLATE_SUCCESS_UPDATED,
				status: 'success'
			}).show();

			LocalEventsManager.trigger('setemplatesaved');
		} else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: function (e) {
		this.clearInvalid();
		this.data = {
			name: $('#se_template_name').val().trim(),
			description: $('#se_template_description').val().trim(),
			id: this.id,
			tags: this.tagsControl.getRawValues()
		};
		if (this.data.name.trim() === '') {
			this.modalNotification.setOptions({
				message: lang.account.messages.INPUT_SERVICE_ELEMENT_TEMPLATE_NAME,
				status: 'error'
			}).show();

			$('#se_template_name').addClass('invalid');
			$('#se_template_name').focus();
		} else {
			var url = Settings.serverPath + 'templates/' + this.id + '/serviceelements';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}
};

import classnames from "classnames";
import { FormEntry, TextBox } from 'controls/react/form';
import { translator } from "core";
import { Map, Set } from "immutable";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getPath, stripTree } from "./helpers";
import "./treeGrid.less";

const b = require('b_').with('tree_grid');
const container_b = require('b_').with('tree_grid_container');

const i = translator({});

TreeGrid.propTypes = {
	data: PropTypes.instanceOf(Map).isRequired,
	selectedItems: PropTypes.array,
	onChange: PropTypes.func.isRequired
};

function expandParents(state, selectedItems) {
	if (!selectedItems) {
		return state;
	}

	for (const item of selectedItems) {
		const path = getPath(item);

		for (let i = 1; i < path.length; i = i + 2) {
			state = state.setIn([...path.slice(0, i), "expanded"], true);
		}
	}

	return state;
}

export function TreeGrid(props) {
	const [state, setState] = useState(expandParents(props.data, props.selectedItems));
	const [selectedIds, setSelectedIds] = useState(Set());
	const [searchText, setSearchText] = useState();
	const [currentSearch, setCurrentSearch] = useState();

	const timerRef = useRef();

	const onSelect = useCallback((id) => {
		if (selectedIds.has(id)) {
			setSelectedIds(selectedIds.delete(id));
		}
		else {
			setSelectedIds(selectedIds.add(id));
		}
	}, [selectedIds]);

	useEffect(() => {
		if (props.selectedItems) {
			setState(expandParents(state, props.selectedItems));
			setSelectedIds(Set(props.selectedItems));
		}
	}, []);

	useEffect(() => {
		props.onChange(selectedIds.toJS());
	}, [selectedIds])

	const onChange = useCallback((value) => {
		setSearchText(value);

		if (timerRef.current) {
			window.clearTimeout(timerRef.current);
		}

		timerRef.current = setTimeout(() => {
			setCurrentSearch(value);
		}, 500);
	});

	useEffect(() => {
		if (!currentSearch) {
			setState(expandParents(props.data, props.selectedItems));
		}
		else {
			setState(stripTree(currentSearch, props.data));
		}
	}, [currentSearch]);

	return <div className={container_b()}>
		<div className={container_b('header')}>
			<div>Node</div>
			<div>Id</div>
			<div>Path</div>
		</div>
		<TextBox value={searchText} onChange={onChange} placeholder={i('Search...')} containerClass={container_b('search')} />
		<div className={b()}>
			<TreeGridLevel data={state} level={0} onSelect={onSelect} selectedIds={selectedIds}/>
		</div>
	</div>

};

TreeGridLevel.propTypes = {
	data: PropTypes.instanceOf(Map).isRequired,
	level: PropTypes.number.isRequired,
	onSelect: PropTypes.func.isRequired,
	selectedIds: PropTypes.instanceOf(Set)
};

function TreeGridLevel(props) {
	const [rows, setRows] = useState();
	const onSelectRef = useRef();

	useEffect(() => {
		if (props.data) {
			setRows(props.data.valueSeq());
		}
	}, [props.data]);

	useEffect(() => { onSelectRef.current = props.onSelect }, [props.onSelect]);

	const onSelect = useCallback((id) => {
		onSelectRef.current(id);
	})

	if (!rows) {
		return null;
	}

	return <>
		{rows.map((row) => {
			return <TreeGridRow key={row.get('id')} row={row} level={props.level} onSelect={onSelect} selectedIds={props.selectedIds}/>
		})}

	</>;
}

function TreeGridRow(props) {
	const [expanded, setExpanded] = useState(props.row.get('expanded'));
	const offsetStyle = useMemo(() => ({ paddingLeft: props.level * 10 }), [])

	const { onSelect } = props;
	const {id, node, path} = props.row.toJS();
	const hasChildren = props.row.has('children');

	const onToggle = useCallback((e) => {
		if (hasChildren) {
			setExpanded(!expanded);
		}

		e.stopPropagation();
	}, [expanded, hasChildren]);

	const onRowSelect = useCallback(() => {
		onSelect(id)
	}, [id]);

	useEffect(() => { setExpanded(props.row.get('expanded')) }, [props.row])

	return <>
		<div className={classnames(
			b('cell'),
			b('node'),
			'pointer',
			{[b('selected')]: props.selectedIds.has(id)}
		)} style={offsetStyle} onClick={onRowSelect}>
			<div className={classnames({
				'k-icon k-i-expand': hasChildren && !expanded,
				'k-icon k-i-collapse': hasChildren && expanded
			}, b('icon'))} onClick={onToggle}></div>
			{node}
		</div>
		<div className={classnames(b('cell'), {[b('selected')]: props.selectedIds.has(id)})}>{id}</div>
		<div className={classnames(b('cell'), {[b('selected')]: props.selectedIds.has(id)})}>{path}</div>
		{expanded && <TreeGridLevel data={props.row.get('children')} level={props.level + 1} onSelect={onSelect} selectedIds={props.selectedIds}/>}
	</>
}

TreeGridRow.propTypes = {
	row: PropTypes.instanceOf(Map).isRequired,
	level: PropTypes.number.isRequired,
	onSelect: PropTypes.func.isRequired,
	selectedIds: PropTypes.instanceOf(Set)
};


import {ArrayHelpers} from "tools/helpers";

function getInitial(columns) {
	const fieldOptions = [];

	for (const column of columns) {
		if (column.sortable && column.sortable.initialDirection) {
			const item = {
				field: column.field,
				dir: column.sortable.initialDirection
			};

			if (column.sortable.compare) {
				item.compare = column.sortable.compare;
			}

			fieldOptions.push(item);
		}
	}

	return fieldOptions;
}

export function getDataSourceFromProps(grid, props) {
	const currentSort = grid.dataSource.sort() || getInitial(props.columns);

	const dataSource = props.dataSourceArray ?
		convertArrayToDataSource(props.dataSourceArray, props.schema) :
		props.dataSource;

	if (props.filter) {
		dataSource.filter(props);
	}

	if (currentSort && props.keepSorting) {
		dataSource.sort(currentSort);
	}

	return dataSource;
}

function convertArrayToDataSource(dataSourceArray, schema) {
	return new kendo.data.DataSource({
		data: ArrayHelpers.generateId(JSON.parse(JSON.stringify(dataSourceArray))),
		schema
	});
}

const columns = [
	{
		field: 'id',
		title: lang.SELECTOR,
		template: function (data) {
			return `<input type="checkbox" class="cw_grid_check" />`
		},
		headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
		attributes: {
			'class': 'text_center'
		},
		headerAttributes: {
			'class': 'text_center'
		},
		sortable: false,
		width: 50,
		filterable: false,
	},
	{
		field: 'name',
		title: lang.NAME,
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	},
	{
		field: 'typeText',
		title: lang.TYPE,
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		}
	},
	{
		field: 'description',
		title: lang.DESCRIPTION,
		filterable: {
			cell: {
				operator: "contains",
				showOperators: false
			}
		},
	}
];

export default columns;

import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import PreferencesPanel from 'controls/preferencesPanel';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';
import {getServiceState} from 'controls/stateRenderer/serviceState';

export default function StatesView(config) {
	// initializing data inside this class
	Utils.apply(this, config);
	this.module = {
		name: 'StatesView'
	};
	this.initComponent();
};

jQuery.extend(StatesView.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		// var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
		//         startFrom: 0,
		//         playing: true
		//     };
		// this.eventsToolbar = new EventsToolbar({
		//      renderTo: $('#cw_states_events_toolbar'),
		//      callBackFn: $.proxy(this.onEventsTimeout, this),
		//      startFrom: eventStatus.startFrom,
		//      playing: eventStatus.playing
		// });

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: '.k-grid-content',
			type: 'plain'
		});

		this.defaultColumns = {
			state: {
				hidden: false,
				width: 50
			},
			actions: {
				hidden: false,
				width: 60
			},
			start: {
				hidden: false,
				width: 200
			},
			end: {
				hidden: false,
				width: 200
			},
			duration: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};

		this.loadUserPreferences();

		this.removeListeners();
		this.attachListeners();

		this.removeMask();
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#cw_service_states_grid').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cw_service_states_grid').on('click', '.k-grid-content table tbody tr', $.proxy(this.onServiceStatesRowClick, this));
	},
	/*
	 * Handler function for service states row click
	 * @param {Object} e The click event
	 * */
	onServiceStatesRowClick: function (e) {
		var target = $(e.currentTarget), uid = target.closest('tr').data('uid'),
			item = this.grid.dataSource.getByUid(uid), itemState;

		//console.log(item);

		State.mainApp.loadModule('QualifierStatesView', null, {
			serviceId: this.serviceId,
			stateFromTime: item.t0 || item.t,
			stateToTime: item.end.getTime(),
			itemState: item.s
		}, e);
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter, columns = this.defaultColumns;
		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		var ahStatesSort, ahStatesFilter, pref;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			pref = JSON.parse(defaultFsView);
			pref = pref;
			ahStatesSort = pref.sort;
			ahStatesFilter = pref.filter;
			this.ahStatesColumns = pref.columns;
		} else {
			if (UserPrefs.get('statesSort')) {
				ahStatesSort = JSON.parse(UserPrefs.get('statesSort'));
			}
			if (UserPrefs.get('statesFilter')) {
				ahStatesFilter = JSON.parse(UserPrefs.get('statesFilter'));
			}
			if (UserPrefs.get('columns')) {
				this.ahStatesColumns = JSON.parse(UserPrefs.get('columns'));
			}
		}

		//if no sorting set default grid sorting
		if (!ahStatesSort) {
			ahStatesSort = [{
				field: 'timestamp',
				dir: 'desc',
				compare: null
			}];
		}

		ahStatesFilter = UserPrefs.restoreDateFilter(ahStatesFilter);
		var serviceStatesColumns = this.ahStatesColumns || this.defaultColumns;
		serviceStatesColumns = Utils.completeColumns(serviceStatesColumns, this.defaultColumns);

		var url = Settings.serverPath + 'services/' + this.serviceId + '/statehistory?timeZone=' + Cookies.CeesoftTimezone + '&interval=0&timeSelector=LASTDAY';

		this.grid = $('#cw_service_states_grid').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						fields: {
							state: {
								type: 'number'
							},
							start: {
								type: 'date'
							},
							end: {
								type: 'date'
							},
							duration: {
								type: 'number'
							}
						}
					},
					data: function (response) {
						if (response.success) {
							var length = response.data.length, results = [];
							for (var i = 0; i < length; i++) {
								if (response.data[i + 1]) {
									response.data[i].state = response.data[i].s ? 2 : 0;

									response.data[i].start = new Date(response.data[i].t);
									response.data[i].end = new Date(response.data[i + 1].t);

									if (response.data[i].t0) {
										response.data[i].duration = response.data[i + 1].t - response.data[i].t0;
									} else {
										response.data[i].duration = response.data[i + 1].t - response.data[i].t;
									}

									response.data[i].duration = response.data[i].duration < 1000 ? 1000 : response.data[i].duration;

									response.data[i].id = i + 1;
									results.push(response.data[i]);
								}
							}
							return results;
						} else {
							return [];
						}
					},
					total: function (response) {
						if (response.data) {
							return response.data.length - 1;
						} else {
							return 0;
						}

					}
				},
				filter: ahStatesFilter,
				sort: ahStatesSort,
				error: ErrorHandler.kendoServerError
			}),
			resizable: true,
			selectable: 'row',
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						eq: filterMessages.EQ,
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: [{
				field: 'state',
				title: '&nbsp;',
				//template: '<span class="cw_summary_state" data-id="#= id#">#= Renderer.summaryState(state) #</span>',
				template: '<span class="cw_summary_state" data-id="#= id#">#= getServiceState(state) #</span>',
				sortable: true,
				width: serviceStatesColumns.state.width,
				hidden: serviceStatesColumns.state.hidden,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.DOWN,
								value: '0'
							}, {
								text: lang.UP,
								value: '2'
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							//template: '#=Renderer.summaryState(value, text)#'
							template: item => getServiceState(item.value, item.text),
						});
					}
				},
				attributes: {
					'class': 'text_center'
				}
			}, {
				field: 'start',
				title: lang.reports.DATE_FROM,
				template: '#= Renderer.browserDateRenderer(start, Constants.DATETIME) #',
				sortable: true,
				width: serviceStatesColumns.start.width,
				hidden: serviceStatesColumns.start.hidden,
				filterable: {
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				}
			}, {
				field: 'end',
				title: lang.reports.DATE_TO,
				template: '#= Renderer.browserDateRenderer(end, Constants.DATETIME) #',
				sortable: true,
				width: serviceStatesColumns.end.width,
				hidden: serviceStatesColumns.end.hidden,
				filterable: {
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				}
			}, {
				field: 'duration',
				sortable: true,
				title: lang.slas.STATE_PERIOD,
				template: '#= Renderer.duration(duration) #',
				width: serviceStatesColumns.duration.width,
				hidden: serviceStatesColumns.duration.hidden,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				}
			}, {
				field: 'description',
				hidden: serviceStatesColumns.description.hidden,
				sortable: true,
				filtrable: false,
				title: lang.DESCRIPTION,
				attributes: {
					"class": "expand ellipsis"
				},
				template: '<span class="cw_service_breach pointer">#if (state) {# #=lang.SERVICE_UP# #} else {# #=lang.SERVICE_DOWN# #}#</span>'
			}],
			columnMenu: true,
			dataBound: $.proxy(this.onServiceStateDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);

		this.filterPanel = new PreferencesPanel({
			renderTo: 'cw_states_filters',
			grid: this.grid,
			modulePrefName: 'ServiceStates',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'serviceStatesFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['description'],
			searchValue: UserPrefs.get('statesSearchPhrase') || '',
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler for the grid Data Bound event
	 * @param {Object} e The dataBoundEvent
	 */
	onDataBound: function (e) {
		this.adjustSectionHeight();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/*
	 * Handler function for events timeout
	 * */
	onEventsTimeout: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'ServiceStates',
			filterPanel: this.filterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'statesSearchPhrase',
				columns: 'columns',
				sort: 'statesSort',
				filter: 'statesFilter',
				FsViews: 'statesFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		UserPrefs.load('ServiceStates', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;

				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);

		this.saveUserPreferences();
		//if (this.eventsToolbar) {this.eventsToolbar.destroy();}
	}
});

import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import {ACTIONS} from "areas/services/widgets/filterPanel/reducer";
import Lang from "core/localization/lang";
import NumericTextBox from "controls/react/form/numericTextBox";
import {b} from './filteringPanelShared';
import classnames from 'classnames';

const OPERATOR_DATA = [{
	text: Lang.grid.filter.EQ,
	value: '=='
}, {
	text: Lang.grid.filter.NEQ,
	value: '!='
}, {
	text: Lang.grid.filter.GTE,
	value: '>='
}, {
	text: Lang.grid.filter.GT,
	value: '>'
}, {
	text: Lang.grid.filter.LTE,
	value: '<='
}, {
	text: Lang.grid.filter.LT,
	value: '<'
}];

const UNIT_DATA = [{
	text: Lang.SECONDS,
	value: 'SECONDS'
}, {
	text: Lang.MINUTES,
	value: 'MINUTES'
}, {
	text: Lang.HOURS,
	value: 'HOURS'
}, {
	text: Lang.DAYS,
	value: 'DAYS'
}];

function StateDuration(props) {
	const onOperatorChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_OPERATOR, index: props.index, value })
	}, [props.dispatch]);

	const onDurationChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_DURATION_VALUE, index: props.index, value })
	}, [props.dispatch]);

	const onDurationUnitChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_DURATION_UNIT, index: props.index, value })
	}, [props.dispatch]);

	return <>
		<div className={b('input')}>
			<DropDownList dataSource={OPERATOR_DATA} value={props.operator} onChange={onOperatorChange} />
		</div>
		<div className={classnames(b('input'), b('small'))}>
			<NumericTextBox value={props.duration} onChange={onDurationChange} />
		</div>
		<div className={classnames(b('input'), b('unit'))}>
			<DropDownList dataSource={UNIT_DATA} value={props.unit} onChange={onDurationUnitChange} />
		</div>
	</>
}

StateDuration.propTypes = {
	operator: PropTypes.string.isRequired,
	duration: PropTypes.number.isRequired,
	unit: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default StateDuration;

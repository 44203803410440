import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "controls/react/form/multiSelect";
import {ACTIONS} from "./reducer";
import {b} from './filteringPanelShared'
import classnames from 'classnames';
import FilteringPanelApi from "./api";


function AssetGroup(props) {
	const [assetGroupsList, setAssetGroupsList] = React.useState([]);
	const isMounted = React.useRef(true);

	React.useEffect( () => {
		if (isMounted.current) {
			getAssetGroups();
		}
		return () => { isMounted.current = false; }
	}, [])
	const onAssetGroupsChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_ASSET_GROUPS, value, index: props.index });
	}, [props.dispatch]);

	const getAssetGroups = async() => {
		let assetGroupsList = await FilteringPanelApi.getAssetGroups();
		let list = [];
		for (let assetGroup of assetGroupsList.items) {
			list.push({
				text: assetGroup.name,
				value: assetGroup.id
			});
		}
		setAssetGroupsList(list);
	}

	return <>
		<div className={classnames(b('input'), b('reset-field'))}>
			<MultiSelect data={assetGroupsList}
			             value={props.assetGroups}
			             onChange={onAssetGroupsChange}
			             dataTextField={'text'}
			             dataValueField={'value'}/>
		</div>
	</>
}

AssetGroup.propTypes = {
	index: PropTypes.number.isRequired,
	assetGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default AssetGroup;

import { useEffect, useState } from "react";
import { useMetricCategories } from "./useMetricCategories";
import { Map, fromJS } from "immutable";
import {getPath} from "./helpers"

export function useMetricCategoryTree(accountId) {
	const [metricCategories] = useMetricCategories(accountId);
	const [categoryTree, setCategoryTree] = useState();

	useEffect(() => {
		if (metricCategories) {
			let tree = Map();

			for (const metric of metricCategories) {
				const path = getPath(metric.id);

				tree = tree.setIn(path, fromJS({
					...metric,
					expanded: false
				}));
			}

			setCategoryTree(tree);
		}
	}, [metricCategories]);

	return [categoryTree];
}

import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import {OPERATORS} from "areas/services/widgets/filterPanel/constants";
import Lang from "core/localization/lang";
import MultiSelect from "controls/react/form/multiSelect";
import Renderer from 'tools/renderer';
import {ACTIONS} from "./reducer";
import {b} from './filteringPanelShared'
import classnames from 'classnames';
import {getServiceState} from 'controls/stateRenderer/serviceState';

function template(data) {
	let state, operatingState
	if (data.value === 'IN_MAINTENANCE') {
		state = 'INACTIVE';
		operatingState = 'IN_MAINTENANCE';
	} else {
		state = data.value;
	}
	return getServiceState(state, data.text, operatingState, null);
}


const STATE_DATA = [{
	text: Lang.DOWN,
	value: 'INACTIVE'
}, {
	text: Lang.WARNING,
	value: 'WARNING'
}, {
	text: Lang.UP,
	value: 'ACTIVE'
}, {
	text: Lang.service.IN_MAINTENANCE,
	value: 'IN_MAINTENANCE'
}, {
	text: Lang.UNAVAILABLE,
	value: 'INVALID'
}];

const OPERATOR_DATA = [{
	text: Lang.grid.filter.EQ,
	value: OPERATORS.EQUAL
}, {
	text: Lang.grid.filter.NEQ,
	value: OPERATORS.NOT_EQUAL
}];

function ServiceState(props) {
	const onOperatorChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_OPERATOR, index: props.index, value })
	}, [props.dispatch, props.index]);

	const onStatesChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_STATES, value, index: props.index });
	}, [props.dispatch]);

	return <>
		<div className={b('input')}>
			<DropDownList dataSource={OPERATOR_DATA} value={props.operator} onChange={onOperatorChange} />
		</div>
		<div className={classnames(b('input'), b('reset-field'))}>
			<MultiSelect data={STATE_DATA} value={props.states} template={template} tagTemplate={template} onChange={onStatesChange} />
		</div>
	</>
}

ServiceState.propTypes = {
	index: PropTypes.number.isRequired,
	operator: PropTypes.string.isRequired,
	states: PropTypes.array.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default ServiceState;

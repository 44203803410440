import {Utils} from 'tools/utils';
import {Settings} from 'settings';
import {State} from 'tools/state';

import Widget from 'areas/service-boards/widget';

import RemoteEventsManager from 'core/remoteEventsManager';


export default function IbmConsoleWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	if (this.renderTo) {
		this.consoleWidgetId = $('#' + this.renderTo);
	} else {
		this.consoleWidgetId = $('#' + this.id);
	}

	this.initComponent();
};

jQuery.extend(IbmConsoleWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (this.renderTo) {
			this.renderContent();
		} else {
			/*if (this.size === 1) {
			 this.renderWidgetContent();
			 } else {
			 this.renderContent();
			 }*/
			this.renderContent();
		}
	},
	/**
	 * Renders console content
	 */
	renderContent: function () {
		var html = '<div class="cw_ibmconsole_content">';
		html += '<div class="cw_assetinfo">';
		html += '<ul class="cw_list w100 cw_ibm_list_left">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head"><label class="">' + lang.INFO + '</label></div>';
		html += '<div class="cw_list_head">' + lang.VALUE + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.ASSET + '</label></div>';
		html += '<div class="cw_list_item"><span class="value w100 cw_asset_name ellipsis" title="' + this.instanceConfiguration.assetName + '">' + this.instanceConfiguration.assetName + '</span></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item"><label class="">' + lang.DESCRIPTION + '</label></div>';
		html += '<div class="cw_list_item"><span class="value w100 cw_asset_description ellipsis" title="' + this.instanceConfiguration.assetDescription + '">' + this.instanceConfiguration.assetDescription + '</span></div>';
		html += '</li>';
		html += '</ul>';
		html += '</div>';
		html += '<div class="cw_assetinfo">';
		html += '<ul class="cw_list w100 cw_ibm_list_right">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head"><label class="">' + lang.INFO + '</label></div>';
		html += '<div class="cw_list_head">' + lang.VALUE + '</div>';
		html += '</li>';
		html += '</ul>';
		html += '</div>';
		html += '<div class="cw_ibm_eventlog_list"></div>';
		html += '</div>';
		html += '<div class="cw_widget_mask"><div class="k-loading-image"></div></div>';
		if (this.renderTo) {
			$('#' + this.renderTo).find('.cw_section_content').empty().append(html);
		} else {
			this.consoleWidgetId.find('.cw_section_content').empty().append(html);
		}
		this.initKendoComponents();
		this.populateDetails();
	},
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		this.grid = $('.cw_ibm_eventlog_list').kendoCustomGrid({
			dataSource: [],
			resizable: true,
			scrollable: true,
			reorderable: true,
			selectable: false,
			columnMenu: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				}
				//,messages: this.gridMessages
			},
			columns: [{
				field: 'sequenceNumber',
				title: lang.widget.ibm.SEQUENCE_NUMBER,
				width: 140
			}, {
				field: 'sampleTime',
				title: lang.widget.ibm.SAMPLE_TIME,
				width: 140
			}, {
				field: 'objectName',
				title: lang.widget.ibm.OBJECT_NAME,
				width: 140
			}, {
				field: 'eventId',
				title: lang.widget.ibm.EVENT_ID,
				width: 140
			}, {
				field: 'description',
				title: lang.DESCRIPTION
			}]
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
	},
	/**
	 * Populates the details section
	 */
	populateDetails: function () {
		var loadUrl = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/assets/' + this.instanceConfiguration.assetId + '/monitors/IBMSVC/' + this.instanceConfiguration.monitorId + '/systemDetails';
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var html;
				var leftKeys = ['systemInfo', 'name', 'codeLevel', 'consoleIp', 'statisticsFrequency', 'statisticsStatus', 'collectTime'];
				var rightKeys = ['spaceAllocatedToVdisks', 'spaceInMdiskGrps', 'totalAllocatedExtentCapacity', 'totalFreeSpace', 'totalMdiskCapacity', 'totalOverAllocation', 'totalUsedCapacity', 'totalVdiskCapacity', 'totalVdiskcopyCapacity'];
				var html = '';
				for (var i = 0; i < leftKeys.length; i++) {
					html += '<li class="cw_list_row">';
					html += '<div class="cw_list_item"><label class="" title="' + leftKeys[i] + '">' + leftKeys[i] + '</label></div>';
					if (result.data.system) {
						html += '<div class="cw_list_item"><span class="value left w100 ellipsis" title="' + (result.data.system.properties[leftKeys[i]] || '') + '">' + (result.data.system.properties[leftKeys[i]] || '') + '</span></div>';
					} else {
						html += '<div class="cw_list_item"></div>';
					}
					html += '</li>';
				}
				$('.cw_ibm_list_left').append(html);
				html = '';
				for (var i = 0; i < rightKeys.length; i++) {
					html += '<li class="cw_list_row">';
					html += '<div class="cw_list_item"><label class="" title="' + rightKeys[i] + '">' + rightKeys[i] + '</label></div>';
					if (result.data.system) {
						html += '<div class="cw_list_item"><span class="value left w100 ellipsis" title="' + (result.data.system.properties[rightKeys[i]] || '') + '">' + (result.data.system.properties[rightKeys[i]] || '') + '</span></div>';
					} else {
						html += '<div class="cw_list_item"></div>';
					}
					html += '</li>';
				}
				$('.cw_ibm_list_right').append(html);

			} else {
				//Utils.showInfo(lang.ALERT, result.message, result.details);
				this.showErrorMessage(result.message);
			}
			if (result.data.eventLog) {
				this.grid.setDataSource(new kendo.ceeview.DataSource({
					data: result.data.eventLog.entries
				}));
			}
			this.removeMask();
		}, this));
	},
	removeMask: function () {
		if (this.renderTo) {
			$('#' + this.renderTo).find('.cw_widget_mask').remove();
		} else {
			this.consoleWidgetId.find('.cw_widget_mask').remove();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Number} max The number of columns/rows of the widget
	 */
	onResize: function (max) {
		this.size = max;
		if (this.size === 1) {
			this.renderWidgetContent();
		} else {
			this.renderContent();
		}
	},
	/**
	 * Handler function for the drop event
	 */
	onDrop: function () {
		this.destroy();
	},
	/**
	 * Subscribes to server metric events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			accountId: this.instanceConfiguration.accountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});

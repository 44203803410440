import React from 'react';

export const TableContext = React.createContext();

export const LoadingState = {
	LOADING: 'LOADING',
	LOADED: 'LOADED'
}

export const FilterConditionList = [
    {
        id: 'startswith',
        name: 'Starts with',
    }, {
        id: 'neq',
        name: 'Is not equal to',
    }, {
        id: 'eq',
        name: 'Is equal to'
    }, {
        id: 'contains',
        name: 'Contains'
    }]
import React from 'react';
import ReactDOM from 'react-dom';

import {AntSelect} from "controls/react/ant/antSelect";

import Settings from "settings";

export async function marketOfferManage() {
	const result = await fetch(Settings.ssoPath + 'marketOfferManage' + window.location.search);

	if(!result.ok)
		return;

	const {data} = JSON.parse(await result.text());

	$('#cost_monitoring_only').prop('checked', data.costMonitoringOnly);
	$('#start_monitoring').prop('checked', data.startMonitoring);

	let selectedSubscriptionIds = data.subscriptions.map(x => x.value);

	const runValidations = () => {
		if(selectedSubscriptionIds.length > 0) {
			document.getElementById('market_offer_subscriptions').classList.remove('required_ext')
			$('#cw_send').removeAttr('disabled');
		}else{
			$('#cw_send').attr('disabled', 'disabled');
			document.getElementById('market_offer_subscriptions').classList.add('required_ext')
		}
	}

	runValidations();

	const onSubscriptionsChange = ids => {
		selectedSubscriptionIds = ids;
		runValidations();
	}

	ReactDOM.render(
		<AntSelect mode={"multiple"}
		           nameField={'text'}
		           valueField={'value'}
		           maxTagCount={30}
		           allowClear={true}
		           dataList={data.availableSubscriptions}
		           defaultValue={selectedSubscriptionIds}
		           onChange={onSubscriptionsChange}
		/>,
		document.getElementById('market_offer_subscriptions')
	);

	$('#cw_send').on('click', () => {
		let url = Settings.ssoPath + 'marketOfferManage' + window.location.search;

		let postObj = {
			subscriptions: data.availableSubscriptions.filter(x => selectedSubscriptionIds.find(id => id == x.value)),
			costMonitoringOnly: $('#cost_monitoring_only').is(':checked'),
			startMonitoring: $('#start_monitoring').is(':checked')
		};
		$.ajax({
			type: "POST",
			url: url,
			data: JSON.stringify(postObj),
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (result) {
				if (result.success) {
					window.location = window.location.origin + '/offer/azure?type=finish';
				}
			}
		});
	});
}

import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Application from 'core/application';


export default function IntegrationDetails(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'IntegrationDetails',
		initialConfig: config
	};
	this.accountContext = this.accountId || Cookies.CeesoftCurrentAccountId;
	this.accountNameContext = this.accountName || Cookies.CeesoftCurrentAccountName;
	this.initComponent();
};

jQuery.extend(IntegrationDetails.prototype, Application.prototype, {
	initComponent: function () {

	}
});
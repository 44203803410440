import {getDataSourceFromProps} from "./getDataSourceFromProps";

export function getConfig(props) {
	const config = {};

	props.schema && (config.schema = props.schema);
	props.change && (config.change = props.change);
	props.save && (config.save = props.save);

	props.detailInit && (config.detailInit = props.detailInit);

	config.filterable = props.filterable || false;
	config.resizable = props.resizable;
	config.editable = props.editable;
	config.sortable = props.sortable;
	config.height = props.height;
	config.scrollable = props.scrollable;

	config.columns = [];

	// if (!config.skipSelectorColumn) {
	// 	config.columns.push({selectable: true, width: "50px"});
	// }

	config.columns = [...config.columns, ...props.columns];

	return config;
}
